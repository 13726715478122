import InvoiceHomeDetailCard from "./InvoiceHomeDetailCard";
import { getInvoiceDetailsCardInfo } from "../invoice-master/invoice-helper";
import { RootState } from "src/context/store";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "src/components";
import { invoiceActions } from "src/context/invoice-slice";
import { fetchEscalatedInvoicesData } from "src/services/invoice-api";
import { fetchPriorityInvoicesData } from "src/services/invoice-api";
import { fetchInvoicesData } from "src/services/invoice-api";

const AllInvoices = ({
  isExpandable,
  isExpandedInitial,
  tabIndex,
  page,
}: {
  isExpandable?: boolean;
  isExpandedInitial?: boolean;
  fetchTabData: (tabIndex: number, page: number) => Promise<void>;
  tabIndex: number;
  page: number;
  setPage: (page: number) => void;
}) => {
  const { invoiceData } = useSelector((state: RootState) => state.invoice);
  const [hasMore, setHasMore] = useState(true);
  const { InvoiceFilters } = useSelector((state: RootState) => state.invoice);

  const [pageNumber, setPageNumber] = useState(page + 1);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchMoreData = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      let response;
      const searchParams = {
        company_code: InvoiceFilters?.company?.[0]?.code,
        division_code: InvoiceFilters?.division?.[0]?.company_divisioncode,
        startDate: InvoiceFilters?.dateRange?.[0],
        endDate: InvoiceFilters?.dateRange?.[1],
        assignee_id: InvoiceFilters?.assignee?.[0]?.invoice_assignment_id,
        city: InvoiceFilters?.city?.[0]?.city_name?.split("|")?.[0]?.trim(),
        district: InvoiceFilters?.district?.[0]?.district_name,
        state: InvoiceFilters?.state?.[0]?.state_name,
        fuid_status_id: InvoiceFilters?.status?.[0]?.fuid_status_id || 1,
        page_size: 10,
        page_number: pageNumber,
      };
      switch (tabIndex) {
        case 0:
          response = await fetchInvoicesData(searchParams);
          break;
        case 1:
          response = await fetchPriorityInvoicesData(searchParams);
          break;
        case 2:
          response = await fetchEscalatedInvoicesData(searchParams);
          break;
        default:
          return;
      }
      if (!response?.data?.data || response?.data?.data?.invoiceData === 0) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }

      // Remove duplicates before appending new data
      // const uniqueNewData = removeDuplicates(orderDetailsFiltered || [], res.data.data);
      const uniqueNewData = response?.data?.data?.invoiceData;

      // If no new unique data is found, stop loading more
      if (uniqueNewData.length === 0) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }

      dispatch(invoiceActions.setInvoiceData(invoiceData ? [...invoiceData, ...uniqueNewData] : uniqueNewData));

      setPageNumber(pageNumber + 1);

      // Check if more data exists
      if (!invoiceData || invoiceData.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching more invoices", error);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-10 w-full pb-4">
      {invoiceData?.length === 0 && (
        <div className="flex justify-center items-center h-[20vh]">
          <p className="text-lg text-gray-500">No invoices found</p>
        </div>
      )}

      <div
        id="scrollableDiv"
        className="h-[calc(100vh-250px)] relative overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
      >
        <InfiniteScroll
          dataLength={invoiceData?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div className="flex justify-center py-4 absolute left-[45%]">
              <Spinner />
            </div>
          }
          className="h-[60vh]"
          scrollableTarget="scrollableDiv"
          endMessage={<div className="py-4 text-center text-gray-500">No more invoices to load</div>}
        >
          {/* <ul className="flex flex-col gap-5 p-4 h-[100vh]"> */}
          {invoiceData?.map((data: any, index: number) => (
            <li className="rounded-xl border shadow-sm border-gray-200 list-none my-4" key={index}>
              <InvoiceHomeDetailCard
                status={data?.fileStatusHdr?.fuid_status_desc}
                isExpandable={isExpandable}
                isExpandedInitial={isExpandedInitial}
                data={getInvoiceDetailsCardInfo(data)}
                checkDataType={false}
                invoiceData={data}
                viewDetails={true}
                index={index}
              />
            </li>
          ))}
          {/* </ul> */}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default AllInvoices;
