import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { getCookieValue } from "src/constants/functions";
import { omsBaseUrl, api, omsLatestUrl, vitecompanyserviceurl, medvolApiUrl } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

const instance: AxiosInstance = axios.create({
  baseURL: api + "/api/v1/counter",
});

const orderInstance: AxiosInstance = axios.create({
  baseURL: api,
});

const omsInstance: AxiosInstance = axios.create({
  baseURL: omsBaseUrl,
});

const omsLatestInstance: AxiosInstance = axios.create({
  baseURL: omsLatestUrl,
});

const medvolInstance: AxiosInstance = axios.create({
  baseURL: medvolApiUrl,
});

const getOmsLatestInstance = () => {
  return axios.create({
    baseURL: omsLatestUrl + "/api/v1/oms",
  });
};

const companyserviceurl: AxiosInstance = axios.create({
  baseURL: vitecompanyserviceurl + "/api/v1/oms",
});

const companyserviceurlevent: AxiosInstance = axios.create({
  baseURL: vitecompanyserviceurl,
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
omsInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
orderInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
omsLatestInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
getOmsLatestInstance().interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
companyserviceurl.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
companyserviceurlevent.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));

export const getCounterByEmployee = async (query: any): Promise<AxiosResponse> => {
  return instance.get(`/details/employee${query}`);
};

export const getCounterOrderDetailsById = (counterOrderId: string) => {
  return orderInstance.get(`/oms/counter/locations?counter_code=${counterOrderId}`);
};

export const getOrderIds = (
  counter_company_location_id: number,
  company_code: string,
  page_number: number,
  page_size: number,
  order_id_int?: number | null,
  counter_orders?: string | null
) => {
  return companyserviceurl.get(`/order/order_details_filtered?counter_company_location_id=${counter_company_location_id}`, {
    headers: {
      // "machine-token": "851f7a311679bb4213fbac68bac413cef95cc0c4a9f4610f44a612fa03a5e88f",
      company_code: company_code,
    },
    params: {
      page_number: page_number,
      page_size: page_size,
      company_code: company_code,
      order_id_int: order_id_int,
      counter_orders: counter_orders,
    },
  });
};

export const getCounterOrderProductsById = (counterLocationCode: string | undefined, item_code?: string, product_name?: string) => {
  return orderInstance.get(
    `/oms/counter/products/location?counter_company_location_id=${counterLocationCode}&item_code=${item_code}&product_name=${product_name}`
  );
};

export const postPlaceOrder = (data: any, company_code: string) => {
  return companyserviceurlevent.post(`/event/order`, data, {
    validateStatus(status: number) {
      return status >= 200 && status <= 500;
    },
    headers: {
      // "machine-token": "851f7a311679bb4213fbac68bac413cef95cc0c4a9f4610f44a612fa03a5e88f",
      company_code: company_code,
    },
  });
};

export const getStockist = (counterLocationCode: string, clusterCodes: string[]) => {
  return orderInstance.get(
    `oms/counter/company/stockist?counter_company_location_id=${counterLocationCode}&cluster_codes=${clusterCodes}&default_only=true`
  );
};

export const getActionPermission = (position_code: string, order_id: number, company_code: string) => {
  return companyserviceurl.get(`/order/action-list?position_code=${position_code}&order_id=${order_id}`, {
    headers: {
      // "machine-token": "851f7a311679bb4213fbac68bac413cef95cc0c4a9f4610f44a612fa03a5e88f",
      company_code: company_code,
    },
  });
};

export const orderApproveRejectActionPermission = (position_code: string, action: string, order_id: number, company_code: string) => {
  return companyserviceurl.get(`/order/is-action-allowed?position_code=${position_code}&action=${action}&order_id=${order_id}`, {
    headers: {
      company_code: company_code,
    },
  });
};

export const getOrderApprovalHierarchy = (order_id: number, company_code: string) => {
  return companyserviceurl.get(`/order/hierarchy?order_id=${order_id}`, {
    headers: {
      // "machine-token": "851f7a311679bb4213fbac68bac413cef95cc0c4a9f4610f44a612fa03a5e88f",
      company_code: company_code,
    },
  });
};

export const putAcceptOrderRequest = (payload: any, company_code: string) => {
  return companyserviceurlevent.post(`event/order/approve`, payload, {
    headers: {
      company_code: company_code,
    },
  });
};

export const putRejectOrderRequest = (payload: any, company_code: string) => {
  return companyserviceurlevent.post(`event/order/reject`, payload, {
    headers: {
      company_code: company_code,
    },
  });
};

export const pendingOrderDetails = (
  position_code: number,
  pageIndex: number,
  pageSize: number,
  globalSearch: string,
  company_code: string,
  company_divisioncode: string,
  stockist_id: string,
  order_status_id: string
  // sortField: string,
  // sortOrder: string
) => {
  return companyserviceurl.get(`order/details_all`, {
    headers: {
      // "machine-token": "851f7a311679bb4213fbac68bac413cef95cc0c4a9f4610f44a612fa03a5e88f",
      company_code: company_code,
    },
    params: {
      page_number: pageIndex,
      page_size: pageSize,
      pending: true,
      position_code: position_code,
      search: globalSearch,
      company_code: company_code,
      company_divisioncode: company_divisioncode,
      company_stockist_code: stockist_id,
      status_id: order_status_id,
      // order_by: sortField,
      // ordering: sortOrder,
    },
  });
};

// fetch stockist filter data
export const fetchStockistFilterData = (company_code: string) => {
  return orderInstance.get(`/api/v1/stockist/details`, {
    params: {
      company_code: company_code,
      active_status: "Y",
    },
  });
};

export const fetchStatusDetails = (company_code: string) => {
  return companyserviceurl.get(`/order/status-options-list`, {
    headers: {
      company_code: company_code,
    },
    params: {
      company_code: company_code,
    },
  });
};

export const updateExDefault = (counter_company_location_id: string, selectedStockistIds: string[]) => {
  const body = {
    counter_company_location_id: counter_company_location_id,
    counter_stockist_Lnk_Id: selectedStockistIds,
  };
  return orderInstance.put(`oms/counter/updateExDefault`, body);
};

export const getOMSComments = (order_id: number, company_code: string, payload: any) => {
  return companyserviceurl.get(`/order/remarks`, {
    params: { order_id, ...payload },
    headers: {
      company_code: company_code,
    },
  });
};

export const postNewOMSComment = (data: any, primary_key: number, company_code: string) => {
  return companyserviceurlevent.post(
    `/event/order/remarks`,
    { data, event_type: "ORDER_REMARKS_ADD", primary_key },
    {
      headers: {
        company_code: company_code,
      },
    }
  );
};

export const uploadAttachment = (data: any) => {
  return companyserviceurl.post(`/order/upload`, data);
};

export const verifyOrderInvoice = async (data: any) => {
  const token = getCookieValue("idToken");

  if (!token) throw new Error("No token found");

  console.log(data);

  return medvolInstance.post(
    `/api/CounterPendingInvoices_ForV2`,

    data,

    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export const addCounterStockist = (payload: any) => {
  return orderInstance.post(`event/counter/stockist/add`, payload);
};

export const getPostPermission = (
  position_code: number,
  order_group_ids: number[],
  company_code: string,
  actor_type: string,
  role: string,
  location_code: string,
  counter_company_location_id: number
) => {
  return orderInstance.get(`/api/v1/employee/upperhierarchy/flat-hierarchy`, {
    params: { position_code, order_group_ids, company_code, actor_type, role, location_code, counter_company_location_id },
  });
};
