import { ChangeEvent, FC, forwardRef, useEffect, useState } from "react";
import SearchIcon from "src/assets/search.svg";
import { cn } from "src/utils/utils";

interface FormInputProps {
  label?: string;
  type?: string;
  id?: string | number;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number | readonly string[];
  className?: string;
  showIcon?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  debounceTime?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
  disabled?: boolean;
  onKeyDown?: any;
  onKeyUp?: any;
  containerClasses?: string;
  readonly?: boolean;
  min?: string;
  enableArrows?: boolean;
  isError?: boolean;
}

const Input: FC<FormInputProps> = forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      label,
      placeholder,
      onChange,
      type,
      value,
      className,
      showIcon,
      onBlur,
      disabled,
      onKeyDown,
      onKeyUp,
      debounceTime = undefined,
      containerClasses = "",
      readonly = false,
      min,
      enableArrows = false,
      isError = false,
    }: FormInputProps,
    ref
  ) => {
    const [inputValue, setInputValue] = useState(value);
    const [timer, setTimer] = useState<number | any | null>(null);

    useEffect(() => {
      if (debounceTime && onChange) {
        if (timer) {
          clearTimeout(timer);
        }
        setTimer(
          setTimeout(
            () =>
              onChange({
                target: { value: inputValue },
              } as ChangeEvent<HTMLInputElement>),
            debounceTime
          )
        );
      }
    }, [inputValue]);

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      if (!debounceTime && onChange) {
        onChange(event);
      }
    };

    return (
      <>
        {label && <p className="text-[#737274] font-medium text-xs ml-3 mb-1">{label}</p>}
        <div className={cn(` inline-flex border ${isError ? "border-red-500" : "border-[#e0e0e0]"} rounded-lg w-full ${containerClasses}`)}>
          {showIcon && <img src={SearchIcon} className="ml-3" />}
          <input
            readOnly={readonly}
            value={inputValue}
            ref={ref}
            type={type || "text"}
            onChange={handleChange}
            disabled={disabled}
            title={inputValue || ("" as any)}
            onBlur={onBlur}
            placeholder={`${placeholder || label}`}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            min={min}
            className={cn(`border-none rounded-lg outline-none text-sm px-3 py-1
              ${
                !enableArrows && type === "number"
                  ? "appearance-none [-moz-appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  : ""
              } 
              ${className}`)}
          />
        </div>
         </>
    );
  }
);

export default Input;
