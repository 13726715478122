export const getOrderDetailsCardInfo = (selectedCounter: any) => {
  let response: any = {};

  response = {
    "Doctor Full Name": selectedCounter?.counter_location?.counter_hdr_id?.counter_name || "-",
    "Counter Type": selectedCounter?.drph_lnk_type?.dr_ph_lnk_code || "-",

    "Mobile Number": selectedCounter?.mobile || "-",
    "MCI Regn. No.": selectedCounter?.counter_location?.counter_hdr_id?.counter_name || "-",

    "Clinic Name": selectedCounter?.counter_location?.clinic_name || "-",
    "Email ID": selectedCounter?.counter_location?.counter_hdr_id?.email || "-",

    City: selectedCounter?.counter_location?.address || "-",
    Location: selectedCounter?.counter_location?.address || "-",

    Speciality: selectedCounter?.counter_location?.counter_hdr_id?.speciality_id?.speciality_desc || "-",
    // "Pin Code": selectedCounter?.counter_location?.pincode || "-",
    // "Cluster Name": selectedCounter?.counter_stockist_lnk?.[0]?.stockist_cluster?.clusterCode?.cluster_name || "-",
    "Medvol Stockist Code": selectedCounter?.counter_stockist_lnk?.[0]?.stockist_cluster?.stockistCompanyLnk?.stockist?.medvol_code || "-",
    Stockist: selectedCounter?.counter_stockist_lnk?.[0]?.stockist_cluster?.stockistCompanyLnk?.stockist?.firm_name || "-",
  };

  return response;
};

export const getDefaultStockistInfo = (selectedCounter: any) => {
  let response: Record<string, string> = {};

  selectedCounter?.counter_stockist_lnk?.forEach((item: any) => {
    const clusterName = item?.stockist_cluster?.clusterCode?.cluster_name;
    const clusterStockist = item?.stockist_cluster?.stockistCompanyLnk?.stockist?.owner_name;

    if (clusterName && clusterStockist) {
      response[clusterName] = clusterStockist;
    }
  });

  response = {
    ...response,
  };

  return response;
};
