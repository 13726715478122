import { Check, Edit, X } from "lucide-react";
import { Dispatch, forwardRef, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Spinner } from "src/components";
import TextArea from "src/components/ui/text-area.component";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import { getOMSComments, postNewOMSComment } from "src/services/order-api";

const PrivateToggle = ({ showPrivate, setShowPrivate }: { showPrivate: boolean; setShowPrivate: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <div className="flex mt-5 space-x-2 ml-52">
      <span className="ml-3 text-sm font-medium text-gray-900">Private</span>
      <label className="relative inline-flex items-center cursor-pointer">
        {/* Hidden checkbox */}
        <input type="checkbox" checked={showPrivate} onChange={() => setShowPrivate(!showPrivate)} className="sr-only peer" />

        {/* Background of the switch */}
        <div className="h-6 transition-colors duration-300 bg-gray-200 rounded-full w-11 peer peer-focus:outline-none peer-checked:bg-green-500">
          {/* White ball inside */}
          <div
            className={`absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow-md transition-transform duration-300 transform ${
              showPrivate ? "translate-x-5" : ""
            }`}
          ></div>
        </div>
      </label>
    </div>
  );
};

interface CommentsProps {
  order_id: number;
  uid: string;
}

const Comments = forwardRef<HTMLElement, CommentsProps>(({ order_id, uid }, ref) => {
  const [commentsList, setCommentsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // async function to get comments
  const { selectedCounterData } = useSelector((state: RootState) => state.ord);
  const { idTokenDetails } = useSelector((state: RootState) => state.auth);
  const [showPrivate, setShowPrivate] = useState(false);

  const getComments = async () => {
    try {
      setIsLoading(true);
      const payload = {
        remarkby_type: idTokenDetails?.["custom:role"],
      };
      const response = await getOMSComments(order_id, selectedCounterData?.company_code, payload);
      setCommentsList(response?.data?.data);
    } catch (error) {
      console.error("Failed to get comments:", error);
    } finally {
      setIsLoading(false);
      console.log({ idTokenDetails });
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  return (
    <aside
      className="bg-white border-l-[1px] min-w-[25rem] overflow-y-auto max-w-[30rem] border-l-[#00000014] fixed px-4 text-[#49484C] text-xl font-semibold py-5 right-0  h-screen"
      ref={ref}
    >
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <Spinner />
        </div>
      ) : (
        <section className="flex flex-col gap-5 py-4 px-4 justify-end rounded-md max-w-[480px] mx-auto">
          <div className="flex flex-col items-center justify-between w-full mx-auto bg-white h-[800px]">
            <div className="w-full h-full max-h-full">
              <div className="sticky top-0 bg-white">
                <Header logCount={commentsList?.length ?? 0} />
                <hr className="self-stretch mt-4 w-full bg-zinc-900 min-h-[1px]" />
              </div>
              <div className="h-[80%] overflow-y-scroll " style={{ width: "100%" }}>
                {commentsList.map((log: any, index: number) => {
                  return (
                    <div style={{ width: "100%" }} key={log?.order_remarks_id}>
                      {index % 2 === 0 && (
                        <div className="flex w-full text-right place-content-end">
                          <LogEntry
                            isEditable={false}
                            author={""}
                            level={log?.remarkby_type}
                            message={log?.order_freetxt_remarks}
                            isEditing={false}
                            isRightSide={true}
                          />
                        </div>
                      )}
                      {index % 2 !== 0 && (
                        <div className="flex w-full text-left place-content-start">
                          <LogEntry
                            isEditable={false}
                            author={""}
                            level={log?.remarkby_type}
                            message={log?.order_freetxt_remarks}
                            isEditing={false}
                            isRightSide={false}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <PrivateToggle showPrivate={showPrivate} setShowPrivate={setShowPrivate} />

              <CommentsSection showPrivate={showPrivate} getCommentsFun={getComments} order_id={order_id} uid={uid} />
            </div>
          </div>
        </section>
      )}
    </aside>
  );
});

interface LogEntryProps {
  author: string;
  level: string;
  message: string;
  isEditable?: boolean;
  onEdit?: (message: string) => void;
  isEditing?: boolean;
  isRightSide?: boolean;
}

// Log Entry Component
const LogEntry = ({ author, level, message, isEditable = false, onEdit, isEditing, isRightSide }: LogEntryProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedMessage, setEditedMessage] = useState(message);
  return (
    <section className={`flex flex-col mt-4 w-8/12 text-sm`}>
      <div className="flex justify-between w-full gap-5 px-5 py-1 line-clamp-1 flex-nowrap">
        <div className="font-medium text-neutral-400">{author}</div>
        <div className="flex items-center gap-2 font-semibold text-zinc-900">
          {level}
          {isEditable && !isEditMode && (
            <button onClick={() => setIsEditMode(true)}>
              <Edit size={16} />
            </button>
          )}
          {isEditMode && !isEditing && (
            <div className="flex flex-wrap gap-2">
              <button onClick={() => setIsEditMode(false)}>
                <X size={16} />
              </button>
              <button
                onClick={() => {
                  onEdit && onEdit(editedMessage);
                  setIsEditMode(false);
                }}
              >
                <Check size={16} />
              </button>
            </div>
          )}
        </div>
      </div>

      {isEditMode ? (
        <TextArea disabled={isEditing} value={editedMessage} onChange={(e) => setEditedMessage(e.target.value)} />
      ) : (
        <div
          className={`justify-center  w-full flex-wrap rounded-lg px-3 py-2.5 mt-1 font-medium text-indigo-900 ${
            isRightSide ? "bg-yellow-100" : "bg-violet-100"
          }  }`}
        >
          {isEditing ? <Spinner /> : message}
        </div>
      )}
    </section>
  );
};

interface CommentsSectionProps {
  showPrivate: boolean;
  getCommentsFun: () => Promise<void>;
  order_id: number;
  uid: string;
}
// // Comments Section
export const CommentsSection: React.FC<CommentsSectionProps> = ({ getCommentsFun, order_id, uid, showPrivate }) => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newComment, setNewComment] = useState("");
  const { selectedCounterData } = useSelector((state: RootState) => state.ord);
  const { idTokenDetails } = useSelector((state: RootState) => state.auth);

  const handleAddComment = async () => {
    if (!newComment.trim()) {
      toast({
        description: "Comment cannot be empty",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsSubmitting(true);
      const data = {
        order_id: order_id,
        order_ucode: uid,
        user_ucode: idTokenDetails?.["custom:ucode"],
        order_freetxt_remarks: newComment,
        remarkby_type: idTokenDetails?.["custom:role"],
        is_private: showPrivate,
      };
      const res = await postNewOMSComment(data, order_id, selectedCounterData?.company_code);
      if (res?.status !== 200) throw new Error();
      setNewComment("");
      toast({
        description: "Comment added successfully",
      });
      setTimeout(() => {
        getCommentsFun();
      }, 1000);
    } catch (error) {
      console.error("Failed to add comment:", error);
      toast({
        description: "Failed to add comment",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <section className="flex flex-wrap items-center content-start self-stretch justify-between px-4 py-1 mt-5 text-sm rounded-lg gap-y-3 gap-x-5 bg-stone-50 text-zinc-900">
        <input
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          type="text"
          placeholder="Type your comment here"
          className="flex-auto bg-transparent outline-none"
          disabled={isSubmitting}
        />
        <Button onClick={handleAddComment} disabled={isSubmitting}>
          {isSubmitting ? (
            <Spinner />
          ) : (
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/5ab2dcfe2c393712cfd9ec4b95ad04c4586ef1b677d53c7f5bc4eadeeb8705de?"
              className="aspect-square w-[18px]"
              alt="Submit Comment"
            />
          )}
        </Button>
      </section>
    </>
  );
};

// Header Component
const Header = ({ logCount }: { logCount: number | string }) => (
  <header className="flex self-stretch justify-between w-full gap-5 px-6 whitespace-nowrap">
    <h2 className="text-lg font-semibold tracking-wide text-zinc-900">Log</h2>
    <div className="justify-center px-2 py-2 text-xs font-medium text-indigo-900 rounded-lg bg-violet-100">{logCount} new</div>
  </header>
);

export default Comments;
