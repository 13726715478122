import { yupResolver } from "@hookform/resolvers/yup";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import downArrow from "src/assets/down-arrow.svg";
import { Button, Input } from "src/components";
import Loader from "src/components/ui/loader.component";
import VersioningCrate from "src/components/Versioning/versioningModal.component";
import { flattenObject, generateUniqueID } from "src/constants/functions";
import SmartForm from "src/container/smart-form.component";
import { invoiceActions } from "src/context/invoice-slice";
import { RootState } from "src/context/store";
import { useEventStatus } from "src/hooks/useEventStatus";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { toast } from "src/hooks/useToast";
import {
  editInvoice,
  getInvoiceBatchList,
  getInvoiceDetails,
  getInvoicePriceList,
  getOrderedProducts,
  getSimilarCompanyProducts,
  updateInvoice,
} from "src/services/invoice-api";
import * as yup from "yup";
import Book from "../../assets/book.png";
import DeleteIcon from "../../assets/delete.svg";
import PurpleLeftArrowIcon from "../../assets/purple-left-arrow.svg";
import SubTable from "../counter-details/sub-table.component";
import { getInvoiceDetailsInfo } from "../invoice-master/invoice-helper";
import InvoiceLayout from "../invoice-master/invoice-layout.component";
import InvoiceDetailCard from "./invoice-detail.component";

const schema = yup.object({
  // itemCode: yup.object().required("Item Code is required"),
  // itemName: yup.object().required("Item Name is required"),
  mrp: yup.number().required("MRP is required"),
  price: yup.number().required("Price is required"),
  // schemeType: yup.object().required("Scheme Type is required"),
  batchNo: yup.string().required("Batch No. is required"),
});

const columnHelper = createColumnHelper();

interface selectedPriceIdentifier {
  key: string;
  description: string;
  priceldentifier_id: number;
}

const priceIdentifierList = [
  {
    priceldentifier_id: 1,
    description: "Discount Percent",
    key: "DISCOUNT_PERCENT",
  },
  {
    priceldentifier_id: 2,
    description: "Discount Amount",
    key: "DISCOUNT_AMOUNT",
  },
  {
    priceldentifier_id: 3,
    description: "Scheme",
    key: "SCHEME",
  },
];
export const InvoiceEntryScreen = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const [openForm, setOpenForm] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [cashDiscountPercent, setCashDiscountPercent] = useState(0);
  const [cashDiscountAmount, setCashDiscountAmount] = useState(0);
  const { invoiceDetails } = useSelector((state: RootState) => state.invoice);
  // const [priceIdentifierList, setPriceIdentifierList] = useState([]);
  const [selectedPriceIdentifier, setSelectedPriceIdentifier] = useState<selectedPriceIdentifier>({
    key: "",
    description: "",
    priceldentifier_id: 0,
  });
  const [quantity, setQuantity] = useState<number>(0);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [useOrderedProducts, setUseOrderedProducts] = useState("ordered");
  const [itemCode, setItemCode] = useState<string>("");
  const [priceList, setPriceList] = useState<any[]>([]);
  const [batchList, setBatchList] = useState<any[]>([]);
  const [gstAmbiguous, setGstAmbiguous] = useState<boolean>(false);
  const [isConfirmingInvoice, setConfirmingInvoice] = useState<boolean>(false);
  const [netInvoiceAmount, setNetInvoiceAmount] = useState<string>("");
  const [selectedBatch, setSelectedBatch] = useState<any>({});
  const [mappedData, setMappedData] = useState<any[]>([]);
  const [tcs, setTcs] = useState<number>(0);
  // const [addedProducts, setAddedProducts] = useState<any[]>([]);
  // const [unknownEntry, setUnknownEntry] = useState<any[]>([]);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [VersioningModal, setVersioningModal] = useState(false);
  const outSideClickRef = useOutsideClick(() => {
    setVersioningModal(false);
  });

  const navigate = useNavigate();

  const smartFormInstance = useForm({
    // @ts-ignore
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: structuredClone({}),
  });

  const { withEventCheck } = useEventStatus();
  const dispatch = useDispatch();
  const { stockist_invoice_attachment_id } = useParams();

  const columns = [
    columnHelper.accessor("item_description", {
      header: "Item Name",
      cell: (info: any) => info.row.original.item_description || "-",
    }),
    columnHelper.accessor("item_code", {
      header: "Item Code",
      cell: (info: any) => info.row.original.item_code || "-",
    }),
    columnHelper.accessor("inv_mrp", {
      header: "Inv. MRP",
      cell: (info: any) => info.row.original.inv_mrp || "-",
    }),
    columnHelper.accessor("ptr", {
      header: "Inv. PTR",
      cell: (info: any) => info.row.original.ptr || "-",
    }),
    columnHelper.accessor("quantity", {
      header: "Qty.",
      cell: (info: any) => info.row.original.quantity,
    }),
    columnHelper.accessor("gross_amt", {
      header: "Gross Amt.",
      cell: (info: any) => info.row.original.gross_amt || "-",
    }),
    columnHelper.accessor("price_identifier", {
      header: "Discount Defined By",
      cell: (info: any) => info.row.original.price_identifier || "-",
    }),
    columnHelper.accessor("discount_amt", {
      header: "Discount %",
      cell: (info: any) => ((info.row.original.discount_amt / (info.row.original.inv_rate * info.row.original.quantity)) * 100)?.toFixed(2) || "",
    }),

    columnHelper.accessor("free_qty", {
      header: "Free Qty",
      cell: (info: any) => info.row.original.free_qty || "-",
    }),

    // columnHelper.accessor("gross_amt", {
    //   header: "Net Amt.",
    //   cell: (info: any) => info.row.original.gross_amt || "-",
    // }),
    columnHelper.accessor("gst", {
      header: "GST %",
      cell: (info: any) => info.row.original.gst || "-",
    }),
    columnHelper.accessor("batch_no", {
      header: "Batch No.",
      cell: (info: any) => info.row.original.batch_no || "-",
    }),
    columnHelper.accessor("action", {
      header: "Action",
      cell: (info: any) => {
        const handleDelete = async (uniqueKey: string) => {
          setMappedData((prev) => prev.filter((item: any) => item?.unique_key !== uniqueKey));
        };
        return (
          <Button onClick={() => handleDelete(info.row.original.unique_key)}>
            <img src={DeleteIcon} alt="delete" className="w-4 h-4" />
          </Button>
        );
      },
    }),
  ];

  const handleFormSubmit = async (data: any) => {
    try {
      const payload = {
        invoice_id: Number(invoiceDetails?.invoice?.invoice_id),
        item_description: useOrderedProducts === "unknown" ? String(data?.itemName?.itemName) : String(data?.itemName),
        item_code: useOrderedProducts === "unknown" ? String(data?.itemCode?.itemCode) : String(data?.itemCode),
        unique_key: String(data?.itemCode) + selectedBatch?.batch_no,
        quantity: Number(quantity),
        gst: data?.gst || "0",
        cash_dis_perc: cashDiscountPercent,
        ptr: Number(price) || "0",
        price_identifier: !selectedPriceIdentifier?.description ? "Discount Amount" : String(selectedPriceIdentifier?.description),
        free_qty: data?.freeQty || "0",
        batch_no: selectedBatch?.batch_no || (data?.batchNo as string),
        inv_rate: String(price) || "0",
        inv_mrp: String(data?.mrp) || "0",
        discount_amt: !selectedPriceIdentifier?.description ? "0" : String(discountAmount),
        counter_code: invoiceDetails?.counter_code,
        is_item_unknown: useOrderedProducts === "unknown",
        gross_amt: Number(price) * Number(quantity),
      };
      setMappedData((prev) => [...prev, payload]);
      resetFormFields();
      // Focus the item name field after form submission
      const itemNameField = document.querySelector('input[name="itemName"]');
      if (itemNameField instanceof HTMLElement) {
        itemNameField?.focus();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCashDiscountPercentChange = (value: string) => {
    const percent = parseFloat(value) || 0;
    setCashDiscountPercent(percent);

    const discountAmount = (calculateSubtotal() * percent) / 100;
    setCashDiscountAmount(discountAmount);

    updateTotal(discountAmount);
  };

  const handleCashDiscountAmountChange = (value: string) => {
    const amount = parseFloat(value) || 0;
    setCashDiscountAmount(amount);

    const percent = (amount / calculateSubtotal()) * 100;
    setCashDiscountPercent(percent);

    updateTotal(amount);
  };

  const updateTotal = (discountAmount: number) => {
    return (calculateSubtotal() - discountAmount).toFixed(2);
  };

  const rowsForItemsAddedTable = (isUnknown: boolean) => {
    const rows: any[] = [];
    mappedData?.length > 0 &&
      mappedData
        ?.filter((item: any) => item?.is_item_unknown === isUnknown)
        .forEach((element: any) => {
          rows.push(flattenObject(element));
        });
    return rows;
  };
  const fetchInvoiceDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getInvoiceDetails(stockist_invoice_attachment_id as string, false);
      dispatch(invoiceActions.setInvoiceDetails(response?.data?.data));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleSubmitProducts = async () => {
    const calculatedTotal = calculateTotal();
    const difference = Math.abs(Number(calculatedTotal) - Number(netInvoiceAmount));

    if (!netInvoiceAmount) {
      toast({
        description: `Please enter net amount`,
        variant: "destructive",
      });
      return;
    }

    if (difference > 10) {
      toast({
        description: `The difference between calculated amount and net amount should not exceed ₹10`,
        variant: "destructive",
      });
      return;
    }

    if (mappedData?.length === 0) {
      toast({
        description: `Please add at least one product`,
        variant: "destructive",
      });
      return;
    }

    try {
      const payload2 = {
        data: {
          invoice_id: Number(invoiceDetails.invoice?.invoice_id),
          invoice_number: invoiceDetails?.invoice?.invoice_number,
          cash_discount_perc: String(cashDiscountPercent),
          cash_discount: String(cashDiscountAmount),
          net_amount: netInvoiceAmount,
          stockist_invoice_attachment_id: invoiceDetails?.stockist_invoice_attachment_id,
          // gst_perc: response?.data?.data?.invoice?.gst_perc || "",
          gross_amount: String(calculateSubtotal()),
          u_code: generateUniqueID(),
          tcs: tcs || 0,
          confirm: true,
          is_manual_discount: cashDiscountAmount > 0,
        },
        primary_key: Number(invoiceDetails?.invoice?.invoice_id),
        event_type: "INVOICE_EDIT",
      };
      const res = await editInvoice(payload2);

      const response = await withEventCheck(
        () => {
          return getInvoiceDetails(stockist_invoice_attachment_id as string, false);
        },
        res?.data?.data?.eventStreamCreated?.streamId
      );
      dispatch(invoiceActions.setInvoiceDetails(response?.data?.data));
      toast({ description: "Invoice confirmed successfully", variant: "default" });
      setConfirmingInvoice(false);

      setMappedData((prev) => prev.map((item) => ({ ...item, p_cash_discount_perc: cashDiscountPercent })));

      try {
        const payload = {
          data: mappedData,
          primary_key: Number(invoiceDetails?.invoice?.invoice_id),
          event_type: "INVOICE_PRODUCT_ADD",
        };
        const res = await updateInvoice(payload);
        const response = await withEventCheck(
          () => {
            return getInvoiceDetails(stockist_invoice_attachment_id as string, false);
          },
          res?.data?.data?.[res?.data?.data]?.eventStreamCreated?.streamId
        );
        dispatch(invoiceActions.setInvoiceDetails(response?.data?.data));
        resetFormFields();
        setIsLoading2(false);
        toast({ description: "Product added successfully", variant: "default" });
        navigate("/transactions/invoice");
      } catch (error: any) {
        console.log(error);
        toast({ description: error?.response?.data?.message, variant: "destructive" });
        setConfirmingInvoice(false);
      }
    } catch (error: any) {
      console.log(error, "error");
      toast({ description: error?.response?.data?.message, variant: "destructive" });
      setIsLoading2(false);
    }
  };

  const fetchProductsData = async (query: string) => {
    if (useOrderedProducts === "unknown") {
      return;
    }
    try {
      return await getSimilarCompanyProducts(invoiceDetails?.company_code, invoiceDetails?.division_code, query, "", 1, 10);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductsDataForItemCode = async (query: string) => {
    if (useOrderedProducts === "unknown") {
      return;
    }
    try {
      return await getSimilarCompanyProducts(invoiceDetails?.company_code, invoiceDetails?.division_code, "", query, 1, 10);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrderedProducts = async (query: string) => {
    if (useOrderedProducts === "unknown") {
      return;
    }
    try {
      return await getOrderedProducts({
        product_name: query,
        counter_code: invoiceDetails?.counter_code,
        item_code: "",
        page: 1,
        page_size: 10,
        company_stockist_code: invoiceDetails?.company_stockist_code,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrderedProductsByItemCode = async (query: string) => {
    if (useOrderedProducts === "unknown") {
      return;
    }
    try {
      return await getOrderedProducts({
        product_name: "",
        counter_code: invoiceDetails?.counter_code,
        item_code: query,
        page: 1,
        page_size: 10,
        company_stockist_code: invoiceDetails?.company_stockist_code,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePercentageChange = (e?: any) => {
    const { setValue }: any = smartFormInstance;
    const percentage = Number(e) / 100;
    const discountAmount = percentage * quantity * price;
    setDiscountAmount(Number(discountAmount?.toFixed(2)));
    setDiscountPercentage(Number(e));
    setValue("discountAmount", discountAmount?.toFixed(2));
    setValue("discountPercentage", Number(e));
  };

  const handleAmountChange = (e?: any) => {
    const { setValue }: any = smartFormInstance;
    const amount = Number(e);
    const discountPercentage = (amount / (quantity * price)) * 100;
    setDiscountAmount(amount);
    setDiscountPercentage(Number(discountPercentage?.toFixed(2)));
    setValue("discountAmount", amount);
    setValue("discountPercentage", discountPercentage?.toFixed(2));
  };

  const handleAutocompleteChange = async (data: any) => {
    const { setValue }: any = smartFormInstance;
    const value = data;
    const { isCustom } = value;
    if (value) {
      !isCustom && setValue("itemName", value?.product_name);
      !isCustom && setValue("itemCode", value?.item_code);
      // setValue("mrp", value?.mrp || "");
      // setValue("price", value?.ptr || "");
      setPrice(value?.ptr);
      setValue("batchNo", "");
      setValue("discountAmount", "");
      setValue("discountPercentage", "");
      setValue("freeQty", "");
      setValue("quantity", "");
      setValue("schemeType", "");
      setValue("gst", "");
      setValue("itemPrice", "");
      setValue("price", "");
      setValue("ptr", "");
      setDiscountPercentage(0);
      setDiscountAmount(0);

      setItemCode(value?.item_code);
    } else {
      // setValue("mrp", "");
      // setValue("price", "");
      setValue("schemeType", "");
      setValue("freeQty", "");
      setValue("discountPercentage", "");
      setValue("discountAmount", "");
      setValue("batchNo", "");
      setValue("itemCode", "");
    }
  };

  const resetFormFields = () => {
    const { reset }: any = smartFormInstance;

    // Reset form fields
    reset();

    // Clear additional state values
    setQuantity(0);
    setPriceList([]);
    setPrice(0);
    setBatchList([]);
    setDiscountPercentage(0);
    setDiscountAmount(0);
    setSelectedPriceIdentifier({
      key: "",
      description: "",
      priceldentifier_id: 0,
    });
    setFetchTrigger(fetchTrigger + 1);
    // setTimeout(() => {
    //   // Focus the Item Name input using the input's ID
    //   const itemNameInput = document.querySelector('[name="itemName"]') as HTMLInputElement;
    //   if (itemNameInput) {
    //     itemNameInput.focus();
    //   }
    // }, 0);
  };

  const handleRadioButtonsForForm = (value: string) => {
    setUseOrderedProducts(value);
    resetFormFields();
  };

  /**
   * Calculates the total invoice amount including GST and discounts
   * Formula: Sum of [(Gross Amount - Discount) + GST] for each item
   * @returns {number} Total invoice amount
   */
  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const amountAfterCashDiscount = subtotal - (subtotal * cashDiscountPercent) / 100;
    const totalGst = calculateTotalGst();
    const tcsCalculatedAmount = amountAfterCashDiscount + totalGst + ((amountAfterCashDiscount + totalGst) * tcs) / 100;
    // return amountAfterCashDiscount + totalGst;
    return tcsCalculatedAmount;
  };

  /**
   * Calculates the raw subtotal before any discounts or taxes
   * Simply sums up the gross amounts of all items
   * @returns {number} Subtotal amount
   */
  const calculateInitialSubtotal = () => {
    let total = 0;
    mappedData?.forEach((item: any) => {
      const { gross_amt, discount_amt } = item;
      total += Number(gross_amt) - Number(discount_amt);
    });
    return total;
  };

  /**
   * Calculates final subtotal after all discounts (including cash discount)
   */
  const calculateSubtotal = () => {
    const initialSubtotal = calculateInitialSubtotal();
    return initialSubtotal;
  };

  // const calculateGstAmount = (item: any) => {
  //   const { gross_amt, gst, discount_amt } = item;
  //   const amountAfterDiscount = Number(gross_amt) - Number(discount_amt);
  //   const gstAmount = (amountAfterDiscount * Number(gst)) / 100;
  //   return gstAmount;
  // };

  const calculateTotalGst = () => {
    let total = 0;
    mappedData?.forEach((item: any) => {
      const { gross_amt, gst, discount_amt } = item;
      // Calculate GST on amount after discount
      let amountAfterItemDiscount = Number(gross_amt) - Number(discount_amt);
      let amountAfterCashDiscount = amountAfterItemDiscount - (amountAfterItemDiscount * cashDiscountPercent) / 100;

      const gstAmount = (amountAfterCashDiscount * Number(gst)) / 100;
      total += gstAmount;
    });
    return total;
  };

  useEffect(() => {
    if (discountAmount || discountPercentage) {
      if (discountAmount) {
        handleAmountChange(discountAmount);
      } else {
        handlePercentageChange(discountPercentage);
      }
    }
  }, [quantity]);

  /**
   * Effect hook to fetch price and batch data when item code changes
   * Only runs for known/ordered products (not unknown items)
   * Makes parallel API calls for efficiency
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch both price list and batch list simultaneously
        Promise.all([
          getInvoicePriceList(itemCode).then((res: any) => setPriceList(res?.data?.data)),
          getInvoiceBatchList(itemCode).then((res: any) => setBatchList(res?.data?.data)),
        ]);
      } catch (error) {
        console.log(error);
      }
    };

    // Only fetch data if we have a valid item code and it's not an unknown product
    if (itemCode && useOrderedProducts !== "unknown") {
      fetchData();
    }
  }, [itemCode, useOrderedProducts, fetchTrigger]);

  /**
   * Effect hook to fetch initial invoice details
   * Runs once on component mount
   */
  useEffect(() => {
    fetchInvoiceDetails();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <InvoiceLayout>
      <div className="flex justify-between px-[3.75rem] mt-6  pb-12 shadow-md border-b-[1px] border-b-[#E9E9E9]">
        <div className="flex items-center flex-col">
          <Button className="flex items-center px-0 py-2 text-lg text-[#7F56D9]" onClick={() => navigate("/transactions/invoice")}>
            <img src={PurpleLeftArrowIcon} alt="back" className="w-3 h-3 mr-3" />
            Back to Invoices
          </Button>
          <div className="">
            <h1 className="text-[#49484C] font-bold text-[2rem]">Invoices</h1>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <Input placeholder="Search" className="px-3 h-[32px] w-[180px] rounded-lg " showIcon={true} />
        </div>
      </div>

      <div className="px-[3.75rem] mx-auto mt-10 relative">
        <div
          onClick={() => {
            setVersioningModal((prev) => !prev);
          }}
          className="p-1 py-3 w-[5%] h-auto border-l-2 border-b-2 absolute top-12 right-0 -mt-12"
        >
          <img onClick={() => {}} src={Book} alt="React Logo" className="h-10 w-14" />
        </div>
        {VersioningModal && (
          <div ref={outSideClickRef}>
            <VersioningCrate type="Invoice" />
          </div>
        )}
        <ul className="mt-5">
          <li className="my-5 mb-10 rounded-lg">
            <InvoiceDetailCard
              isExpandedInitial={true}
              isExpandable={false}
              status={invoiceDetails?.fileStatusHdr?.fuid_status_desc}
              checkDataType={false}
              data={getInvoiceDetailsInfo(invoiceDetails)}
              viewDetails={false}
              invoiceData={invoiceDetails}
              // handleAddItem={handleAddItem}
              fetchInvoiceDetails={fetchInvoiceDetails}
            />
          </li>
        </ul>
        {invoiceDetails?.invoice && (
          <div className="p-0 mb-10 border-2 rounded-lg">
            <div className="m-5">
              <div className="flex items-center justify-between">
                <p className="mb-2 text-lg font-semibold">Invoice Details</p>
                <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
                  <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
                </Button>
              </div>
              <div className="flex items-center gap-x-5">
                <div className="flex items-center mb-5 space-x-6">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="orderedProducts"
                      name="productType"
                      value="ordered"
                      checked={useOrderedProducts === "ordered"}
                      onChange={() => handleRadioButtonsForForm("ordered")}
                      className="mr-2"
                    />
                    <label htmlFor="orderedProducts" className="text-sm text-gray-500">
                      Ordered Products
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="companyProducts"
                      name="productType"
                      value="company"
                      checked={useOrderedProducts === "company"}
                      onChange={() => handleRadioButtonsForForm("company")}
                      className="mr-2"
                    />
                    <label htmlFor="companyProducts" className="text-sm text-gray-500">
                      Company Products
                    </label>
                  </div>
                </div>
                <div className="flex items-center mb-5">
                  <input
                    type="radio"
                    id="unknownOrder"
                    name="productType"
                    value="unknown"
                    checked={useOrderedProducts === "unknown"}
                    onChange={() => handleRadioButtonsForForm("unknown")}
                    className="mr-2"
                  />
                  <label htmlFor="unknownOrder" className="text-sm text-gray-500">
                    Unknown Order
                  </label>
                </div>
              </div>
              <div
                className={`transition-all duration-500 ease-in-out ${isExpanded ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"}`}
                style={{ transitionProperty: "max-height, opacity" }}
              >
                <SmartForm onFormSubmit={handleFormSubmit} schema={schema} isControlled={false} formInstance={smartFormInstance} defaultValues={{}}>
                  <div className="grid grid-cols-4 gap-4">
                    <SmartForm.Autocomplete
                      className="z-10 w-full"
                      suggestionList={[]}
                      placeholder=" "
                      fieldName="itemCode"
                      label="Item Code"
                      showRequiredMark={false}
                      customFieldName="itemCode"
                      uniqueKey="id"
                      displayKey="item_code"
                      allowCustomValue={useOrderedProducts === "unknown"}
                      onChange={(value) => {
                        // update other fields from this if it exists
                        handleAutocompleteChange(value);
                      }}
                      // additionDisplayKey="companyProducts.item_code"
                      isControlled={false}
                      asyncListFunction={
                        useOrderedProducts === "ordered"
                          ? fetchOrderedProductsByItemCode
                          : useOrderedProducts === "company"
                            ? fetchProductsDataForItemCode
                            : ""
                      }
                    />
                    <SmartForm.Autocomplete
                      className="z-10 w-full"
                      suggestionList={[]}
                      placeholder=" "
                      fieldName="itemName"
                      customFieldName="itemName"
                      label="Item Name"
                      showRequiredMark={false}
                      uniqueKey="id"
                      displayKey={"product_name"}
                      allowCustomValue={useOrderedProducts === "unknown"}
                      onChange={(value) => {
                        // update other fields from this if it exists
                        handleAutocompleteChange(value);
                      }}
                      isControlled={false}
                      asyncListFunction={
                        useOrderedProducts === "ordered" ? fetchOrderedProducts : useOrderedProducts === "company" ? fetchProductsData : ""
                      }
                    />
                    <SmartForm.Autocomplete
                      className="z-10 w-full"
                      suggestionList={priceList}
                      placeholder=" "
                      fieldName="itemPrice"
                      label="MRP - PTR"
                      showRequiredMark={false}
                      uniqueKey="company_productprice_id"
                      displayKey="mrp"
                      additionDisplayKey={useOrderedProducts === "unknown" ? "" : "ptr"}
                      customFieldName="itemPrice"
                      allowCustomValue={priceList?.length ? false : true}
                      showAdditionalDisplayKeyWhenSelected={false}
                      onChange={(value) => {
                        // update other fields from this if it exists
                        // handleAutocompleteChange(value);
                        const { setValue }: any = smartFormInstance;
                        setValue("mrp", value?.mrp);
                        setValue("price", value?.ptr);
                        setValue("ptr", value?.ptr);
                        setPrice(value?.ptr);
                        setValue("gst", value?.company_productcode?.gst_pc);
                        setGstAmbiguous(value?.company_productcode?.gst_ambiguous);
                        setValue("quantity", "");
                        setValue("schemeType", "");
                        setValue("batchNo", "");
                        setValue("discountPercentage", "");
                        setValue("discountAmount", "");
                        setValue("freeQty", "");
                      }}
                      isControlled={false}
                      // asyncListFunction={}
                    />
                    <SmartForm.Input
                      className="w-full"
                      inputClassName="w-full"
                      type="text"
                      placeholder=" "
                      fieldName="ptr"
                      label="PTR"
                      disabled={useOrderedProducts !== "unknown"}
                      showRequiredMark={false}
                    />
                    <SmartForm.Input
                      className="w-full"
                      inputClassName="w-full"
                      type="number"
                      placeholder=" "
                      fieldName="price"
                      label="Price"
                      showRequiredMark={false}
                      changeHandler={(e: any) => {
                        setPrice(e);
                      }}
                    />
                    <SmartForm.Input
                      className="w-full"
                      inputClassName="w-full"
                      type="number"
                      placeholder=" "
                      fieldName="gst"
                      label="GST%"
                      showRequiredMark={false}
                      disabled={!gstAmbiguous && useOrderedProducts !== "unknown"}
                    />
                    <SmartForm.Input
                      className="w-full"
                      inputClassName="w-full"
                      type="number"
                      placeholder=" "
                      fieldName="quantity"
                      label="Quantity"
                      value={quantity}
                      showRequiredMark={false}
                      changeHandler={(e: any) => {
                        setQuantity(e);
                      }}
                    />
                    <SmartForm.Autocomplete
                      className="w-full"
                      suggestionList={priceIdentifierList}
                      placeholder=" "
                      fieldName="schemeType"
                      label="Scheme Type"
                      showRequiredMark={false}
                      uniqueKey="key"
                      displayKey="description"
                      onChange={(val) => {
                        setSelectedPriceIdentifier(val);
                      }}
                    />
                    {selectedPriceIdentifier?.key == "SCHEME" && (
                      <SmartForm.Input
                        className="w-full"
                        inputClassName="w-full"
                        type="text"
                        label="Free Qty"
                        showRequiredMark={false}
                        placeholder=" "
                        fieldName="freeQty"
                      />
                    )}

                    {(selectedPriceIdentifier?.key == "DISCOUNT_PERCENT" || selectedPriceIdentifier?.key == "DISCOUNT_AMOUNT") && (
                      <>
                        {" "}
                        <SmartForm.Input
                          className="w-full"
                          inputClassName="w-full"
                          type="text"
                          label="Discount Percent"
                          showRequiredMark={false}
                          placeholder=" "
                          value={discountPercentage}
                          disabled={selectedPriceIdentifier?.key == "DISCOUNT_AMOUNT"}
                          fieldName="discountPercentage"
                          changeHandler={handlePercentageChange}
                        />
                        <SmartForm.Input
                          className="w-full"
                          inputClassName="w-full"
                          type="number"
                          label="Discount Amount"
                          showRequiredMark={false}
                          placeholder=" "
                          disabled={selectedPriceIdentifier?.key == "DISCOUNT_PERCENT"}
                          fieldName="discountAmount"
                          value={discountAmount}
                          changeHandler={handleAmountChange}
                        />{" "}
                      </>
                    )}

                    <SmartForm.Autocomplete
                      className="w-full"
                      label="Batch No."
                      showRequiredMark={false}
                      placeholder=" "
                      fieldName="batchNo"
                      suggestionList={batchList}
                      uniqueKey="batch_no"
                      displayKey="batch_no"
                      allowCustomValue={true}
                      onChange={(val) => {
                        setSelectedBatch(val);
                        const { setValue }: any = smartFormInstance;
                        setValue("batchNo", val?.batch_no);
                      }}
                    />
                  </div>
                  <div className="flex justify-end pb-5 mt-5 gap-x-2">
                    {/* <Button className="font-normal text-gray-700 bg-[#F1F1F1] h-9 border-2 border-gray-200" onClick={resetFormFields}>
                            Add another item
                          </Button> */}

                    <Button className="h-9 text-white font-normal bg-[#8051FF] shadow-sm" type="submit">
                      Add Product
                    </Button>
                  </div>
                </SmartForm>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col mt-5 gap-x-5 xl:flex-row">
          <div className="flex flex-col w-full gap-4 p-8 border rounded-lg xl:w-9/12">
            <SubTable
              isLoading={isLoading2}
              title="Items Added"
              subtitle="Review and manage all items added"
              showheader={true}
              columns={columns}
              data={rowsForItemsAddedTable(false)}
            />
            <SubTable
              isLoading={isLoading2}
              title="Unknown Order"
              subtitle="Review and manage all items added"
              showheader={true}
              columns={columns}
              data={rowsForItemsAddedTable(true)}
            />
          </div>
          <div className="w-full p-8 mt-10 border rounded-lg xl:w-3/12 xl:mt-0">
            <p className="mb-2 text-lg font-semibold">Calculations</p>
            <div className="flex flex-col pb-10 gap-y-1">
              <p className="flex justify-between py-2 text-sm">
                Subtotal <span className="">₹{(calculateSubtotal() - cashDiscountAmount)?.toFixed(2)}</span>
              </p>
              <p className="flex justify-between py-2 text-sm">
                <span className="mr-2">Cash Discount %</span>
                <div className="">
                  <Input
                    enableArrows={false}
                    showIcon={false}
                    type="number"
                    placeholder="0"
                    value={cashDiscountPercent}
                    onChange={(e: any) => handleCashDiscountPercentChange(e?.target?.value)}
                    className="w-24 py-1 text-right border border-gray-300 rounded-lg"
                  />{" "}
                </div>
              </p>
              <p className="flex justify-between py-2 text-sm">
                <span className="mr-2">Cash Discount Amount</span>
                <div className="">
                  <Input
                    enableArrows={false}
                    showIcon={false}
                    type="number"
                    placeholder="0"
                    value={cashDiscountAmount?.toFixed(2)}
                    onChange={(e: any) => handleCashDiscountAmountChange(e?.target?.value)}
                    className="w-24 py-1 text-right border border-gray-300 rounded-lg"
                  />{" "}
                </div>
              </p>
              <p className="flex justify-between py-2 text-sm">
                <span className="mr-2">TCS %</span>
                <div className="">
                  <Input
                    enableArrows={false}
                    showIcon={false}
                    type="number"
                    placeholder="TCS"
                    value={tcs}
                    onChange={(e: any) => setTcs(e?.target?.value)}
                    className="w-24 py-1 text-right border border-gray-300 rounded-lg"
                  />{" "}
                </div>
              </p>
              <p className="flex justify-between py-2 text-sm">
                GST Amount<span className="font-semibold">₹{calculateTotalGst()?.toFixed(2)}</span>
              </p>
              <p className="flex justify-between py-2 text-sm">
                Total (as calculated) <span className="font-semibold">₹{calculateTotal()?.toFixed(2)}</span>
              </p>
              <p className="flex justify-between py-2 text-sm">
                <span className="mr-2">
                  Net Inv. Amt.<span className="text-red-500"> *</span>
                </span>
                <div className="">
                  <Input
                    enableArrows={false}
                    showIcon={false}
                    type="number"
                    placeholder="Net Amt."
                    value={netInvoiceAmount}
                    onChange={(e: any) => setNetInvoiceAmount(e?.target?.value)}
                    className="w-24 py-1 text-right border border-gray-300 rounded-lg"
                  />{" "}
                </div>
              </p>
              <Button
                className="w-full h-9 mt-5 font-normal text-white bg-[#8051FF] shadow-sm"
                isLoading={isConfirmingInvoice}
                onClick={handleSubmitProducts}
              >
                Confirm Invoice
              </Button>
            </div>
          </div>
        </div>
      </div>
    </InvoiceLayout>
  );
};
