import jwtDecode from "jwt-decode";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "src/components";
import { getCookieValue } from "src/constants/functions";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import UinDetails from "src/pages/counter-details/uin-details-form.component";
import { checkUinRequired, validateUin } from "src/services/counter-api";
import { queryEmployeeDetails } from "src/services/employee-api";

interface DivisionUINStatus {
  divisionCode: string;
  positionCode: string;
  isVerified: boolean;
  isSkipped: boolean;
  psrUCode: string;
  requiresUIN: boolean;
  allowsAlternateUIN: boolean;
}

const OrderUinConfirmation = ({
  divisionCode,
  counterCode,
  counterLocationCode,
  onUinVerified,
}: {
  divisionCode: string[];
  counterCode: string;
  counterLocationCode: string;
  onUinVerified: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentDivisionIndex, setCurrentDivisionIndex] = useState(0);
  const [divisionStatuses, setDivisionStatuses] = useState<DivisionUINStatus[]>([]);
  const [validationResponse, setValidationResponse] = useState<any>(null);
  const [uinInput, setUinInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { selectedCounterData } = useSelector((state: RootState) => state.ord);

  const { toast } = useToast();

  const checkUINValidation = async () => {
    try {
      setIsLoading(true);

      const divisionStatuses = await initializeDivisionStatuses();

      // Check if any division requires UIN validation
      const requiresUinValidation = divisionStatuses.some((division) => division.requiresUIN && !division.isVerified && !division.isSkipped);

      if (!requiresUinValidation) {
        // If no divisions require UIN validation, proceed
        onUinVerified();
        return true;
      }

      // Find the first unverified division that requires UIN
      const nextDivisionIndex = divisionStatuses.findIndex((division: any) => division.requiresUIN && !division.isVerified && !division.isSkipped);

      if (nextDivisionIndex !== -1) {
        setCurrentDivisionIndex(nextDivisionIndex);
        setIsOpen(true);
        return false;
      }

      return true;
    } catch (error) {
      toast({
        description: "Failed to check UIN requirement",
        variant: "destructive",
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyUIN = async () => {
    try {
      const currentDivision = divisionStatuses[currentDivisionIndex];

      // Don't proceed if no UIN is entered
      if (!uinInput) {
        toast({
          description: "Please enter a UIN",
          variant: "destructive",
        });
        return;
      }

      const token = await getCookieValue("idToken");
      const decodedToken: any = await jwtDecode((token?.toString() as string) || "");
      const response = await validateUin(
        {
          Company_DivisionCode: currentDivision.divisionCode?.toString(),
          PSR_UCode: currentDivision.psrUCode?.toString(),
          Company_UIN: uinInput,
          UserUCode: decodedToken["custom:ucode"],
        },
        token
      );

      setValidationResponse(response);

      if (response?.status === 200) {
        // Show UIN details after successful validation
        return true;
      } else {
        toast({
          description: response?.data?.message || "UIN validation failed",
          variant: "destructive",
        });
        return false;
      }
    } catch (error) {
      toast({
        description: "Failed to validate UIN",
        variant: "destructive",
      });
      return false;
    }
  };

  const handleSkipDivision = () => {
    setDivisionStatuses((prev) => {
      const updated = [...prev];
      updated[currentDivisionIndex].isSkipped = true;
      return updated;
    });

    // Check if this is the last division or if all remaining divisions are already verified/skipped
    const remainingDivisions = divisionStatuses.slice(currentDivisionIndex + 1);
    const hasRemainingUnverifiedDivisions = remainingDivisions.some(
      (division) => division.requiresUIN && !division.isVerified && !division.isSkipped
    );

    if (!hasRemainingUnverifiedDivisions) {
      // If no more divisions need verification, close modal and call onUinVerified
      setIsOpen(false);
      onUinVerified();
    } else {
      // Otherwise, proceed to next division
      handleNext();
    }
  };

  const handleVerifyDivision = () => {
    setDivisionStatuses((prev) => {
      const updated = [...prev];
      updated[currentDivisionIndex].isVerified = true;
      return updated;
    });
    handleNext();
  };

  const handleNext = (data?: any) => {
    const currentStatuses = data || divisionStatuses;

    // Find next division that requires UIN verification
    const nextDivisionIndex = currentStatuses.findIndex(
      (division: any, index: number) => index > currentDivisionIndex && division.requiresUIN && !division.isVerified && !division.isSkipped
    );

    if (nextDivisionIndex !== -1) {
      // Found next division requiring verification
      setCurrentDivisionIndex(nextDivisionIndex);
      setIsOpen(true);
      setValidationResponse(null);
      setUinInput(""); // Clear UIN input
    } else {
      // Check if there are any remaining divisions that need verification
      const hasUnverifiedDivisions = currentStatuses.some((division: any) => division.requiresUIN && !division.isVerified && !division.isSkipped);

      if (!hasUnverifiedDivisions) {
        // All divisions are either verified or skipped
        setIsOpen(false);
        onUinVerified();
      } else {
        // Go back to first unverified division
        const firstUnverifiedIndex = currentStatuses.findIndex(
          (division: any) => division.requiresUIN && !division.isVerified && !division.isSkipped
        );
        setCurrentDivisionIndex(firstUnverifiedIndex);
        setIsOpen(true);
        setValidationResponse(null);
        setUinInput("");
      }
    }
  };

  const checkUINRequirementForDivision = async (divisionCode: string) => {
    try {
      const token = await getCookieValue("idToken");
      const decodedToken: any = await jwtDecode((token?.toString() as string) || "");

      const body = {
        UserUCode: decodedToken["custom:ucode"],
        Company_DivisionCode: JSON.stringify(divisionCode),
        Counter_Code: counterCode,
        Location_Code: JSON.stringify(counterLocationCode),
      };

      const response = await checkUinRequired(body, token);
      return {
        isUinRequired: response?.data?.UINValidationRequired,
        isAlternateUinAllowed: response?.data?.AlternateUIN_Status,
      };
    } catch (error) {
      toast({
        description: "Failed to fetch UIN validation status",
        variant: "destructive",
      });
      return null;
    }
  };

  const initializeDivisionStatuses = async () => {
    // Initialize division statuses with division-specific position codes
    const initialStatuses = divisionCode.map((code) => {
      const divisionData = selectedCounterData?.counter_field_force_id?.find((div: any) => div.company_division_code.company_divisioncode == code);
      return {
        divisionCode: code,
        positionCode: divisionData?.position_code || "",
        isVerified: false,
        isSkipped: false,
        psrUCode: "",
        requiresUIN: false,
        allowsAlternateUIN: false,
      };
    });

    // Fetch employee details
    try {
      const employeeResponse = await fetchEmployeeDetailsForPositionCode(initialStatuses.map((status) => status.positionCode));

      // Check UIN requirements for each division
      for (let status of initialStatuses) {
        const uinRequirements = await checkUINRequirementForDivision(status.divisionCode);
        if (uinRequirements) {
          status.requiresUIN = uinRequirements.isUinRequired;
          status.allowsAlternateUIN = uinRequirements.isAlternateUinAllowed;
        }
        status.psrUCode = employeeResponse[status.positionCode];
      }

      setDivisionStatuses(initialStatuses);
      return initialStatuses;
    } catch (error) {
      toast({
        description: "Failed to initialize division statuses",
        variant: "destructive",
      });
      return [];
    }
  };

  const fetchEmployeeDetailsForPositionCode = async (positionCodes: string[]) => {
    const response = await queryEmployeeDetails(`/details/?position_code=${positionCodes}`);

    const employeePositionCodesANdUID: { [key: string]: string } = {};
    response?.data?.data?.forEach((item: any) => {
      employeePositionCodesANdUID[item?.emp_position_hdr?.position_code] = item?.uid;
    });
    return employeePositionCodesANdUID;
  };

  const modal = (
    <Modal
      isOpen={isOpen}
      isHeader={true}
      title={`UIN Verification - Division ${divisionStatuses[currentDivisionIndex]?.divisionCode}`}
      onCloseModal={() => setIsOpen(false)}
    >
      <div className="p-6">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Enter UIN</label>
          <input
            type="text"
            className="w-full p-2 border rounded-md"
            placeholder="Enter UIN for verification"
            onChange={(e) => setUinInput(e.target.value)}
            value={uinInput}
          />
        </div>

        {validationResponse?.status === true && (
          <UinDetails uinData={validationResponse} onBack={() => setIsOpen(false)} onConfirm={handleVerifyDivision} />
        )}

        <div className="flex justify-between mt-4">
          <Button onClick={handleSkipDivision}>Skip Division</Button>
          <Button onClick={handleVerifyUIN} className="bg-primary text-white" disabled={!uinInput}>
            Verify UIN
          </Button>
        </div>
      </div>
    </Modal>
  );

  return {
    modal,
    isOpen,
    checkUINValidation,
    divisionStatuses,
    isLoading,
  };
};

export default OrderUinConfirmation;
