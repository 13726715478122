import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "src/components";
import Loader from "src/components/ui/loader.component";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import { verifyOrderInvoice } from "src/services/order-api";

interface VerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  setIsCheckingPendingInvoices: Dispatch<SetStateAction<boolean>>;
  isCheckingPendingInvoices: boolean;
}

export const VerificationModal = ({
  isOpen,
  onClose,
  onConfirm,
  isCheckingPendingInvoices,
  setIsCheckingPendingInvoices,
}: VerificationModalProps) => {
  const [pendingInvoicesNumbers, setPendingInvoicesNumbers] = useState("");
  const [isModal2Open, setIsModal2Open] = useState(false);
  const { toast } = useToast();
  const [isRegistered, setIsRegistered] = useState<string>("");
  const [registeredMsg, setRegisteredMsg] = useState<string>("");
  const { selectedCounterData } = useSelector((state: RootState) => state.ord);
  const { idTokenDetails } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isOpen) {
      setIsCheckingPendingInvoices(true);
      const arrayTOCheck = ["INDPH-PH", "INDPHPH", "INDPHHS", "INDPH-HS"];
      const isIndph = arrayTOCheck.includes(selectedCounterData?.counter_location?.drph_lnk_type?.dr_ph_lnk_code);
      verifyOrderInvoice({
        User_UCode: idTokenDetails?.["custom:ucode"],
        Counter_UCode: selectedCounterData?.counter_location?.counter_hdr_id?.uid,
        Location_Code: isIndph ? 1 : selectedCounterData?.counter_location?.location_code,
        CompanyCode: selectedCounterData?.company_code,
      })
        .then((res: any) => {
          if (res?.status !== 200) throw new Error(res?.data?.error);
          setPendingInvoicesNumbers(res?.data?.invoicenumbers);
          if (!res?.data?.invoicenumbers?.length && res?.data?.is_registered == "N") onConfirm();
          if (!res?.data?.invoicenumbers?.length && res?.data?.is_registered == "Y") setIsModal2Open(true);
          setIsRegistered(res?.data?.is_registered);
          setRegisteredMsg(res?.data?.registered_msg);
        })
        .catch((error: any) => {
          console.log(error);
          toast({ description: error?.response?.data?.message || "Failed to fetch pending invoices", variant: "destructive" });
        })
        .finally(() => {
          setIsCheckingPendingInvoices(false);
        });
    }
  }, [isOpen]);

  if (!isOpen || isCheckingPendingInvoices) return null;

  return (
    <>
      <Modal isOpen={isOpen && pendingInvoicesNumbers?.length > 0} isHeader={false} onCloseModal={onClose}>
        {false ? (
          <Loader />
        ) : (
          <div className="p-8 ">
            <div className="text-center">
              <h2 className="mb-3 text-2xl font-bold text-gray-900">Confirm Order Placement</h2>
              <p className="mb-6 text-gray-600">Please review the following details before confirming your order</p>
            </div>

            <div className="mb-8">
              <div className="flex items-center mb-4">
                <svg className="w-5 h-5 mr-2 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                <h3 className="text-lg font-semibold text-gray-800">Pending Invoices</h3>
              </div>

              <div className="p-4 border-l-4 border-yellow-400 rounded-lg bg-yellow-50">
                <div className="flex items-center">
                  <span className="text-gray-700">{pendingInvoicesNumbers}</span>
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <Button
                onClick={onClose}
                className="min-w-[120px] px-6 py-2.5 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 transition-colors duration-200"
              >
                No
              </Button>
              <Button
                onClick={() => {
                  isRegistered == "Y" ? setIsModal2Open(true) : onConfirm();
                }}
                className="min-w-[120px] px-6 py-2.5 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-colors duration-200 shadow-sm"
              >
                Yes
              </Button>
            </div>
          </div>
        )}
      </Modal>
      <Modal isOpen={isModal2Open} isHeader={false} onCloseModal={() => setIsModal2Open(false)}>
        {false ? (
          <Loader />
        ) : (
          <div className="p-8 ">
            <div className="text-center">
              {/* <h2 className="mb-3 text-2xl font-bold text-gray-900">Confirm Order Placement</h2> */}
              <p className="mb-6 text-gray-600">{registeredMsg}</p>
            </div>

            <div className="flex justify-end gap-4">
              <Button
                onClick={() => {
                  setIsModal2Open(false);
                  onClose();
                }}
                className="min-w-[120px] px-6 py-2.5 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 transition-colors duration-200"
              >
                No
              </Button>
              <Button
                onClick={onConfirm}
                className="min-w-[120px] px-6 py-2.5 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-colors duration-200 shadow-sm"
              >
                Yes
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
