import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface InvoiceState {
  invoiceData: any | null | undefined | any[];
  invoicePriorityData: any | null | undefined | any[];
  invoiceEscalatedData: any | null | undefined | any[];
  invoiceApprovalList: any | null | undefined | any[];
  invoiceDetails: any | null | undefined | any[];
  InvoiceFilters: {
    division: any[] | undefined;
    company: any[] | undefined;
    status: any[] | undefined;
    stockist: any[] | undefined;
    dateRange: any[] | undefined;
    assignee: any[] | undefined;
    city: any[] | undefined;
    district: any[] | undefined;
    state: any[] | undefined;
    fuidNo: any[] | undefined;
  };
  InvoiceFiltersData: {
    division: any[] | undefined;
    company: any[] | undefined;
    status: any[] | undefined;
    stockist: any[] | undefined;
    dateRange: any[] | undefined;
    assignee: any[] | undefined;
    city: any[] | undefined;
    district: any[] | undefined;
    state: any[] | undefined;
    fuidNo: any[] | undefined;
  };
  isResetFilter: boolean;
  invoiceApprovalData: any | null | undefined | any[];
}

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    invoiceData: [],
    invoiceDetails: [],
    invoicePriorityData: [],
    invoiceEscalatedData: [],
    invoiceApprovalList: [],
    InvoiceFilters: {
      division: [],
      company: [],
      status: [],
      stockist: [],
      dateRange: [],
      assignee: [],
      city: [],
      district: [],
      state: [],
      fuidNo: [],
    },
    InvoiceFiltersData: {
      division: [],
      company: [],
      status: [],
      stockist: [],
      dateRange: [],
      assignee: [],
      city: [],
      district: [],
      state: [],
      fuidNo: [],
    },
    isResetFilter: false,
    invoiceApprovalData: [],
  } as InvoiceState,
  reducers: {
    setInvoiceData(state, action: PayloadAction<any[] | null | undefined>): void {
      state.invoiceData = action.payload;
    },
    setInvoicePriorityData(state, action: PayloadAction<any[] | null | undefined>): void {
      state.invoicePriorityData = action.payload;
    },
    setInvoiceEscalatedData(state, action: PayloadAction<any[] | null | undefined>): void {
      state.invoiceEscalatedData = action.payload;
    },
    setInvoiceApprovalList(state, action: PayloadAction<any[] | null | undefined>): void {
      state.invoiceApprovalList = action.payload;
    },
    setInvoiceDetails(state, action: PayloadAction<any[] | null | undefined>): void {
      state.invoiceDetails = action.payload;
    },
    setInvoiceFilters(state, action: PayloadAction<{ key: keyof typeof state.InvoiceFilters; value: any[] | undefined }>) {
      const { key, value } = action.payload;
      if (key === "dateRange" && value) {
        state.InvoiceFilters[key] = value.map((dateString) => new Date(dateString).toISOString()); // Convert back to Date
      } else {
        state.InvoiceFilters[key] = value;
      }
    },

    setInvoiceFiltersData(state, action: PayloadAction<{ key: keyof typeof state.InvoiceFiltersData; value: any[] | undefined }>) {
      const { key, value } = action.payload;
      state.InvoiceFiltersData[key] = value;
    },
    setIsResetFilter(state, action: PayloadAction<boolean>) {
      state.isResetFilter = action.payload;
    },
    setInvoiceApprovalData(state, action: PayloadAction<any[] | null | undefined>): void {
      state.invoiceApprovalData = action.payload;
    },
  },
});

export const invoiceActions = invoiceSlice.actions;
export { invoiceSlice };
