import { useSelector } from "react-redux";
import { Button, MultiAutocomplete } from "src/components";
import { RootState } from "src/context/store";
import DeleteIcon from "../../assets/delete.svg";
import { useDispatch } from "react-redux";
import { ordActions } from "src/context/order-slice";
import { useToast } from "src/hooks/useToast";

interface OrderReviewProductCardProps {
  clusterProducts: any;
  clusterStockist: any;
  selectedStockist: any;
  handleStockistChange: (value: any[], type: string) => void;
}

const OrderReviewProductCard = ({ clusterProducts, clusterStockist, selectedStockist, handleStockistChange }: OrderReviewProductCardProps) => {
  const { cartDataObj } = useSelector((state: RootState) => state?.ord);
  const dispatch = useDispatch();
  const { toast } = useToast();

  const handleAdjustToSchemeQty = (counter_product_lnk_id: string) => {
    const currentProd = cartDataObj?.[counter_product_lnk_id];
    const cartQty = currentProd?.cartQty || 0;
    const schemeqty_cp = currentProd?.counter_product_slab?.[0]?.schemeqty_cp || 1;

    if (schemeqty_cp > 0 && cartQty % schemeqty_cp !== 0) {
      const adjustedQty = Math.ceil(cartQty / schemeqty_cp) * schemeqty_cp;

      // Update the cartDataObj with the adjusted quantity
      dispatch(
        ordActions.setCartDataObj({
          key: counter_product_lnk_id,
          value: {
            ...currentProd,
            cartQty: adjustedQty,
          },
        })
      );
      toast({ description: "Quantity adjusted to the nearest multiple of scheme quantity", variant: "default" });
    }
  };

  const handleDecrement = (counter_product_lnk_id: string) => {
    const currentProd = cartDataObj?.[counter_product_lnk_id];
    const currentQty = currentProd?.cartQty || 0;
    const newQty = Number(currentQty) - 1;

    // if (Number(newQty) <= 0) return;
    if (newQty < currentProd?.counter_product_slab?.[0]?.min_order_qty) {
      toast({ description: `Minimum order quantity is ${currentProd?.counter_product_slab?.[0]?.min_order_qty}`, variant: "destructive" });
      return;
    } else {
      dispatch(
        ordActions.setCartDataObj({
          key: counter_product_lnk_id,
          value: {
            ...currentProd,
            cartQty: newQty,
          },
        })
      );
    }
  };

  const handleIncrement = (counter_product_lnk_id: string) => {
    const currentProd = cartDataObj?.[counter_product_lnk_id];
    const currentQty = currentProd?.cartQty || 0;
    const newQty = Number(currentQty) + 1;
    if (newQty > currentProd?.counter_product_slab?.[0]?.max_order_qty) {
      toast({ description: `Maximum order quantity is ${currentProd?.counter_product_slab?.[0]?.max_order_qty}`, variant: "destructive" });
      return;
    } else {
      dispatch(
        ordActions.setCartDataObj({
          key: counter_product_lnk_id,
          value: {
            ...currentProd,
            cartQty: newQty,
          },
        })
      );
    }
  };

  const handleDelete = (counter_product_lnk_id: string) => {
    dispatch(ordActions.removeCartDataObj({ key: counter_product_lnk_id }));
  };

  return (
    <div>
      <div className="flex justify-center w-full gap-10 mt-0">
        <div className="mt-10 w-[100%]">
          <div className="mb-6 mt-4 flex items-center gap-2">
            <div className="flex items-center gap-10">
              <div className="flex items-center gap-2">
                <p className="text-[1rem] text-gray-800 font-semibold shrink-0">Cluster Name: </p>
                <p className="text-[1rem] text-indigo-500 font-semibold shrink-0">{clusterStockist?.[0]?.cluster_name}</p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-[1rem] text-gray-800 font-semibold shrink-0">Select Stockist : </p>

                <MultiAutocomplete
                  inputClassName="w-full"
                  placeholder="Choose a stockist"
                  suggestionList={clusterStockist?.map((item: any) => ({
                    name: item?.owner_name,
                    id: item?.stockist_id,
                    company_stockist_code: item?.company_stockist_code,
                    medvol_stockist_code: item?.medvol_stockist_code,
                    cluster_code: item?.cluster_code,
                    stockist_cluster: item?.stockist_cluster,
                    counter_stockist_Lnk_Id: item?.counter_stockist_Lnk_Id,
                    name_id: `${item?.cluster_name} - ${item?.owner_name}`,
                    is_default: item?.is_default || false,
                    disabled: item?.disabled || item?.is_default,
                    unique_stockist_id: item?.unique_stockist_id || `district-${item?.cluster_code}-${item?.stockist_id}`,
                  }))}
                  controlledValue={selectedStockist || []}    
                  uniqueKey="counter_stockist_Lnk_Id"
                  displayKey="name_id"
                  onSelectionChange={handleStockistChange}
                />
              </div>
            </div>
          </div>
          <div className="">
            {/* Header Section */}

            {/* Products Table */}
            <div className="bg-white rounded-lg">
              <div className="overflow-x-auto rounded-lg">
                <table className="min-w-full table-auto border-collapse border border-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Product Name</th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Division</th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Scheme</th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Price (PTR)</th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Min Order Qty</th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Max Order Qty</th>

                      <th className="px-6 py-3 w-[14rem] text-left text-sm font-medium text-gray-600 border-b border-gray-200">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clusterProducts &&
                      clusterProducts?.map((item: any) => (
                        <tr key={item?.counter_product_lnk_id} className="hover:bg-gray-50 border-b border-gray-200">
                          <td className="px-6 py-4 text-sm text-gray-800">{item?.companyProducts?.name}</td>
                          <td className="px-6 py-4 text-sm text-gray-800">{item?.counter_ff_div_id?.company_division_code?.division_name || "-"}</td>
                          <td className="w-[14rem] px-6 py-6 flex justify-between text-sm text-gray-800">
                            {item?.priceldentifier?.description || "-"} :
                            {item?.priceldentifier?.priceldentifier_id == "2" && (
                              <div className="rounded-md">
                                <span className="text-sm border border-gray-300 px-2 py-1 rounded-md text-gray-900">
                                  {item?.counter_product_slab?.[0]?.schemeqty_cp} + {item?.counter_product_slab?.[0]?.quantity_cp}
                                </span>
                              </div>
                            )}
                            {/* Fixed Price Section */}
                            {item?.priceldentifier?.priceldentifier_id == "3" && item?.counter_product_slab?.[0]?.fixedprice_cp && (
                              <div className="rounded-md">
                                <span className="text-sm border border-gray-300 px-2 py-1 rounded-md text-gray-900">
                                  ₹{item?.counter_product_slab?.[0]?.fixedprice_cp}
                                </span>
                              </div>
                            )}
                            {/* Discount Section */}
                            {item?.priceldentifier?.priceldentifier_id == "1" && item?.counter_product_slab?.[0]?.discountonptr_cp && (
                              <div className="rounded-md">
                                <span className="text-sm border border-gray-300 px-2 py-1 rounded-md text-gray-900">
                                  {item?.counter_product_slab?.[0]?.discountonptr_cp}%
                                </span>
                              </div>
                            )}
                          </td>
                          {/* <td className="px-6 py-4 text-sm text-gray-800">₹{item?.companyProducts?.ptr || "N/A"}</td> */}
                          <td className="px-6 py-4 text-sm text-gray-800">₹{item?.counter_product_slab?.[0]?.price || "N/A"}</td>
                          <td className="px-6 py-4 text-sm text-gray-800">{item?.counter_product_slab?.[0]?.min_order_qty || "N/A"}</td>
                          <td className="px-6 py-4 text-sm text-gray-800">{item?.counter_product_slab?.[0]?.max_order_qty || "N/A"}</td>
                          <td className="px-6 py-4 text-sm text-gray-800">
                            {cartDataObj?.[item?.counter_product_lnk_id] && (
                              <div className="flex items-center gap-2" onMouseLeave={() => handleAdjustToSchemeQty(item.counter_product_lnk_id)}>
                                <button
                                  onClick={() => handleDecrement(item?.counter_product_lnk_id)}
                                  className="px-3 py-1 rounded-lg border border-[#d0d0d0] bg-white"
                                >
                                  -
                                </button>

                                <p className="px-3 py-1 text-center">{cartDataObj?.[item?.counter_product_lnk_id]?.cartQty}</p>
                                <button
                                  onClick={() => handleIncrement(item?.counter_product_lnk_id)}
                                  className="px-3 py-1 rounded-lg border border-[#d0d0d0] bg-white"
                                >
                                  +
                                </button>
                                <Button onClick={() => handleDelete(item?.counter_product_lnk_id)}>
                                  <img src={DeleteIcon} alt="delete" className="w-4 h-4" />
                                </Button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderReviewProductCard;
