import React, { useState } from "react";
import Button from "../../components/Button";
import SmartForm from "../../components/SmartForm";
import { putAcceptOrderRequest, putRejectOrderRequest } from "src/services/order-api";
import { RootState } from "src/context/store";
import { useSelector } from "react-redux";
import { useToast } from "src/hooks/useToast";

interface OrderApprovalFormProps {
  type: boolean;
  order_id: number;
  closeModal: (modalName: string) => void;
  onSuccess: (streamId: string) => void;
  position_code: string;
}

const OrderApprovalForm: React.FC<OrderApprovalFormProps> = ({ type, order_id, closeModal, onSuccess, position_code }) => {
  const { selectedCounterData } = useSelector((state: RootState) => state.ord);
  const [remarks, setRemarks] = useState("");
  const { toast } = useToast();

  async function acceptRequestHandler(data: any) {
    if (!data?.remarks?.trim()) {
      toast({
        description: "Comment cannot be empty",
        variant: "destructive",
      });
      return;
    }
    try {
      const payload = {
        data: {
          position_code: position_code,
          order_id: order_id,
          action: "approve",
          remarks: data?.remarks.trim(),
        },
        event_type: "ORDER_APPROVE",
        primary_key: order_id,
      };
      // console.log({ payload });
      const res = await putAcceptOrderRequest(payload, selectedCounterData?.company_code);

      if (res.status !== 200) throw new Error();
      closeModal("approveCounter");
      setTimeout(() => {
        onSuccess(res?.data?.data?.data?.eventStreamCreated?.streamId);
      }, 1500);
      toast({
        description: "Order has been moved to next level for approval",
        variant: "default",
      });
    } catch (error: any) {
      console.log(error);
      closeModal("approveCounter");
      toast({
        description: error?.response?.data?.message || "Failed to approve order",
        variant: "destructive",
      });
    }
  }

  async function rejectRequestHandler(data: any) {
    if (!data?.remarks?.trim()) {
      toast({
        description: "Comment cannot be empty",
        variant: "destructive",
      });
      return;
    }
    try {
      const payload = {
        data: {
          position_code: position_code,
          order_id: order_id,
          remarks: data?.remarks?.trim(),
        },
        event_type: "ORDER_REJECT",
        primary_key: order_id,
      };

      const res = await putRejectOrderRequest(payload, selectedCounterData?.company_code);

      if (res.status !== 200) throw new Error();
      closeModal("rejectCounter");
      setTimeout(() => {
        onSuccess(res?.data?.data?.data?.eventStreamCreated?.streamId);
      }, 1500);
      toast({
        description: "Order has been rejected successfully",
        variant: "default",
      });
    } catch (error: any) {
      toast({
        description: error?.response?.data?.message || "Failed to reject order",
        variant: "destructive",
      });
    }
  }

  // const handlePendingState = async (config: any, resolve: any, reject: any, attempt = 0) => {
  //   const params = new URLSearchParams(window.location.search);

  //   if (ApiBlocker.isEventPending) {
  //     if (attempt >= 10) {
  //       clearTimeout(pendingTimer);
  //       reject(new Error("Maximum retry attempts reached."));
  //       return;
  //     }
  //     clearTimeout(pendingTimer); // Clear the previous timer
  //     pendingTimer = setTimeout(async () => {
  //       const counterId = window.sessionStorage.getItem("doctor_counter_id") || params.get("id");
  //       if (counterId) {
  //         await ApiBlocker.eventChecked(`counter_${counterId}`);
  //       }
  //       handlePendingState(config, resolve, reject, attempt + 1); // Recursively call the function until the event is not pending
  //     }, 1000); // Wait for 1 second before checking again
  //   } else {
  //     if (config?.data?.data) {
  //       const payload = structuredClone(config?.data?.data);
  //       const trimmedPayload = trimObjectValues(payload);
  //       config.data.data = trimmedPayload;
  //     }
  //     resolve(addAuthHeader(config)); // Proceed with the API call if not pending
  //   }
  // };

  return (
    <SmartForm
      isControlled={false}
      defaultValues={{}}
      onFormSubmit={(data: any) => {
        type ? acceptRequestHandler(data) : rejectRequestHandler(data);
      }}
    >
      <section className="flex flex-col gap-2 px-5 py-5 w-100">
        <SmartForm.TextArea
          type="text"
          value={remarks}
          onChange={(e: any) => {
            console.log(e.target.value);

            setRemarks(e.target.value);
          }}
          className=""
          placeholder="Type comment here..."
          fieldName="remarks"
          label="Comments"
        />
      </section>
      <footer className="border border-t-[1px] flex justify-end px-5 py-3 gap-3 rounded-bl-2xl rounded-br-2xl">
        <Button className="border" onClick={() => (type ? closeModal("approveCounter") : closeModal("rejectCounter"))}>
          Cancel
        </Button>
        <Button type="submit" className="text-white bg-[#586AF5]">
          Submit
        </Button>
      </footer>
    </SmartForm>
  );
};

export default OrderApprovalForm;
