import { useState } from "react";
import { Button, Modal } from "src/components";
import downArrow from "src/assets/down-arrow.svg";
import receipt from "src/features/fields/assets/receipt.svg";
import check from "src/features/fields/assets/check.svg";
import qrcode from "src/features/fields/assets/qrcode.svg";
import cross from "src/features/fields/assets/cross.svg";
import logo from "src/features/fields/assets/logo.svg";
import InvoiceAcknowledgement from "./InvoiceAcknowledgement";

const data = {
  "Entered on": "21 June 2024",
  "Approved on": "21 June 2024, 11:30 AM",
  "E-Invoice Applicable": "Yes",
  "Entered by": "Dr. Shreevansh Kumar",
  "Approved by": "Dr. Shreevansh Kumar",
  "IR No.": "",
};

const data2 = {
  Type: "Image / Screen",
  "Acknowledged on": "21 June 2024, 11:30 AM",
  "Verified on": "21 June 2024, 11:30 AM",
  "Acknowledged by": "Dr. Shreevansh Kumar",
  "Acknowledged by.": "Dr. Shreevansh Kumar",
  "Verified by": "Dr. Shreevansh Kumar",
};

const OrderInfoInvoices = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalClose2 = () => {
    setIsModalOpen2(false);
  };

  return (
    <div className="mt-[-48px]">
      {/* Modal for QR Code */}
      <InvoiceAcknowledgement isOpen={isModalOpen2} onClose={handleModalClose2} />

      <Modal isOpen={isModalOpen} onCloseModal={handleModalClose} isHeader={false} title="View QR Code">
        <div className="flex flex-col gap-4 p-6">
          <header className="flex justify-between items-center">
            <p>View QR Code</p>
            <img src={cross} alt="cross" onClick={() => handleModalClose()} className="w-5 h-5 invert cursor-pointer" />
          </header>
          <img src={qrcode} alt="check" className="p-4 w-80 h-80" />
          <img src={logo} alt="logo" className="absolute top-[175px] left-[140px] z-10 w-24 h-24" />
          <p className="text-sm text-[#616161]">Invoice No</p>
          <input type="text" className="w-full border border-[#D0D0D0] rounded-md p-2 h-8" value={"#123-4567890"} />
          <Button onClick={() => handleModalClose()} className="px-4 py-2 w-full text-white rounded-lg bg-[#8051FF]">
            Save Changes
          </Button>
        </div>
      </Modal>
      <div className="mt-6 w-full mx-auto shadow-sm border border-[#D0D0D0] rounded-xl p-6 bg-white">
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <img src={receipt} alt="receipt" className="inline w-6 h-6 transform scale-x-[-1]" />
              <p className="font-semibold text-[1.3rem]">Invoice #123-456</p>
              <p className="font-semibold flex items-center w-fit text-[.8rem] h-6 px-2 rounded-full bg-[#E3E3E3] text-[#4A4A4A]">FUID #123456</p>
            </div>
          </div>
          <div className="flex gap-2">
            <Button className="font-normal bg-[#F1F1F1] border shadow-sm border-[#D0D0D0]" onClick={() => setIsModalOpen(true)}>
              View QR code
            </Button>
            <Button className="font-normal text-white bg-[#8051FF]" onClick={() => setIsModalOpen2(true)}>
              Download
            </Button>
            <Button className="w-10 shadow-sm h-10 bg-[#F1F1F1] border border-[#D0D0D0]" onClick={handleToggle}>
              <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
            </Button>
          </div>
        </div>{" "}
        <div className="border-t mt-6 border-[#d0d0d0]"></div>
        <p className="mt-4 text-sm font-semibold">Summary</p>
        <div className="grid grid-cols-3 gap-4 mt-4 text-sm">
          {Object.entries(data).map(([key, value], index) => (
            <div key={index} className="flex gap-10 items-center">
              <span className="font-normal text-[#616161]">{key} : </span>
              <span className="text-[#303030]">{value}</span>
              {/* Conditional rendering for buttons */}
              {key === "IR No." && <img src={check} alt="check" className="w-6 h-6" />}
              {key === "E-Invoice Applicable" && <Button className="font-normal h-7 bg-[#F1F1F1] border shadow-sm border-[#D0D0D0]">Edit</Button>}
              {key === "IR No." && <Button className="font-normal h-7 bg-[#F1F1F1] border shadow-sm border-[#D0D0D0]">Copy</Button>}
            </div>
          ))}
        </div>
        <div className="bg-[#F3F3F3] mt-4 p-4 rounded-lg">
          <div className="flex justify-between items-center">
            <p className="text-sm font-semibold">Acknowledgement</p>

            <Button className="font-normal h-8 bg-[#F1F1F1] border shadow-sm border-[#D0D0D0]">Open full view</Button>
          </div>
          <div className="border-t mt-4 border-[#d0d0d0]"></div>
          <div className="grid grid-cols-3 gap-4 mt-4 text-sm">
            {Object.entries(data2).map(([key, value], index) => (
              <div key={index} className="flex gap-10 items-center">
                <span className="font-normal text-[#616161]">{key} : </span>
                <span className="text-[#303030]">{value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-6 w-full mx-auto shadow-sm border border-[#D0D0D0] rounded-xl p-6 bg-white">
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <img src={receipt} alt="receipt" className="inline w-6 h-6 transform scale-x-[-1]" />
              <p className="font-semibold text-[1.3rem]">Invoice #123-456</p>
            </div>
          </div>
          <div className="flex gap-2">
            <Button className="font-normal bg-[#F1F1F1] border shadow-sm border-[#D0D0D0]" onClick={() => setIsModalOpen(true)}>
              View QR code
            </Button>
            <Button className="font-normal text-white bg-[#8051FF]">Download</Button>
            <Button className="w-10 shadow-sm h-10 bg-[#F1F1F1] border border-[#D0D0D0]" onClick={handleToggle}>
              <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
            </Button>
          </div>
        </div>{" "}
      </div>
      <div className="mt-6 w-full mx-auto shadow-sm border border-[#D0D0D0] rounded-xl p-6 bg-white">
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <img src={receipt} alt="receipt" className="inline w-6 h-6 transform scale-x-[-1]" />
              <p className="font-semibold text-[1.3rem]">Invoice #123-456</p>
            </div>
          </div>
          <div className="flex gap-2">
            <Button className="font-normal bg-[#F1F1F1] border shadow-sm border-[#D0D0D0]" onClick={() => setIsModalOpen(true)}>
              View QR code
            </Button>
            <Button className="font-normal text-white bg-[#8051FF]">Download</Button>
            <Button className="w-10 shadow-sm h-10 bg-[#F1F1F1] border border-[#D0D0D0]" onClick={handleToggle}>
              <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
            </Button>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default OrderInfoInvoices;
