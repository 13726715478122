import { Button } from "src/components";
import crossIcon from "../../assets/cross.svg";
import { putInvoiceOnHold } from "src/services/invoice-api";
import { useToast } from "src/hooks/useToast";

const InvoiceOnHold = ({ onCloseModal, invoiceData }: { onCloseModal: () => void; invoiceData: any }) => {
  const { toast } = useToast();

  const handlePutOnHold = async () => {
    const payload = {
      data: {
        stockist_invoice_attachment_id: invoiceData?.stockist_invoice_attachment_id,
      },
      primary_key: invoiceData?.stockist_invoice_attachment_id,
      event_type: "STOCKIST_ATTACHMENT_HOLD",
    };
    try {
      const res = await putInvoiceOnHold(payload);
      console.log(res);
      toast({ description: "Invoice on hold successfully", variant: "default" });
      onCloseModal();
    } catch (error) {
      console.log(error);
    } finally {
      onCloseModal();
    }
  };

  return (
    <div className="flex flex-col gap-y-2 w-[500px] mx-auto bg-white p-4 border border-gray-300 shadow-lg rounded-lg">
      <div className="flex justify-between items-center">
        <p className="text-normal py-1 font-semibold text-[#1B1A1F]">Put invoice on hold</p>
        <Button className="bg-transparent -mr-3" onClick={onCloseModal}>
          <img src={crossIcon} alt="cross" className="w-5 h-5 font-bold invert cursor-pointer" />
        </Button>
      </div>
      <p className="text-sm mb-2 font-normal text-[#666666]">Are you sure you want to put this invoice on hold?</p>

      <div className="flex justify-end gap-x-2">
        <Button className="text-white bg-[#8051FF] mt-4" onClick={handlePutOnHold}>
          Proceed
        </Button>
        <Button className="bg-[#f5f5f5]  mt-4" onClick={onCloseModal}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default InvoiceOnHold;
