import { Tab, Transition } from "@headlessui/react";
import InvoiceCard from "../invoice-master/invoice-card";
import classNames from "classnames";
import AllInvoices from "./invoice-all.component";
import { Fragment, useEffect, useState } from "react";
import {
  fetchEscalatedInvoicesData,
  fetchInvoicesData,
  fetchInvoiceStatus,
  fetchPriorityInvoicesData,
  getAssigneeList,
} from "src/services/invoice-api";
import { invoiceActions } from "src/context/invoice-slice";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "src/hooks/useToast";
import filterIcon from "src/assets/filterIcon.svg";
import { Button, Input, Spinner } from "src/components";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select.component";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { getAllCompanyDetails } from "src/services/company-api";
import { RootState } from "src/context/store";
import InvoiceFiltersComponent from "../../features/fields/container/InvoiceFilters";
import { queryDivisionDetails } from "../../services/division-api";
import { getCityDetails } from "src/services/region-api";
import { getDistrictDetails } from "src/services/region-api";
import { getStateDetails } from "src/services/region-api";
import InvoicePriorityTab from "./InvoiceProrityTab";
import InvoiceEscalatedTab from "./InvoiceEscalatedTab";

const searchOptions = [
  { label: "FUID No", value: "fuid_no" },
  { label: "Invoice Number", value: "invoice_number" },
  { label: "Order No", value: "order_no" },
  { label: "Counter Code", value: "counter_code" },
  { label: "Stockist Medvol Code", value: "stockist_medvol_code" },
  { label: "Company Stockist Code", value: "company_stockist_code" },
];

const InvoiceEntry = () => {
  const [selectedTab, setSelectedTab] = useState(0); // Track the active tab
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const dispatch = useDispatch();
  const { InvoiceFiltersData, InvoiceFilters } = useSelector((state: RootState) => state.invoice);
  const { toast } = useToast();
  const [searchCategory, setSearchCategory] = useState("fuid_no");
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [tabsDataCount, setTabsDataCount] = useState<{ name: string; count: number }[]>([
    { name: "All Invoices", count: 0 },
    { name: "Priority Tasks", count: 0 },
    { name: "Escalated", count: 0 },
  ]);

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const ref = useOutsideClick(() => {
    setIsFilterOpen(false);
  });

  const fetchTabData = async (page: number) => {
    setIsLoading(true);
    try {
      const searchParams = {
        company_code: InvoiceFilters?.company?.[0]?.code,
        division_code: InvoiceFilters?.division?.[0]?.company_divisioncode,
        startDate: InvoiceFilters?.dateRange?.[0],
        endDate: InvoiceFilters?.dateRange?.[1],
        assignee_id: InvoiceFilters?.assignee?.[0]?.invoice_assignment_id,
        city: InvoiceFilters?.city?.[0]?.city_name?.split("|")?.[0]?.trim(),
        district: InvoiceFilters?.district?.[0]?.district_name,
        state: InvoiceFilters?.state?.[0]?.state_name,
        fuid_status_id: InvoiceFilters?.status?.[0]?.fuid_status_id || 1,
        page_size: 10,
        page_number: page,
      };
      if (searchValue && searchCategory) {
        searchParams[searchCategory as keyof typeof searchParams] = searchValue;
      }

      fetchInvoicesData(searchParams).then((res) => {
        dispatch(invoiceActions.setInvoiceData(res?.data?.data?.invoiceData || []));
        setTabsDataCount((prev) => prev.map((tab) => (tab.name === "All Invoices" ? { ...tab, count: res?.data?.data?.total || 0 } : tab)));
      });

      fetchPriorityInvoicesData(searchParams).then((res) => {
        dispatch(invoiceActions.setInvoicePriorityData(res?.data?.data?.invoiceData || []));
        setTabsDataCount((prev) => prev.map((tab) => (tab.name === "Priority Tasks" ? { ...tab, count: res?.data?.data?.total || 0 } : tab)));
      });

      fetchEscalatedInvoicesData(searchParams).then((res) => {
        dispatch(invoiceActions.setInvoiceEscalatedData(res?.data?.data?.invoiceData || []));
        setTabsDataCount((prev) => prev.map((tab) => (tab.name === "Escalated" ? { ...tab, count: res?.data?.data?.total || 0 } : tab)));
      });

      toast({ description: "Invoice data fetched successfully", variant: "default" });
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching invoices data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCompanyDetails()
      .then((res) => dispatch(invoiceActions.setInvoiceFiltersData({ key: "company", value: res?.data })))
      .catch((err) => console.log(err));
  }, [dispatch]);

  useEffect(() => {
    if (InvoiceFilters?.company?.length) {
      queryDivisionDetails(`/details/?fields=division_name&company_code=${InvoiceFilters?.company?.[0]?.code}`)
        .then((res) => dispatch(invoiceActions.setInvoiceFiltersData({ key: "division", value: res?.data })))
        .catch((err) => console.log(err));

      // fetchStockistFilterData(InvoiceFilters?.company?.[0]?.code)
      //   .then((res) => dispatch(invoiceActions.setInvoiceFiltersData({ key: "stockist", value: res?.data?.data })))
      //   .catch((err) => console.log(err));
    } else {
      dispatch(invoiceActions.setInvoiceFiltersData({ key: "division", value: [] }));
    }
  }, [InvoiceFilters?.company]);

  useEffect(() => {
    fetchTabData(page);
  }, [
    InvoiceFilters?.company,
    InvoiceFilters?.division,
    InvoiceFilters?.status,
    InvoiceFilters?.assignee,
    InvoiceFilters?.city,
    InvoiceFilters?.district,
    InvoiceFilters?.state,
    InvoiceFilters?.fuidNo,
  ]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchTabData(page);
    }, 300);

    return () => clearTimeout(timerId);
  }, [searchValue]);

  useEffect(() => {
    if (InvoiceFiltersData?.status?.length === 0) {
      fetchInvoiceStatus()
        .then((res) => dispatch(invoiceActions.setInvoiceFiltersData({ key: "status", value: res?.data })))
        .catch((err) => console.log(err));
    }
    if (InvoiceFiltersData?.assignee?.length === 0) {
      getAssigneeList()
        .then((res) => dispatch(invoiceActions.setInvoiceFiltersData({ key: "assignee", value: res?.data?.data })))
        .catch((err) => console.log(err));
    }
    if (InvoiceFiltersData?.city?.length === 0) {
      getCityDetails("")
        .then((res) => {
          dispatch(invoiceActions.setInvoiceFiltersData({ key: "city", value: res?.data?.data }));
        })
        .catch((err) => console.log(err));
    }
    if (InvoiceFiltersData?.district?.length === 0) {
      getDistrictDetails("")
        .then((res) => {
          dispatch(invoiceActions.setInvoiceFiltersData({ key: "district", value: res?.data?.data }));
        })
        .catch((err) => console.log(err));
    }
    if (InvoiceFiltersData?.state?.length === 0) {
      getStateDetails("")
        .then((res) => {
          dispatch(invoiceActions.setInvoiceFiltersData({ key: "state", value: res?.data?.data }));
        })
        .catch((err) => console.log(err));
    }
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[50vh]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full">
      <Transition
        as={Fragment}
        show={isFilterOpen}
        enter="transition transition ease-in-out duration-200 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition  ease-in-out duration-200 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed right-0 inset-0 top-0 z-[500]">
          <InvoiceFiltersComponent
            companyDetails={InvoiceFiltersData?.company || []}
            divisionDetails={InvoiceFiltersData?.division || []}
            stockistDetails={InvoiceFiltersData?.stockist || []}
            statusDetails={InvoiceFiltersData?.status || []}
            assigneeDetails={InvoiceFiltersData?.assignee || []}
            cityDetails={InvoiceFiltersData?.city || []}
            districtDetails={InvoiceFiltersData?.district || []}
            stateDetails={InvoiceFiltersData?.state || []}
            ref={ref}
            onClose={() => setIsFilterOpen(false)}
          />
        </div>
      </Transition>
      <div className="mx-auto">
        <section className="flex flex-wrap justify-between mx-auto mt-4">
          <InvoiceCard title={tabsDataCount[0]?.count.toString()} subtitle="New invoices this month" heading="Total invoices" />
          <InvoiceCard title={tabsDataCount[2]?.count.toString()} subtitle="Invoices this month" heading="Invoices escalated" />
          <InvoiceCard title={tabsDataCount[1]?.count.toString()} subtitle="New invoices this month" heading="MTD" />
          <InvoiceCard title={tabsDataCount[0]?.count.toString()} subtitle="New invoices this month" heading="Total invoices" />
        </section>
        <div className="px-2 py-10 w-full sm:px-0">
          <Tab.Group
            selectedIndex={selectedTab}
            onChange={(index) => setSelectedTab(index)} // Update the selected tab
          >
            <div className="flex justify-between">
              <Tab.List className="flex p-1 space-x-1 rounded-lg bg-[#e3e3e3] w-[500px] h-[38px] items-center">
                {tabsDataCount.map((category, index) => (
                  <Tab
                    key={index}
                    className={({ selected }) =>
                      classNames(
                        "w-full rounded-md py-1.5 text-sm font-normal leading-5",
                        "focus:outline-none",
                        selected ? "bg-white" : "hover:bg-white/[0.12] hover:text-black"
                      )
                    }
                  >
                    {category.name}
                    {category.count !== null && (
                      <span className="ml-4 bg-[#8051FF] text-white text-[12px] px-2 py-1 rounded-lg">{category.count}</span>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <div className="flex gap-2 h-full">
                <div className="flex items-center w-[400px]">
                  {/* Company Dropdown */}
                  <Select value={searchCategory} onValueChange={setSearchCategory}>
                    <SelectTrigger className="w-[200px]">
                      <SelectValue placeholder="Search By" />
                    </SelectTrigger>
                    <SelectContent className="bg-white">
                      {searchOptions.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {/* <Input placeholder="Search" className="w-full py-2 bg-[#F1F1F1] border-none" /> */}
                  <Input
                    placeholder="Search"
                    debounceTime={500}
                    value={searchValue}
                    onChange={handleSearchChange}
                    className="w-full px-2 py-1.5 text-gray-700 bg-[#F1F1F1] border-none rounded-l-none rounded-r-md focus:outline-none focus:ring-0"
                  />
                </div>
                <div className="flex items-center gap-2 h-full relative">
                  {/* Blue dot indicator */}
                  {Object.values(InvoiceFilters).some((value) => value && value.length > 0) && (
                    <span className="absolute top-[-10px] flex items-center justify-center text-white text-xs right-[-10px] w-6 h-6 bg-red-500 rounded-full">
                      {Object.values(InvoiceFilters).filter((value) => value && value.length > 0).length}
                    </span>
                  )}

                  <Button className="bg-[#F1F1F1]" onClick={() => setIsFilterOpen(true)}>
                    <img src={filterIcon} alt="filter-icon" />
                  </Button>
                </div>
              </div>
            </div>
            <Tab.Panels className="mt-2">
              <Tab.Panel>
                <AllInvoices fetchTabData={fetchTabData} tabIndex={0} page={page} setPage={setPage} />
              </Tab.Panel>
              <Tab.Panel>
                <InvoicePriorityTab fetchTabData={fetchTabData} page={page} setPage={setPage} />
              </Tab.Panel>
              <Tab.Panel>
                <InvoiceEscalatedTab fetchTabData={fetchTabData} page={page} setPage={setPage} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};

export default InvoiceEntry;
