// Importing necessary libraries and services
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { Badge, Button } from "src/components";
import { getPharmacyDetailsByNameAndId } from "src/services/counter-api";
import { emailStatusHandler, mciNumberUniqueHandler, mobileStatusHandler } from "src/utils/utils";
import * as yup from "yup";

// Helper function for creating columns in tables
const columnHelper = createColumnHelper();

export const AvailableGST = [
  { name: "Yes", id: "Yes" },
  { name: "No", id: "No" },
];
export const GSTStatus = [
  { name: "Active", id: "Active" },
  { name: "Inactive", id: "Inactive" },
  { name: "Cancelled", id: "Cancelled" },
  { name: "Cancelled suo-moto", id: "Cancelled suo-moto" },
  { name: "Suspended", id: "Suspended" },
  { name: "Not Migrated", id: "Not Migrated" },
];

// Interface for the form values of the counter
export interface CounterFormValues {
  // Type of the form field
  type: string;
  // ID of the form field
  id: string | number;
  // Key of the form field
  keyofForm: string;
  // Label of the form field
  label: string;
  // Function to fetch async list data
  asyncListFunction?: any;
  // Size of the form field
  size?: "full";
  // List of suggestions for autocomplete
  suggestionList?: any[] | null;
  // Unique key for the suggestion list
  uniqueKey?: string;
  // Display key for the suggestion list
  displayKey?: string;
  // If the form field is disabled
  disabled?: boolean;
  additionalDisplayKey?: string;
  additionalDisplayKeySearch?: boolean;
}

export const deleteSchema = yup.object().shape({
  reference_number: yup.string().required("Reference number is required"),
});

// Schema for form validation using Yup
export const schema = yup.object().shape({
  companyName: yup.object().required(),
  counter_name: yup.string().required().max(300, "Counter name should not be more than 300 characters"),
  mci_register_number: yup.string().max(100).required().test("mciNumber", "Invalid mci number", mciNumberUniqueHandler),
  cityList: yup.object().required(),
  email: yup
    .string()
    .email()
    .notRequired()
    .test("managerEmailId", "Invalid email Id", (val, context) => {
      if (typeof val === "string") emailStatusHandler(val, context);
      return true;
    }),
  address: yup.string().required(),
  pincode: yup
    .number()
    .positive()
    .integer()
    .notRequired()
    .transform((value) => {
      return isNaN(value) ? undefined : value;
    })
    .test("len", "Pincode should not be more than 10 characters", (val: any) => String(val).length <= 10),
  mobile: yup
    .number()
    .positive()
    .required()
    .transform((value) => {
      return isNaN(value) ? undefined : value;
    })

    .test("len", "Mobile number should 10 characters", (val: any) => String(val).length === 10)
    .test("mobile-validation", "Invalid mobile number", mobileStatusHandler),
  // .test("mobile_async_validation", "Mobile Validation Error", function (value) { // Use function
  //   return checkMobileStatus(value)
  //     .then((res) => {
  //       const message = res;
  //       return this.createError({ message: 'message' });
  //       // return Promise.resolve(this.createError({ message: message })); // This also works
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // })
  clinic_name: yup.string().required().max(300),
  gst_available: yup.object().required("Available Gst is a required field"),
  gst_number: yup
    .string()
    .nullable()
    .test("gst_available", "GST number is required when GST is available.", function (value: any) {
      const { gst_available } = this.parent;
      if (gst_available && gst_available.id === "Yes" && !/^[0-9][a-zA-Z0-9]{14}$/.test(value)) {
        return this.createError({ message: "Invalid format. The value must start with a number and be 15 characters long." });
      } else {
        return true;
      }
    }),
  gst_status: yup
    .object()
    .nullable()
    .test("gst_available", "GST status is required when GST is available.", function (value: any) {
      const { gst_available } = this.parent;
      if (gst_available && gst_available.id === "Yes" && !value) {
        return false;
      } else {
        return true;
      }
    }),
  // gst: yup.string().max(15),
  speciality: yup.object().required(),
  pan_number: yup
    .string()
    .notRequired()
    .nullable()
    .test(
      "pan-number-format",
      "Invalid PAN. It must be 10 characters in sequence of: 5 uppercase letters, 4 digits, and 1 uppercase letter.",
      (value) => {
        return !value || /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value);
      }
    ),
});

export const editCounterSchema = yup.object().shape({
  counter_name: yup.string().required().max(300, "Counter name should not be more than 300 characters"),
  mci_register_number: yup.string().max(100).required().test("mciNumber", "Invalid mci number", mciNumberUniqueHandler),
  email: yup
    .string()
    .email()
    .notRequired()
    .test("managerEmailId", "Invalid email Id", (val, context) => {
      if (typeof val === "string") emailStatusHandler(val, context);
      return true;
    }),
  gst_number: yup
    .string()
    .notRequired()
    .nullable()
    .test("gst-format", "Invalid format. The value must start with a number and be 15 characters long or be empty.", (value) => {
      return !value || /^[0-9][a-zA-Z0-9]{14}$/.test(value);
    }),
});

// Schema for form validation using in a assign stockist to a counter
export const AddStockistSchema = yup.object().shape({
  location_id: yup.object().required(),
  counter_company_id: yup.object().required(),
  cluster_code: yup.object().required(),
  stockist_hdr_id: yup.object().required(),
  remarks: yup.string().required(),
});

export const ValueCapSchema = yup.object().shape({
  company: yup.lazy((value) => (typeof value === "object" ? yup.object() : yup.string()).required("Company name is required")),
  division: yup.lazy((value) => (typeof value === "object" ? yup.object() : yup.string()).required("Division name is required")),
  order_cap_value: yup.number().required("Location name is required"),
  reference_number: yup.string().required().max(100, "Reference number should not be more than 100 characters"),
  expiry_date: yup
    .date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" || originalValue === undefined ? null : value;
    })
    .test("valid-date-format", "Invalid date format", (value) => {
      if (!value) return true;
      const date = new Date(value);
      const [year, month, day] = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
      return year.toString().length <= 4 && month <= 12 && day <= 31;
    }),
});

// Form fields for the counter form
export const counterForm: CounterFormValues[] = [
  {
    // Field for CREQ Code
    label: "CREQ Code",
    type: "input",
    id: 1,
    keyofForm: "name",
  },
  {
    // Field for Counter Type with autocomplete
    label: "Counter Type",
    type: "autocomplete",
    id: 2,
    keyofForm: "counterType",
    // Suggestion list for Counter Type
    suggestionList: [
      { id: 1, label: "DOCTOR" },
      { id: 2, label: "PHARM" },
      { id: 3, label: "HOSP" },
    ],
    uniqueKey: "id",
    displayKey: "label",
  },
  {
    // Field for Doctor Full Name
    label: "Doctor Full Name",
    type: "input",
    id: 3,
    keyofForm: "counter_name",
  },
  {
    // Field for MCI Register Number
    label: "MCI Register Number",
    type: "input",
    id: 4,
    keyofForm: "mciRegisterNumber",
  },
  {
    // Field for Mobile Number
    label: "Mobile Number",
    type: "input",
    id: 5,
    keyofForm: "mobileNumber",
  },
  {
    // Field for Email Address
    label: "Email Address",
    type: "input",
    id: 6,
    keyofForm: "emailAddress",
  },
  {
    // Field for Clinic Name
    label: "Clinic Name",
    type: "input",
    id: 7,
    keyofForm: "clinicName",
  },
  {
    // Field for Address
    label: "Address",
    type: "input",
    id: 8,
    keyofForm: "address",
  },
  {
    // Field for City
    label: "City",
    type: "input",
    id: 9,
    keyofForm: "city_code",
  },
  {
    // Field for Pincode
    label: "Pincode",
    type: "input",
    id: 10,
    keyofForm: "pincode",
  },
  {
    // Field for Speciality
    label: "Speciality",
    type: "input",
    id: 11,
    keyofForm: "speciality",
  },
  {
    // Field for Company Name with autocomplete
    label: "Company Name",
    type: "autocomplete",
    id: 12,
    keyofForm: "companyName",
  },
];

// Schema for normal doctor form validation
export const normalDoctorSchema = yup.object().shape({
  reference_number: yup.string().notRequired().nullable(),

  // gst_available: yup.object().notRequired().nullable(),
  // gst_status: yup.object().notRequired().nullable(),
  // gst_number: yup
  //   .string()
  //   .notRequired()
  //   .nullable()
  //   .matches(/^[0-9][a-zA-Z0-9]{14}$/, "Invalid format. The value must start with a number and be 15 characters long."),
});

export const draphDoctorSchema = yup.object().shape({
  reference_number: yup.string().notRequired().nullable(),
  pharmacist_id: yup.object().required().nullable(),
  // gst_available: yup.object().notRequired().nullable(),
  // gst_status: yup.object().notRequired().nullable(),
  // gst_number: yup
  //   .string()
  //   .notRequired()
  //   .nullable()
  //   .matches(/^[0-9][a-zA-Z0-9]{14}$/, "Invalid format. The value must start with a number and be 15 characters long."),
});

// Form fields for the normal doctor form
export const normalDoctorForm = [
  {
    // Field for Reference Number
    label: "Reference Number",
    type: "input",
    id: 1,
    keyofForm: "reference_number",
    size: "full",
  },
];

// Form fields for the Add/Update Location form

// Schema for location form validation
export const LocationSchema = yup.object().shape({
  name: yup.object().required("Company Name is required"),
  mobile: yup
    .number()
    .positive()
    .required()
    .transform((value) => {
      return isNaN(value) ? undefined : value;
    })
    .test("len", "Mobile number should 10 characters", (val: any) => String(val).length === 10)
    .test("mobile-validation", "Invalid mobile number", mobileStatusHandler),
});

// Form fields for the Create Counter form
export const CreateCounterForm: CounterFormValues[] = [
  {
    // Field for Reference Number
    label: "Reference Number",
    type: "input",
    id: 1,
    keyofForm: "reference_number",
    size: "full",
  },
  {
    // Field for Pharmacy Code with autocomplete and async list fetching
    label: "Pharmacy Code",
    type: "autocomplete",
    id: 2,
    asyncListFunction: getPharmacyDetailsByNameAndId,
    displayKey: "counter_name",
    additionalDisplayKey: "counter_code",
    uniqueKey: "counter_id",
    keyofForm: "pharmacist_id",
    size: "full",
    additionalDisplayKeySearch: true,
  },
];

// Schema for hospital form validation
export const hospitalPharmacySchema = yup.object().shape({
  companyName: yup.object().required(),
  counter_name: yup.string().required().max(300, "Counter name should not be more than 300 characters"),
  cityList: yup.object().required(),
  address: yup.string().required(),
  gst_number: yup
    .string()
    .notRequired()
    .nullable()
    .test("gst-format", "Invalid format. The value must start with a number and be 15 characters long or be empty.", (value) => {
      return !value || /^[0-9][a-zA-Z0-9]{14}$/.test(value);
    }),
  pincode: yup
    .number()
    .positive()
    .integer()
    .notRequired()
    .transform((value) => {
      return isNaN(value) ? undefined : value;
    }),
  mobile: yup
    .number()
    .positive()
    .required()
    .transform((value) => {
      return isNaN(value) ? undefined : value;
    })
    .test("len", "Mobile number should 10 characters", (val: any) => String(val).length === 10)
    .test("mobile-validation", "Invalid mobile number", mobileStatusHandler),

  manager_name: yup.string().max(100).notRequired(),
  drug_license_number: yup.string().max(150).required(),
  hospital_name: yup.object().required(),
});

// Schema for hospital form validation
export const hospitalSchema = yup.object().shape({
  companyName: yup.object().required(),
  hospital_id: yup.object().required(),
  counter_name: yup.string().required().max(300, "Counter name should not be more than 300 characters"),
  reference_number: yup.string().required("Reference Number is required"),
  cityList: yup.object().required(),
  address: yup.string().required(),
  email: yup
    .string()
    .email()
    .notRequired()
    .test("managerEmailId", "Invalid email Id", (val, context) => {
      if (typeof val === "string") emailStatusHandler(val, context);
      return true;
    }),
  gst_available: yup.object().required("Available Gst is a required field"),
  gst_number: yup
    .string()
    .nullable()
    .test("gst_available", "GST number is required when GST is available.", function (value: any) {
      const { gst_available } = this.parent;
      if (gst_available && gst_available.id === "Yes" && !/^[0-9][a-zA-Z0-9]{14}$/.test(value)) {
        this.createError({ message: "Invalid format. The value must start with a number and be 15 characters long." });
        return false;
      } else {
        return true;
      }
    }),
  gst_status: yup
    .object()
    .nullable()
    .test("gst_available", "GST status is required when GST is available.", function (value: any) {
      const { gst_available } = this.parent;
      if (gst_available && gst_available.id === "Yes" && !value) {
        return false;
      } else {
        return true;
      }
    }),
  pincode: yup
    .number()
    .positive()
    .integer()
    .notRequired()
    .transform((value) => {
      return isNaN(value) ? undefined : value;
    }),
  mobile: yup
    .number()
    .positive()
    .required()
    .transform((value) => {
      return isNaN(value) ? undefined : value;
    })
    .test("len", "Mobile number should 10 characters", (val: any) => String(val).length === 10)
    .test("mobile-validation", "Invalid mobile number", mobileStatusHandler),
  manager_name: yup.string().max(100).notRequired(),
  drug_license_number: yup.string().max(150).required(),
  pan_number: yup
    .string()
    .notRequired()
    .nullable()
    .test(
      "pan-number-format",
      "Invalid PAN. It must be 10 characters in sequence of: 5 uppercase letters, 4 digits, and 1 uppercase letter.",
      (value) => {
        return !value || /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value);
      }
    ),
});

export const editHospitalSchema = yup.object().shape({
  counter_name: yup.string().required().max(300, "Counter name should not be more than 300 characters"),
  hospital_id: yup.object().required(),
  reference_number: yup.string().required("Reference Number is required"),
  gst_available: yup.object().required("Available Gst is a required field"),
  gst_number: yup
    .string()
    .nullable()
    .test("gst_available", "GST number is required when GST is available.", function (value: any) {
      const { gst_available } = this.parent;
      if (gst_available && gst_available.id === "Yes" && !/^[0-9][a-zA-Z0-9]{14}$/.test(value)) {
        this.createError({ message: "Invalid format. The value must start with a number and be 15 characters long." });
        return false;
      } else {
        return true;
      }
    }),
  gst_status: yup
    .object()
    .nullable()
    .test("gst_available", "GST status is required when GST is available.", function (value: any) {
      const { gst_available } = this.parent;
      if (gst_available && gst_available.id === "Yes" && !value) {
        return false;
      } else {
        return true;
      }
    }),
  manager_name: yup.string().max(100).notRequired(),
  drug_license_number: yup.string().max(150).required(),
  email: yup
    .string()
    .email()
    .notRequired()
    .test("managerEmailId", "Invalid email Id", (val, context) => {
      if (typeof val === "string") emailStatusHandler(val, context);
      return true;
    }),
  pan_number: yup
    .string()
    .notRequired()
    .nullable()
    .test(
      "pan-number-format",
      "Invalid PAN. It must be 10 characters in sequence of: 5 uppercase letters, 4 digits, and 1 uppercase letter.",
      (value) => {
        return !value || /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value);
      }
    ),
});

// Schema for pharmacy form validation
export const pharmacySchema = yup.object().shape({
  companyName: yup.object().required(),
  reference_number: yup.string().required("Reference Number is required"),
  counter_name: yup.string().required().max(300, "Counter name should not be more than 300 characters"),
  cityList: yup.object().required(),
  address: yup.string().required(),
  gst_available: yup.object().notRequired(),
  gst_number: yup.string().test("gst_available", "GST number is required when GST is available.", function (value: any) {
    console.log("this", this);
    const { gst_available } = this.parent;
    if (gst_available && gst_available.id === "Yes" && !/^[0-9][a-zA-Z0-9]{14}$/.test(value)) {
      this.createError({ message: "Invalid format. The value must start with a number and be 15 characters long." });
      return false;
    } else {
      return true;
    }
  }),
  pincode: yup
    .number()
    .positive()
    .integer()
    .notRequired()
    .transform((value) => {
      return isNaN(value) ? undefined : value;
    }),
  mobile: yup
    .number()
    .positive()
    .required()
    .transform((value) => {
      return isNaN(value) ? undefined : value;
    })
    .test("mobile-validation", "Invalid mobile number", mobileStatusHandler)
    .test("len", "Mobile number should 10 characters", (val: any) => String(val).length === 10),
  manager_name: yup.string().max(100).notRequired(),
  drug_license_number: yup.string().max(150).required(),
});

// export const addProductSchema = yup.object().shape({
//   company_productcode: yup.object().required(),
//   division_details: yup.object().required(),
//   companyProducts: yup.object().required(),
//   priceldentifier: yup.object().required(),
//   periodtype: yup.object().required(),
//   periodlimit: yup.number().required().moreThan(yup.ref("max_order_qty"), "Period limit must be greater than max order quantity"),
//   reference_number: yup.string().required(),
//   int_qty: yup.number().notRequired(),
// });

export const addProductSchema = yup.object().shape({
  division_details: yup.object().shape({
    division_name: yup.string().required("Select Division is required"),
  }),
  companyProducts: yup.object().shape({
    company_productcode: yup.string().required("Select Item is required"),
    name: yup.string().required(),
  }),
  priceldentifier: yup.object().shape({
    id: yup.string().required("Select Discount is required"),
    description: yup.string().required(),
  }),
  periodtype: yup.lazy((value) => (typeof value === "object" ? yup.object().required() : yup.string().required())),
  periodlimit: yup
    .number()
    .required("Period limit is required")
    .test("period-limit-greater-than-max-order-qty", "Period limit should be greater than or equal to max order quantity", function (value) {
      const { counter_product_slab } = this.parent;
      if (!counter_product_slab || counter_product_slab.length === 0) return true;
      const maxOrderQty = Math.max(...counter_product_slab.map((slab: any) => slab.max_order_qty));
      return value >= maxOrderQty;
    }),
  int_qty: yup
    .string()
    .notRequired()
    .test("int_qty", "Integral Quantity should be less than min order quantity", function (value: any) {
      const { counter_product_slab } = this.parent;
      if (!counter_product_slab || counter_product_slab.length === 0) return true;
      const minOrderQty = Math.min(...counter_product_slab.map((slab: any) => slab.min_order_qty));
      if (parseInt(value) <= parseInt(minOrderQty as any)) return true;
      else return false;
    }),
  reference_number: yup.string().required("Reference Number is required"),
  counter_product_slab: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          min_order_qty: yup
            .number()
            .transform((value) => {
              return isNaN(value) ? undefined : value;
            })
            .required("Min Order Qty is required"),
          max_order_qty: yup
            .number()
            .required("Max Order Qty is required")
            .transform((value) => {
              return isNaN(value) ? undefined : value;
            })
            .test("is-greater-than-min", "Max Order Qty must be greater than Min Order Qty", function (value) {
              return value > this.parent.min_order_qty;
            }),
          fixedprice_cp: yup
            .number()
            .nullable()
            .notRequired()
            .transform((value) => {
              return isNaN(value) ? undefined : value;
            }),

          quantity_cp: yup
            .number()
            .nullable()
            .notRequired()
            .transform((value) => {
              return isNaN(value) ? undefined : value;
            }),
          schemeqty_cp: yup
            .number()
            .nullable()
            .notRequired()
            .transform((value) => {
              return isNaN(value) ? undefined : value;
            }),
          discountonptr_cp: yup
            .number()
            .nullable()
            .notRequired()
            .transform((value) => {
              return isNaN(value) ? undefined : value;
            }),
        })
        .required()
    )
    .min(1, "At least one slab is required")
    .required(),
});

export const addDelegateSchema = yup.object().shape({
  counter_location_id: yup.object().required("Location Name is required"),
  delegate_name: yup.string().required("Full name is required"),
  mobile: yup
    .number()
    .required("Phone number is required")
    .test("len", "Mobile number should 10 characters", (val: any) => String(val).length === 10)
    .test("mobile-validation", "Invalid mobile number", async (val: any, context: any) => await mobileStatusHandler(val, context)),
});

export const removeStockistSchema = yup.object().shape({
  reference_number: yup.string().required("Reference number is required"),
});

// Form fields for the Remove Stockist form
export const removeStockistMetaData = [
  {
    // Field for Reference Number
    label: "Reference Number",
    type: "input",
    id: 1,
    keyofForm: "reference_number",
    size: "full",
  },
];

// Metadata for the Value Cap Current table
export const valueCapCurrentTableMD = [
  // Column for Current Value Cap
  columnHelper.accessor("order_cap_value", {
    cell: (info) => new Intl.NumberFormat("en-US", { maximumSignificantDigits: 3 }).format(info.getValue()),
    header: () => "Current Value Cap",
  }),

  // Column for Assigned on
  columnHelper.accessor("created_date", {
    cell: (info) => moment(info.getValue()).format("DD MMM, YYYY"),
    header: () => "Assigned on",
  }),

  // Column for Time
  columnHelper.accessor("time", {
    cell: (info: any) => moment(info.row.original?.created_date).format("hh:mm A"),
    header: () => "Time",
    enableSorting: false,
  }),

  // Column for Expiry Date
  columnHelper.accessor("expiry_date", {
    cell: (info) => moment(info.getValue()).format("DD MMM, YYYY"),
    header: () => "Expiry Date",
  }),

  // Column for Remarks
  columnHelper.accessor("remarks", {
    cell: (info) => info.getValue(),
    header: () => "Remarks",
  }),

  // column for status
  columnHelper.accessor("status", {
    cell: (info) => {
      let variant;
      switch (info.getValue()) {
        case "active":
          variant = "success";
          break;
        case "inactive":
          variant = "error";
          break;
        default:
          variant = "default";
      }
      return <Badge variant={variant || "default"}>{info.getValue()}</Badge>;
    },
    header: () => "Status",
  }),
];

// Metadata for the Blocked/Revoked table
export const blockedRevokedTableMD = [
  // Column for Block Type
  columnHelper.accessor("block_type", {
    cell: (info) => info.getValue(),
    header: () => "Block Type",
  }),
  // Column for Block Category
  columnHelper.accessor("blocked_remarks_desc", {
    cell: (info) => info.getValue(),
    header: () => "Block Category",
  }),
  // Column for Remarks
  columnHelper.accessor("remarks", {
    cell: (info) => info.getValue(),
    header: () => "Remarks",
    enableSorting: false,
  }),
  // Column for Internal Remarks
  columnHelper.accessor("internal_remarks", {
    cell: (info) => info.getValue(),
    header: () => "Internal Remarks",
    enableSorting: false,
  }),
  // Column for Blocked By
  columnHelper.accessor("blocked_by", {
    cell: (info) => info.getValue(),
    header: () => "Blocked By",
  }),
  // Column for Date & Time
  columnHelper.accessor("date_time", {
    cell: (info) => info.getValue(),
    header: () => "Date & Time",
  }),
  // Column for Revoked Block with a button
  columnHelper.accessor("revoked_block", {
    cell: () => <Button className="border">Revoke</Button>,
    header: () => "Revoked Block",
  }),
];

// Dummy data for the Blocked/Revoked table
export const blockedRevokedTableD = [
  {
    block_type: "Type A",
    block_category: "Category 1",
    remarks: "Remark 1",
    internal_remarks: "Internal Remark 1",
    blocked_by: "User 1",
    date_time: "2022-01-01 00:00:00",
    revoked_block: "Revoked Block 1",
  },
  {
    block_type: "Type B",
    block_category: "Category 2",
    remarks: "Remark 2",
    internal_remarks: "Internal Remark 2",
    blocked_by: "User 2",
    date_time: "2022-02-02 00:00:00",
    revoked_block: "Revoked Block 2",
  },
];
// Dummy data for the Value Cap Current table
export const valueCapCurrentTableD = [
  {
    remarks: "Remark 3",
    current_value_cap: "Value Cap 3",
    assigned_by: "User 3",
    assigned_on: "2022-03-03",
    time: "00:00:00",
    expiry_date: "2022-04-03",
    default_value_cap: "Default Cap 3",
  },
  {
    remarks: "Remark 4",
    current_value_cap: "Value Cap 4",
    assigned_by: "User 4",
    assigned_on: "2022-04-04",
    time: "00:00:00",
    expiry_date: "2022-05-04",
    default_value_cap: "Default Cap 4",
  },
  {
    remarks: "Remark 5",
    current_value_cap: "Value Cap 5",
    assigned_by: "User 5",
    assigned_on: "2022-05-05",
    time: "00:00:00",
    expiry_date: "2022-06-05",
    default_value_cap: "Default Cap 5",
  },
];

// Meta data for the Stockist Details table
export const stockistDetailsTableD = [
  {
    stockist_code: "Code 1",
    stockist_name: "Name 1",
    default_tag: "Tag 1",
    created_by: "User 1",
    created_date: "2022-01-01",
    remarks: "Remark 1",
  },
  {
    stockist_code: "Code 2",
    stockist_name: "Name 2",
    default_tag: "Tag 2",
    created_by: "User 2",
    created_date: "2022-02-02",
    remarks: "Remark 2",
  },
];

// Column definitions for the Stockist Log table
export const stockistLogColumns = [
  columnHelper.accessor("stockist_code", {
    cell: (info) => info.getValue(),
    header: () => "Stockist Code",
  }),
  columnHelper.accessor("stockist_name", {
    cell: (info) => info.getValue(),
    header: () => "Stockist Name",
  }),
  columnHelper.accessor("default_tag", {
    cell: (info) => <Badge className="bg-[#E9E9E9]">{info.getValue()}</Badge>,
    header: () => "Default Tag",
  }),
  columnHelper.accessor("created_by", {
    cell: (info) => info.getValue(),
    header: () => "Created By",
  }),
  columnHelper.accessor("modified_by", {
    cell: (info) => info.getValue(),
    header: () => "Modified By",
  }),
  columnHelper.accessor("modified_date", {
    cell: (info) => info.getValue(),
    header: () => "Modified Date",
  }),
  columnHelper.accessor("remarks", {
    cell: (info) => info.getValue(),
    header: () => "Remarks",
  }),
];

// Dummy data for the Stockist Log table
export const stockistLogDummyData = [
  {
    stockist_code: "S001",
    stockist_name: "Stockist 1",
    default_tag: "Tag1",
    created_by: "User1",
    modified_by: "User2",
    modified_date: "2022-01-01",
    remarks: "Test Remarks",
  },
  {
    stockist_code: "S002",
    stockist_name: "Stockist 2",
    default_tag: "Tag2",
    created_by: "User2",
    modified_by: "User3",
    modified_date: "2022-02-01",
    remarks: "Test Remarks 2",
  },
];

// Dummy data for delegate details
export const delegateDetailsData = [
  {
    delegate_code: "D001",
    delegate_name: "Delegate 1",
    location: "Location 1",
    location_code: "L001",
    mobile_number: "1234567890",
    status: "Active",
    registration_date: "2022-01-01",
    remove: "Remove",
  },
  {
    delegate_code: "D002",
    delegate_name: "Delegate 2",
    location: "Location 2",
    location_code: "L002",
    mobile_number: "0987654321",
    status: "Inactive",
    registration_date: "2022-02-01",
    remove: "Remove",
  },
];

// Dummy data for the product details table
export const productDetailsData = [
  {
    item_code: "IC001",
    item_name: "Product 1",
    division: "Division 1",
    type: "Type 1",
    value: "Value 1",
    qtr_limit: "Qtr. Limit 1",
    min_qty: "Min Qty. 1",
    int_qty: "Int. Qty 1",
    max_qty: "Max Qty. 1",
    log: "Log 1",
  },
  {
    item_code: "IC002",
    item_name: "Product 2",
    division: "Division 2",
    type: "Type 2",
    value: "Value 2",
    qtr_limit: "Qtr. Limit 2",
    min_qty: "Min Qty. 2",
    int_qty: "Int. Qty 2",
    max_qty: "Max Qty. 2",
    log: "Log 2",
  },
  // Add more dummy data as needed
];

export const updateMobileSchema = yup.object().shape({
  mobile: yup
    .number()
    .positive()
    .required()
    .transform((value) => {
      return isNaN(value) ? undefined : value;
    })
    .test("len", "Mobile number should 10 characters", (val: any) => String(val).length === 10)
    .test("mobile-validation", "Invalid mobile number", mobileStatusHandler),
});
