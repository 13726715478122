import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { Input } from "src/components";
import Breadcrumb from "src/components/ui/breadcrumb.component";
import InvoiceApproval from "./invoice-approval.component";
import InvoiceEntry from "../invoice-homepage/invoice-entry.component";
import InvoiceLayout from "./invoice-layout.component";
import InvoiceComparison from "../invoice-details/invoice-comparison.component";
import ORD1 from "../invoice-details/order-details/ord1.component";
import ORD2 from "../invoice-details/order-details/ord2.component";
import ORD4 from "../invoice-details/order-details/ord4.component";
import ORD5 from "../invoice-details/order-details/ord5.component";
import ORD8 from "../invoice-details/order-details/ord8.component";
import { InvoiceEntryScreen } from "../invoice-details/InvoiceEntryScreen";
import { InvoiceApprovalScreen } from "../invoice-details/InvoiceApprovalScreen";

const tabs = ["Entry", "Approval", "Completed", "Comparison", "INV9", "Order Info", "ORD3", "ORD4", "ORD5", "ORD8"];

const InvoiceMasterComponent = () => {
  return (
    <InvoiceLayout>
      <header className="flex flex-col justify-between py-5 mx-auto">
        <div className="flex justify-between px-[3.75rem]">
          <div>
            <Breadcrumb
              currentActive="Stockists"
              dataOrigin={[
                { name: "Masters", path: "masters" },
                { name: "Stockist", path: "stockists" },
              ]}
            />
            <h1 className="text-[#49484C] font-bold text-[2rem]">My Tasks</h1>
            <p className="font-medium text-base text-[#929194]">View and browse all your tasks</p>
          </div>

          <div className="flex gap-2 items-center">
            <Input placeholder="Search" className="px-3 h-[32px] w-[180px] rounded-lg " showIcon={true} />
          </div>
        </div>
        <Tab.Group>
          <Tab.List className="relative px-[3.75rem] mb-[1.16rem] mt-6 border-b-[1px] border-b-[#E9E9E9] shadow-md">
            {tabs.map((item: string) => (
              <Tab key={item} as={Fragment}>
                {({ selected }: { selected: boolean }) => (
                  <div
                    className={`relative inline-block cursor-pointer -bottom-[1px] px-4 py-3 font-semibold text-sm  border-b-2  not-italic  hover:border-b-[#586AF5] hover:text-[#586af5] focus:outline-none ${
                      selected ? "text-[#586af5] border-b-[#586AF5]" : "text-[rgba(0,0,0,0.4)] border-b-transparent"
                    }`}
                  >
                    {item}
                  </div>
                )}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel className="px-[3.75rem]">{<InvoiceEntry />}</Tab.Panel>
            <Tab.Panel className="px-[3.75rem]">
              <InvoiceApproval />
            </Tab.Panel>
            <Tab.Panel className="px-[3.75rem]">
              <InvoiceEntryScreen />
            </Tab.Panel>

            <Tab.Panel className="px-[3.75rem]">
              <InvoiceComparison />
            </Tab.Panel>
            <Tab.Panel className="px-[3.75rem]">
              <InvoiceApprovalScreen />
            </Tab.Panel>
            <Tab.Panel className="px-[3.75rem]">
              <ORD1 />
            </Tab.Panel>
            <Tab.Panel className="px-[3.75rem]">
              <ORD2 />
            </Tab.Panel>
            <Tab.Panel className="px-[3.75rem]">
              <ORD4 />
            </Tab.Panel>
            <Tab.Panel className="px-[3.75rem]">
              <ORD5 />
            </Tab.Panel>
            <Tab.Panel className="px-[3.75rem]">
              <ORD8 />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </header>
    </InvoiceLayout>
  );
};

export default InvoiceMasterComponent;
