import { useState, useCallback } from "react";
import { ApiBlocker } from "src/constants/global-const";

interface EventCheckOptions {
  retries?: number;
  retryDelay?: number;
  setPending?: (val: boolean) => void;
  setBlocked?: (val: boolean) => void;
}

export const useEventStatus = (options?: EventCheckOptions) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const withEventCheck = useCallback(
    <T>(apiCall: () => Promise<T>, streamId?: string): Promise<T> => {
      const { retries = 10, retryDelay = 1000 } = options || {};
      setLoading(true);
      setError(null);

      const checkEventStatus = async (): Promise<T> => {
        if (!streamId) {
          return apiCall();
        }

        const eventStatus = await ApiBlocker.eventChecked(streamId);

        if (eventStatus?.isEventBlocked) {
          setIsBlocked(true);
          if (options?.setBlocked) options.setBlocked(true);

          throw new Error("Event is blocked. Please try again later");
        }

        if (eventStatus?.isEventPending) {
          let retryCount = 0;
          setIsPending(true);
          if (options?.setPending) options.setPending(true);

          const retry = async (): Promise<T> => {
            const status = await ApiBlocker.eventChecked(streamId);

            if (status?.isEventPending && retryCount < retries) {
              retryCount++;
              await new Promise((resolve) => setTimeout(resolve, retryDelay));
              return retry();
            }

            if (status?.isEventBlocked) {
              setIsBlocked(true);
              if (options?.setBlocked) options.setBlocked(true);
              throw new Error("Event became blocked during retry");
            }

            return apiCall();
          };

          return retry();
        }

        setIsPending(false);
        if (options?.setPending) options.setPending(true);
        return apiCall();
      };

      return checkEventStatus()
        .catch((err) => {
          const error = err instanceof Error ? err : new Error("Unknown error occurred");
          setError(error);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [options]
  );

  return {
    withEventCheck,
    loading,
    error,
    isBlocked,
    isPending,
  };
};
