import InvoiceHomeDetailCard from "../invoice-homepage/InvoiceHomeDetailCard";
import { getInvoiceDetailsCardInfo } from "../invoice-master/invoice-helper";
import { RootState } from "src/context/store";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "src/hooks/useToast";
import { invoiceActions } from "src/context/invoice-slice";
import { fetchInvoicesData } from "src/services/invoice-api";
import { useEffect, useState } from "react";
import { Spinner } from "src/components";
import Book from "../../assets/book.png";
import VersioningCrate from "src/components/Versioning/versioningModal.component";
import { useOutsideClick } from "src/hooks/useOutsideClick";

const InvoiceApproval = () => {
  const { invoiceApprovalList } = useSelector((state: RootState) => state.invoice);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [VersioningModal, setVersioningModal] = useState(false);
  const outSideClickRef = useOutsideClick(() => {
    setVersioningModal(false);
  });

  const fetchTabData = async () => {
    setIsLoading(true);
    try {
      let response;
      const searchParams = {
        fuid_status_id: 6,
      };

      response = await fetchInvoicesData(searchParams);

      dispatch(invoiceActions.setInvoiceApprovalList(response?.data?.data?.invoiceData || []));
      toast({ description: "Invoice data fetched successfully", variant: "default" });
    } catch (error) {
      console.error("Error fetching invoices data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTabData();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[50vh]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="relative">
      <div
        onClick={() => {
          setVersioningModal((prev) => !prev);
        }}
        className="p-1 h-auto absolute -top-[40px] -right-[65px] cursor-pointer"
      >
        <img onClick={() => {}} src={Book} alt="React Logo" className="h-10 w-14" />
      </div>
      {VersioningModal && (
        <div ref={outSideClickRef}>
          <VersioningCrate type="Invoice" />
        </div>
      )}
      <ul>
        {invoiceApprovalList?.map((data: any, index: number) => (
          <li className="my-5 rounded-xl border-2 shadow-sm border-1-black" key={index}>
            <InvoiceHomeDetailCard
              status={data?.fileStatusHdr?.fuid_status_desc}
              isExpandable={true}
              isExpandedInitial={false}
              data={getInvoiceDetailsCardInfo(data)}
              checkDataType={false}
              invoiceData={data}
              viewDetails={true}
              index={index}
              approvalScreen={true}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InvoiceApproval;
