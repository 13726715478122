/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnFiltersState, RowSelectionState, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { FC, Fragment, useState } from "react";
// import arrowDownIcon from "../../assets/arrow-down.svg";
import { Button, Spinner } from "src/components";
import BlockIcon from "src/assets/slash.svg";
import { cn } from "src/utils/utils";

interface TableProps {
  columns: any[];
  data: any[];
  onRowClick?: (row: any) => void;
  title?: string;
  subtitle?: string;
  isGlobalSearch?: boolean;
  searchPlaceholder?: string;
  showheader?: boolean;
  cta1Action?: any;
  cta2Action?: any;
  cta1?: string;
  cta2?: string;
  blockAction?: any;
  headClass?: string;
  isLoading?: boolean;
}

const SubTable: FC<TableProps> = ({
  columns,
  data,
  onRowClick,
  title,
  // subtitle,
  cta1Action,
  cta2Action,
  cta1,
  cta2,
  blockAction,
  showheader = true,
  headClass,
  isLoading
}) => {
  const [columnFilters, setColumnsFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    state: {
      columnFilters,
      rowSelection,
    },
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row) => row.counter_field_force_id,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnsFilters,
    onRowSelectionChange: setRowSelection,
  });

  return (
    <>
      <div className="bg-[#F8F8F8] w-full rounded-[0.652rem]">
        {title && (
          <section className="flex justify-between items-center px-4 py-3">
            <div>
              <h4 style={{ fontFamily: "Inter", fontSize: "14px", fontStyle: "normal", fontWeight: "600", lineHeight: "18px", color: " #000" }}>
                {title}
              </h4>
              {/* <p className="text-sm font-medium text-[#49484C] opacity-60">{subtitle}</p> */}
            </div>
            <div className="flex gap-2">
              {blockAction && (
                <Button onClick={blockAction} className="border">
                  <img src={BlockIcon} alt="block" width={16} height={16} />
                </Button>
              )}
              {cta1 && (
                <Button
                  style={{
                    color: "#303030",
                    borderRadius: "6px",
                    border: "1px solid #E3E3E3",
                    backgroundColor: "#F1F1F1",
                    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.16)",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "450",
                    lineHeight: "20px",
                  }}
                  onClick={() => cta1Action()}
                >
                  {cta1}
                </Button>
              )}
              {cta2 && (
                <Button
                  style={{
                    color: "#E3E3E3",
                    borderRadius: "6px",
                    backgroundColor: "#8051FF",
                    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.16)",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "450",
                    lineHeight: "20px",
                  }}
                  onClick={() => cta2Action()}
                >
                  {cta2}
                </Button>
              )}
            </div>
          </section>
        )}

{isLoading && (
        <div className="flex justify-center w-full py-5">
          <Spinner />
        </div>
      )}

      {!isLoading &&  <div className="overflow-auto pl-5 pr-5 pb-5">
          <div className="w-full border rounded-[0.625rem] overflow-hidden">
            <table className="w-full text-sm text-left text-[#49484C] font-medium table-auto">
              {showheader && (
                <thead className={cn(`pt-4 w-full ${headClass ? headClass : ""}`)}>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          className="py-2 pl-2 text-left border border-b-0"
                          key={header.id}
                          style={{
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "16px",
                          }}
                        >
                          <div className="flex items-center max-w-full gap-2 overflow-hidden">
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            {/* {header.column.getCanSort() && (
                              <button onClick={header.column.getToggleSortingHandler()}>
                                <img src={arrowDownIcon} alt="sorting" />
                              </button>
                            )} */}
                            {{ asc: " 🔼", desc: " 🔽" }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
              )}
              <tbody className="bg-white p-0">
                {table.getRowModel().rows.map((row) => {
                  return (
                    <Fragment key={row.id}>
                      <tr
                        onClick={(e) => {
                          e.stopPropagation();
                          if (onRowClick) onRowClick(row);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className="py-2 pl-2 overflow-hidden  whitespace-nowrap border border-b-0"
                            style={{
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "450",
                              lineHeight: "16px",
                            }}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
              <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            </table>
          </div>
        </div>}
      </div>
    </>
  );
};

export default SubTable;
