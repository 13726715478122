import { useSelector } from "react-redux";
import { Button } from "src/components";
import { useNavigate } from "react-router-dom";
import SuccessIcon from "../../assets/successCircle.svg";
import { useEffect } from "react";
import moment from "moment";

const OrderConfirmation = () => {
  const { orderDetails, cartDataObj, selectedCounterData } = useSelector((state: any) => state.ord);
  const navigate = useNavigate();

  const totalPrice = orderDetails?.reduce((acc: any, item: any) => acc + item?.Order_Price, 0);
  // const totalDiscount = orderDetails?.reduce((acc: any, item: any) => acc + Number(item?.Order_CashDiscount), 0);
  // const totalDiscount = orderDetails?.[0]?.Order_CashDiscount;
  const totalItems = Object.values(cartDataObj)?.reduce((acc: number, item: any) => acc + item?.cartQty, 0);

  // const getTotalDiscount = (): number => {
  //   let totalDiscount = 0;

  //   Object.values(cartDataObj)?.forEach((item: any) => {
  //     const ptr = Number(item?.companyProducts?.ptr || 0);
  //     const cartQty = Number(item?.cartQty || 0);
  //     const priceIdentifier = item?.priceldentifier?.priceldentifier_id;
  //     const slab = item?.counter_product_slab?.[0];

  //     if (!slab) return;

  //     switch (priceIdentifier) {
  //       case 2: // Scheme Discount
  //         totalDiscount += ptr * Math.floor(cartQty / (slab.schemeqty_cp || 1)) * (slab.quantity_cp || 0);
  //         break;

  //       case 3: // Fixed Price Discount
  //         totalDiscount += Number(slab.fixedprice_cp || 0) * cartQty;
  //         break;

  //       case 1: // Percentage Discount
  //       default:
  //         totalDiscount += (ptr * cartQty * Number(slab.discountonptr_cp || 0)) / 100;
  //         break;
  //     }
  //   });

  //   return Number(totalDiscount.toFixed(2));
  // };

  useEffect(() => {
    if (!orderDetails || orderDetails?.length === 0) {
      navigate("/request-approval/orders");
    }
  }, []);

  return orderDetails ? (
    <div className="max-w-[1400px] mx-auto p-6 px-20 bg-white rounded-lg mt-10">
      {/* Header */}
      <div className="text-center mb-8">
        <div className="text-4xl text-green-500 mb-2">✔</div>
        <h2 className="text-3xl font-semibold">Thank you for placing an order</h2>
        <p className="text-[#4A4A4A] mt-2 text-sm">
          Order initiated successfully on behalf of {selectedCounterData?.counter_location?.counter_hdr_id?.counter_name}
        </p>
        <div className="bg-[#F1F1F1] text-[#4A4A4A] inline-block px-4 py-1 rounded-md mt-4 text-sm font-medium">
          {orderDetails.map((item: any) => item?.Order_OrderNumber).join(", ")}
        </div>
      </div>
      <div className="flex w-full gap-6 mb-8 mt-16">
        {/* Details Container */}
        <div className="flex flex-col w-[55%] gap-6 mb-8">
          {/* Personnel Details */}
          <div className="border border-[#D0D0D0] shadow-sm p-6 rounded-xl">
            <h3 className="text-lg font-semibold pb-2 mb-4">Personal Details</h3>
            <div className="space-y-3 text-[1rem]">
              <p className="flex justify-between">
                <p className="text-[#616161]">Stockist Name:</p> {orderDetails[0]?.Stockist?.Stockist_Name}
              </p>
              <p className="flex justify-between">
                <p className="text-[#616161]">Headquarter:</p>{" "}
                {cartDataObj[Object.keys(cartDataObj)[0]]?.counter_ff_div_id?.position_code?.emp_position_devrel[0]?.headQuarter_code}
              </p>
              <p className="flex justify-between">
                <span className="text-[#616161]">Order No.:</span>
                <span className="text-right">{orderDetails.map((item: any) => item?.Order_OrderNumber).join(", ")}</span>
              </p>
              <p className="flex justify-between">
                <p className="text-[#616161]">Date: </p> {moment(orderDetails[0]?.Order_OrderDate).format("MMM Do, YYYY")}
              </p>
              <p className="flex justify-between">
                <p className="text-[#616161]">Time: </p>
                {moment(orderDetails[0]?.Order_OrderDate).format("h:mm A")}{" "}
              </p>
            </div>
          </div>

          {/* Order Details */}
          <div className="bg-white border border-[#D0D0D0] p-4 rounded-xl">
            <div className="flex justify-between items-center pb-2">
              <h3 className="text-lg font-semibold mb-4 ">Order Details</h3>
              <p className="border border-[#c4afff] rounded-lg text-[#8051ff] text-sm font-medium px-4 py-2">
                {Object.keys(cartDataObj)?.length} Items | {totalItems} units
              </p>
            </div>{" "}
            <div className="space-y-2 text-sm">
              <table className="w-full  border-separate border-spacing-y-3">
                <tr className="text-[1rem]">
                  <td className="text-[#616161]">Product Name</td>
                  <td className="text-[#616161]">Discount Type</td>
                  <td className="text-[#616161]">Qty.</td>
                  <td className="text-[#616161]">Price</td>
                </tr>
                {cartDataObj &&
                  Object.entries(cartDataObj)?.map(([_, item]: [string, any]) => (
                    <tr className="text-[1rem]">
                      <td>{item?.companyProducts?.name}</td>
                      {item?.priceldentifier?.priceldentifier_id == "1" && item?.counter_product_slab?.[0]?.discountonptr_cp && (
                        <td>
                          {item?.priceldentifier?.description}: {item?.counter_product_slab?.[0]?.discountonptr_cp}%
                        </td>
                      )}
                      {item?.priceldentifier?.priceldentifier_id == "2" && item?.counter_product_slab?.[0]?.schemeqty_cp && (
                        <td>
                          {item?.priceldentifier?.description}: {item?.counter_product_slab?.[0]?.schemeqty_cp} +{" "}
                          {item?.counter_product_slab?.[0]?.quantity_cp}
                        </td>
                      )}
                      {item?.priceldentifier?.priceldentifier_id == "3" && item?.counter_product_slab?.[0]?.fixedprice_cp && (
                        <td>
                          {item?.priceldentifier?.description}: ₹{item?.counter_product_slab?.[0]?.fixedprice_cp}
                        </td>
                      )}

                      <td>{item?.cartQty}</td>
                      <td>{item?.companyProducts?.ptr}</td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
        </div>

        {/* Order Summary */}
        <div className="bg-white w-[40%] px-10 rounded-lg text-[1rem]">
          <div className="text-sm space-y-4">
            <div className="flex justify-between">
              <span className="text-[#616161] text-[1rem]">Order Status:</span>
              <span className="font-semibold text-[1.1rem] flex items-center">
                <img src={SuccessIcon} alt="counter_details_check" className="w-5 h-5 mr-2" />
                {totalPrice?.toFixed(2)}
              </span>
            </div>
            {/* <div className="flex justify-between">
              <span className="text-[#616161] text-[1rem]">Discount:</span>
              <span className="text-[#616161] text-[1rem]">-₹{totalDiscount.toFixed(2)}</span>
            </div> */}
            {/* <div className="flex justify-between">
              <span className="text-[#616161] text-[1rem]">GST:</span>
              <span className="text-[1rem]">₹23</span>
            </div> */}
            <div className="flex justify-between text-lg font-bold mt-2">
              <span className="text-[#616161] text-[1rem]">Total:</span>
              <span className="text-[1rem]">₹{totalPrice?.toFixed(2)}</span>
            </div>
            <Button className="bg-[#8051FF] text-white w-full" onClick={() => navigate(`/transactions/orders/${1}`)}>
              Back to orders
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-screen">No data found</div>
  );
};

export default OrderConfirmation;
