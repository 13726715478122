export const getInvoiceDetailsCardInfo = (invoiceData: any) => {
  let response: any = {};

  response = {
    Company: invoiceData?.company_name || "-", // Company name
    "Invoice No.": invoiceData?.invoice?.invoice_number || invoiceData?.stockist_invoice_number, // Invoice number
    "Invoice Date": invoiceData?.created_date
      ? `${new Date(invoiceData.created_date).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}, ${new Date(invoiceData.created_date).toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        })}`
      : "-",
    "Order Number": invoiceData?.fuid_no || "-", // Order number
    Counter: invoiceData?.counter_code || "-", // Counter
    "Pharmacy Name": invoiceData?.pharmacy_name || "-", // Pharmacy name
    City: invoiceData?.state_district_city || "-", // City
    "Link Type": invoiceData?.link_type || "-", // Link type logic
    Stockist: invoiceData?.stockist_name || "-",
    "Stockist Code (CMP)": invoiceData?.company_stockist_code || "-",
    "Stockist Code (MV)": invoiceData?.stockist_medvol_code || "-",
  };

  return response;
};

export const getInvoiceDetailsInfo = (invoiceDetails: any) => {
  let response: any = {};

  response = {
    Company: invoiceDetails?.company_name || "-",
    Stockist: invoiceDetails?.stockist_name || "-",
    "GST Status": invoiceDetails?.invoice?.gst_validated === true ? "Yes" : "No",

    "Invoice no.": invoiceDetails?.invoice?.invoice_number || String(invoiceDetails?.stockist_invoice_number) || "-",
    "Stockist code": invoiceDetails?.stockist_medvol_code || "-",
    "GST No.": invoiceDetails?.counter_gst_number || " ",

    "Invoice date": invoiceDetails?.created_date || "-",
    Pharmacy: invoiceDetails?.pharmacy_name || "-",
    "E-Inv.Applicability": invoiceDetails?.applicable || "-",

    "Reference ID": invoiceDetails?.reference_no || "-",
    "Pharmacy code": invoiceDetails?.pharmacy_code || "-",
    "QR/IRN": invoiceDetails?.invoice?.e_inv_ref_type || " ",

    Counter: invoiceDetails?.counter_name || "-",
    "Clinic/Hospital": invoiceDetails?.counter_clinic_name || "-",
    "Irn Number": invoiceDetails?.invoice?.irn || " ",
    "DL Present": invoiceDetails?.invoice?.dl_validated === true ? "Yes" : "No",
    "DL no.": invoiceDetails?.counter_dl_number || " ",

    "MCI Validated": invoiceDetails?.invoice?.mci_validaiton === true ? "Yes" : "No" || " ",
    Address: invoiceDetails?.counter_address,

    "Counter code": invoiceDetails?.counter_code || "-",
    "MCI no.": invoiceDetails?.counter_mci_number || " ",

    "Link Type": invoiceDetails?.link_type || "-",
    City: invoiceDetails?.state_district_city?.split(",")[2] || "-",
    "MTD Value": "-",
  };

  return response;
};
