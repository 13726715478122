import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { api, vitecompanyserviceurl } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

const instance: AxiosInstance = axios.create({
  baseURL: api,
});

const companyserviceurl: AxiosInstance = axios.create({
  baseURL: vitecompanyserviceurl + "/api/v1/oms",
});

const companyserviceurlevent: AxiosInstance = axios.create({
  baseURL: vitecompanyserviceurl,
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
companyserviceurl.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
companyserviceurlevent.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));

export const fetchInvoicesData = ({
  startDate,
  endDate,
  company_code,
  division_code,
  assignee_id,
  city,
  district,
  state,
  fuid_no,
  fuid_status_id,
  invoice_number,
  order_no,
  counter_code,
  stockist_medvol_code,
  company_stockist_code,
  page_size,
  page_number,
}: any) => {
  return companyserviceurl.get(`/stockist_attachment/all`, {
    params: {
      company_code: company_code,
      division_code: division_code,
      date_from: startDate,
      date_to: endDate,
      invoice_assignment_id: assignee_id,
      city: city,
      district: district,
      state: state,
      fuid_no: fuid_no,
      fuid_status_id: fuid_status_id,
      invoice_number: invoice_number,
      order_no: order_no,
      counter_code: counter_code,
      stockist_medvol_code: stockist_medvol_code,
      company_stockist_code: company_stockist_code,
      page_size: page_size,
      page: page_number,
    },
    headers: {
      company_code: 618,
    },
  });
};

export const fetchPriorityInvoicesData = ({
  startDate,
  endDate,
  company_code,
  division_code,
  assignee_id,
  city,
  district,
  state,
  fuid_no,
  fuid_status_id,
  invoice_number,
  order_no,
  counter_code,
  stockist_medvol_code,
  company_stockist_code,
  page_size,
  page_number,
}: any) => {
  return companyserviceurl.get(`/stockist_attachment/all?is_prioritised=true`, {
    params: {
      company_code: company_code,
      division_code: division_code,
      date_from: startDate,
      date_to: endDate,
      invoice_assignment_id: assignee_id,
      city: city,
      district: district,
      state: state,
      fuid_no: fuid_no,
      fuid_status_id: fuid_status_id,
      invoice_number: invoice_number,
      order_no: order_no,
      counter_code: counter_code,
      stockist_medvol_code: stockist_medvol_code,
      company_stockist_code: company_stockist_code,
      page_size: page_size,
      page: page_number,
    },
    headers: {
      company_code: 618,
    },
  });
};

export const fetchEscalatedInvoicesData = ({
  startDate,
  endDate,
  company_code,
  division_code,
  assignee_id,
  city,
  district,
  state,
  fuid_no,
  fuid_status_id,
  invoice_number,
  order_no,
  counter_code,
  stockist_medvol_code,
  company_stockist_code,
  page_size,
  page_number,
}: any) => {
  return companyserviceurl.get(`/stockist_attachment/all?is_escalated=true`, {
    params: {
      company_code: company_code,
      division_code: division_code,
      date_from: startDate,
      date_to: endDate,
      invoice_assignment_id: assignee_id,
      city: city,
      district: district,
      state: state,
      fuid_no: fuid_no,
      fuid_status_id: fuid_status_id,
      invoice_number: invoice_number,
      order_no: order_no,
      counter_code: counter_code,
      stockist_medvol_code: stockist_medvol_code,
      company_stockist_code: company_stockist_code,
      page_size: page_size,
      page: page_number,
    },
    headers: {
      company_code: 618,
    },
  });
};

export const getInvoiceDetails = (stockist_invoice_attachment_id: string, products: boolean) => {
  return companyserviceurl.get(
    `/stockist_attachment/details?stockist_invoice_attachment_id=${stockist_invoice_attachment_id}&invoice_id=&products=${products}`,
    {
      headers: {
        company_code: 618,
      },
    }
  );
};

export const downloadInvoice = (file_path: string) => {
  return companyserviceurl.get(`/stockist_attachment/get-file?file_key=${file_path}`, {
    headers: {
      company_code: 618,
    },
  });
};

export const uploadInvoice = (payload: any) => {
  return companyserviceurlevent.post(`/event/invoice/add`, payload);
};

export const updateInvoice = (payload: any) => {
  return companyserviceurlevent.post(`event/invoice/product-add`, payload);
};

export const rejectInvoice = (payload: any) => {
  return companyserviceurlevent.post(`/event/stockist_attachment/reject`, payload);
};

export const getRejectionCategory = () => {
  return companyserviceurlevent.get(`api/v1/oms/stockist_attachment/reject-categories`);
};

export const getAssigneeList = () => {
  return companyserviceurl.get("/stockist_attachment/invoice-assignees");
};

export const reAssignInvoice = (payload: any) => {
  return companyserviceurlevent.post(`/event/stockist_attachment/reassign`, payload);
};

export const escalateInvoice = (payload: any) => {
  return companyserviceurlevent.post(`/event/stockist_attachment/escalate`, payload);
};

export const setPriorityInvoice = (payload: any) => {
  return companyserviceurlevent.post(`/event/stockist_attachment/set-priority`, payload);
};

export const putInvoiceOnHold = (payload: any) => {
  return companyserviceurlevent.post(`/event/stockist_attachment/hold`, payload);
};

export const fetchInvoiceStatus = () => {
  return companyserviceurl.get(`/stockist_attachment/fuid-status-list`);
};

export const getSimilarCompanyProducts = (
  company_code: string,
  division_codes: string,
  product_name: string,
  product_code: string,
  page: number,
  page_size: number
) => {
  return instance.get(
    `/oms/counter/company/products?company_code=${company_code}&division_codes=${division_codes}&product_name=${product_name}&item_code=${product_code}&page=${page}&page_size=${page_size}`
  );
};

export const editInvoice = (payload: any) => {
  return companyserviceurlevent.post(`/event/invoice/edit`, payload);
};

export const getOrderedProducts = (payload: any) => {
  return companyserviceurlevent.get(
    `/api/v1/oms/order/similar-products?counter_code=${payload?.counter_code}&product_name=${payload?.product_name}&page=${payload?.page}&page_size=${payload?.page_size}&company_stockist_code=${payload?.company_stockist_code}`
  );
};

export const getSimilarOrders = (payload: any) => {
  return companyserviceurl.get(
    `/order/similar-orders?item_code=${payload?.item_code}&inv_mrp=${payload?.inv_mrp}&counter_code=${payload?.counter_code}&company_stockist_code=${payload?.company_stockist_code}`
  );
};

export const editInvoiceProduct = (payload: any) => {
  return companyserviceurlevent.post(`/event/invoice/product-edit`, payload);
};

export const invoiceProductDelete = (payload: any) => {
  return companyserviceurlevent.delete(`/event/invoice/product-delete`, { data: payload });
};

export const getInvoiceBatchList = (itemCode: string) => {
  return companyserviceurl.get(`/invoice/product-batch?item_code=${itemCode}`);
};

export const getInvoicePriceList = (itemCode: string) => {
  return instance.get(`/api/v1/product/get-product-price?item_code=${itemCode}`);
};
