import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "src/components";
import { ordActions } from "src/context/order-slice";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import { getCounterByEmployee, getCounterOrderProductsById, getPostPermission } from "src/services/order-api";
import cross from "../../assets/cross.svg";
import PurpleLeftArrowIcon from "../../assets/purple-left-arrow.svg";
import Button from "../../components/Button";
import Input from "src/components/ui/input.component";

const OrdersProductScreen = () => {
  const { locationCode } = useParams();
  // const locationCodeFixed = "141439";
  const { productsData, cartDataObj, selectedCounterData } = useSelector((state: RootState) => state.ord);
  const { idTokenDetails } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const counterCode = window.sessionStorage.getItem("counterOrderId");
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);

  const { toast } = useToast();

  const handleAddToCart = (item: any) => {
    const counter_product_lnk_id = item.counter_product_lnk_id;

    const existingItem = cartDataObj?.[counter_product_lnk_id];
    const currentQty = existingItem?.cartQty || item?.counter_product_slab?.[0]?.min_order_qty;
    const newQty = Number(currentQty);

    dispatch(
      ordActions.setCartDataObj({
        key: counter_product_lnk_id,
        value: {
          ...item,
          cartQty: newQty,
        },
      })
    );
  };

  const handleIncrement = (counter_product_lnk_id: string) => {
    const currentProd = cartDataObj?.[counter_product_lnk_id];
    const currentQty = currentProd?.cartQty || 0;
    const newQty = Number(currentQty) + 1;
    if (newQty > Number(currentProd?.counter_product_slab?.[0]?.max_order_qty)) {
      toast({ description: `Maximum order quantity is ${currentProd?.counter_product_slab?.[0]?.max_order_qty}`, variant: "destructive" });
      return;
    } else {
      dispatch(
        ordActions.setCartDataObj({
          key: counter_product_lnk_id,
          value: {
            ...currentProd,
            cartQty: newQty,
          },
        })
      );
    }
  };

  // const handleDiscount = (productId: any, value: any) => {
  //   setCart((prev: any) => prev.map((cartItem: any) => (cartItem.counter_product === productId ? { ...cartItem, scheme: value } : cartItem)));
  // };

  // const handleDivisionChange = (productId: any, value: any) => {
  //   setCart((prev: any) => prev.map((cartItem: any) => (cartItem.counter_product === productId ? { ...cartItem, division: value } : cartItem)));
  // };

  const handleAdjustToSchemeQty = (counter_product_lnk_id: string) => {
    const currentProd = cartDataObj?.[counter_product_lnk_id];
    const cartQty = currentProd?.cartQty || 0;
    const schemeqty_cp = currentProd?.counter_product_slab?.[0]?.schemeqty_cp || 1;

    if (schemeqty_cp > 0 && cartQty % schemeqty_cp !== 0) {
      const adjustedQty = Math.ceil(cartQty / schemeqty_cp) * schemeqty_cp;

      // Update the cartDataObj with the adjusted quantity
      dispatch(
        ordActions.setCartDataObj({
          key: counter_product_lnk_id,
          value: {
            ...currentProd,
            cartQty: adjustedQty,
          },
        })
      );
      toast({ description: "Quantity adjusted to the nearest multiple of scheme quantity", variant: "default" });
    }
  };

  const handleReviewOrder = () => {
    // Adjust cartQty to be a multiple of schemeqty_cp if necessary
    // Object.values(cartDataObj).forEach((item: any, index: number) => {
    //   let cartQty = item?.cartQty || 0;
    //   if (item?.priceldentifier?.priceldentifier_id === 2) {
    //     const schemeqty_cp = item?.counter_product_slab?.[0]?.schemeqty_cp || 0;
    //     if (schemeqty_cp > 0 && cartQty % schemeqty_cp !== 0) {
    //       cartQty = Math.ceil(cartQty / schemeqty_cp) * schemeqty_cp;
    //       // Update the cartDataObj with the adjusted quantity
    //       cartDataObj[index] = { ...item, cartQty };
    //     }
    //   }
    // });

    // validation for cart data
    const cartData = Object.values(cartDataObj);
    const isValid = cartData.every((item: any) => {
      return item?.cartQty >= item?.counter_product_slab?.[0]?.min_order_qty && item?.cartQty <= item?.counter_product_slab?.[0]?.max_order_qty;
    });
    if (!isValid) {
      toast({
        description: "Please enter a valid quantity",
        variant: "destructive",
      });
      return;
    }

    // const hasInvalidSchemeQty = cartData.some((item: any) => {
    //   if (item?.priceldentifier?.priceldentifier_id === 2) {
    //     const schemeqty_cp = item?.counter_product_slab?.[0]?.schemeqty_cp || 0;
    //     return schemeqty_cp > 0 && item?.cartQty % schemeqty_cp !== 0;
    //   }
    //   return false;
    // });

    // if (hasInvalidSchemeQty) {
    //   toast({
    //     description: `Quantity should be a multiple of scheme quantity for specific products.`,
    //     variant: "destructive",
    //   });
    //   return;
    // }

    if (!Object.keys(cartDataObj)?.length) {
      toast({
        description: "Please select at least one product",
      });
      return;
    }
    navigate(`/transactions/orders/review/${locationCode}`);
  };

  const handleDecrement = (counter_product_lnk_id: string) => {
    const currentProd = cartDataObj?.[counter_product_lnk_id];
    const currentQty = currentProd?.cartQty || 0;
    const newQty = Number(currentQty) - 1;
    if (newQty < currentProd?.counter_product_slab?.[0]?.min_order_qty) {
      dispatch(ordActions.removeCartDataObj({ key: counter_product_lnk_id }));
    } else {
      dispatch(
        ordActions.setCartDataObj({
          key: counter_product_lnk_id,
          value: {
            ...currentProd,
            cartQty: newQty,
          },
        })
      );
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (!value) {
      // If search is empty, reset to show all products
      setFilteredProducts([]);
      return;
    }

    // Perform case-insensitive search across multiple fields
    const searchResults = productsData?.filter(
      (item: any) =>
        item?.companyProducts?.item_code?.toLowerCase().includes(value.toLowerCase()) ||
        item?.companyProducts?.name?.toLowerCase().includes(value.toLowerCase()) ||
        item?.counter_ff_div_id?.company_division_code?.division_name?.toLowerCase().includes(value.toLowerCase()) ||
        item?.companyProducts?.ptr?.toString().includes(value)
    );

    setFilteredProducts(searchResults || []);
  };
  const fetchProductsData = async () => {
    try {
      setIsLoading(true);
      const res = await getCounterOrderProductsById(
        window.sessionStorage.getItem("counterCompanyLocationId") || selectedCounterData?.counter_company_location_id,
        "",
        ""
      );
      dispatch(ordActions.setProductsData(res?.data?.data));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleManualQuantityChange = (item: any, value: string) => {
    const counter_product_lnk_id = item?.counter_product_lnk_id;
    const numValue = Number(value);
    // const minOrderQty = Number(item?.counter_product_slab?.[0]?.min_order_qty) || 1;
    // const maxOrderQty = Number(item?.counter_product_slab?.[0]?.max_order_qty);

    if (isNaN(numValue)) {
      return;
    }

    // if (numValue > maxOrderQty) {
    //   toast({ description: `Maximum order quantity is ${maxOrderQty}`, variant: "destructive" });
    //   return;
    // }

    // if (numValue < minOrderQty) {
    //   toast({
    //     description: `Minimum order quantity for this product is ${minOrderQty}`,
    //     variant: "destructive",
    //   });
    //   return;
    // }

    dispatch(
      ordActions.setCartDataObj({
        key: counter_product_lnk_id,
        value: {
          ...item,
          cartQty: numValue,
        },
      })
    );
  };

  const fetchCounterData = async (counter_company_location_id: number) => {
    const query = `?counter_company_location_id=${counter_company_location_id}`;

    try {
      const response = await getCounterByEmployee(query);
      dispatch(ordActions.setSelectedCounterData(response?.data?.data?.[0]));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPostPermission = async (order_group_ids: number[], item: any) => {
    try {
      const response = await getPostPermission(
        // currentPsr?.emp_position_hdr?.position_code,
        selectedCounterData?.counter_location?.counter_hdr_id?.counter_code,
        order_group_ids,
        selectedCounterData?.company_code,
        "counter",
        idTokenDetails?.["custom:mv_role"],
        selectedCounterData?.counter_location?.location_code,
        selectedCounterData?.counter_company_location_id
      );
      if (response?.data?.status === true) {
        handleAddToCart(item);
      }
    } catch (err: any) {
      toast({
        description: err?.response?.data?.message,
        variant: "destructive",
      });
      console.log(err);
    }
  };

  useEffect(() => {
    if (!productsData || productsData?.length === 0) {
      fetchProductsData();
    }
    if (!selectedCounterData || selectedCounterData.length === 0) {
      fetchCounterData(Number(window.sessionStorage.getItem("counterCompanyLocationId")));
    }
  }, []);

  const displayProducts = searchTerm ? filteredProducts : productsData;

  return (
    <div>
      <div>
        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="p-6 px-0 border-b shadow-md">
              <Button className="text-lg px-16 text-[#7F56D9]" onClick={() => navigate(`/transactions/orders/details/${counterCode}/0`)}>
                <img src={PurpleLeftArrowIcon} alt="back" className="mr-4" />
                Back to Pharmacies
              </Button>
              <div className="w-full px-16">
                <h1 className="font-semibold text-[2rem]">{selectedCounterData?.counter_location?.counter_hdr_id?.counter_name}</h1>
                <div className="flex items-center justify-between gap-2 mt-2">
                  <div className="flex items-center gap-2">
                    <p className="font-semibold text-[.9rem] w-fit px-2 py-1 rounded-xl bg-[#EAF4FF] text-[#00527C]">
                      {selectedCounterData?.counter_location?.counter_hdr_id?.counter_code}
                    </p>
                    <p className="text-[#5E4200] w-fit px-2 py-1 rounded-xl font-semibold bg-[#FFF1E3] text-[.9rem]">
                      {selectedCounterData?.drph_lnk_type?.dr_ph_lnk_code}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Input placeholder="Search for products" value={searchTerm} onChange={handleSearchChange} className="h-12" />
                    <Button onClick={handleReviewOrder} className="bg-[#8051FF] text-white h-12 w-[12rem]">
                      Review Order
                    </Button>
                  </div>
                </div>{" "}
              </div>
            </div>
            {
              <div className="px-16 mt-6">
                {/* Header Section */}

                {/* Products Table */}
                <div className="bg-white rounded-lg shadow-sm">
                  <h2 className="font-semibold text-lg mb-4">{searchTerm ? `Search Results (${displayProducts?.length})` : "Products"}</h2>
                  <div className="overflow-x-auto rounded-lg">
                    <table className="min-w-full table-auto border-collapse border border-gray-200">
                      <thead className="bg-gray-100">
                        <tr>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Product Code</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Product Name</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Division</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Scheme</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Price (PTR)</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Period Type</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Period Limit</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Min Order Qty</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Max Order Qty</th>

                          <th className="px-6 py-3 w-[14rem] text-left text-sm font-medium text-gray-600 border-b border-gray-200">Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayProducts?.length === 0 ? (
                          <tr>
                            <td colSpan={6} className="text-center text-gray-500 py-6">
                              No products found matching your search.
                            </td>
                          </tr>
                        ) : (
                          displayProducts?.map((item: any) => (
                            <tr key={item?.counter_product_lnk_id} className="hover:bg-gray-50 border-b border-gray-200">
                              <td className="px-6 py-4 text-sm text-gray-800">{item?.companyProducts?.item_code}</td>

                              <td className="px-6 py-4 text-sm text-gray-800">{item?.companyProducts?.name}</td>
                              <td className="px-6 py-4 text-sm text-gray-800">
                                {item?.counter_ff_div_id?.company_division_code?.division_name || "-"}
                              </td>
                              <td className="w-[14rem] px-6 py-6 flex justify-between text-sm text-gray-800">
                                {item?.priceldentifier?.description || "-"} :
                                {item?.priceldentifier?.priceldentifier_id == "2" && (
                                  <div className="rounded-md">
                                    <span className="text-sm border border-gray-300 px-2 py-1 rounded-md text-gray-900">
                                      {item?.counter_product_slab?.[0]?.schemeqty_cp} + {item?.counter_product_slab?.[0]?.quantity_cp}
                                    </span>
                                  </div>
                                )}
                                {/* Fixed Price Section */}
                                {item?.priceldentifier?.priceldentifier_id == "3" && item?.counter_product_slab?.[0]?.fixedprice_cp && (
                                  <div className="rounded-md">
                                    <span className="text-sm border border-gray-300 px-2 py-1 rounded-md text-gray-900">
                                      ₹{item?.counter_product_slab?.[0]?.fixedprice_cp}
                                    </span>
                                  </div>
                                )}
                                {/* Discount Section */}
                                {item?.priceldentifier?.priceldentifier_id == "1" && item?.counter_product_slab?.[0]?.discountonptr_cp && (
                                  <div className="rounded-md">
                                    <span className="text-sm border border-gray-300 px-2 py-1 rounded-md text-gray-900">
                                      {item?.counter_product_slab?.[0]?.discountonptr_cp}%
                                    </span>
                                  </div>
                                )}
                              </td>
                              {/* <td className="px-6 py-4 text-sm text-gray-800">₹{item?.companyProducts?.ptr || "N/A"}</td> */}
                              <td className="px-6 py-4 text-sm text-gray-800">₹{item?.counter_product_slab?.[0]?.price}</td>
                              <td className="px-6 py-4 text-sm text-gray-800">{item?.periodtype}</td>
                              <td className="px-6 py-4 text-sm text-gray-800">{item?.periodlimit}</td>
                              <td className="px-6 py-4 text-sm text-gray-800">{item?.counter_product_slab?.[0]?.min_order_qty || "N/A"}</td>
                              <td className="px-6 py-4 text-sm text-gray-800">{item?.counter_product_slab?.[0]?.max_order_qty || "N/A"}</td>
                              <td className="px-6 py-4 text-sm text-gray-800">
                                {!cartDataObj?.[item?.counter_product_lnk_id] ? (
                                  <button
                                    onClick={() => {
                                      fetchPostPermission(item?.companyProducts?.item_division[0]?.ordergroup_id?.ordergroup_id, item);
                                    }}
                                    className="bg-[#8051FF] text-white px-4 py-2 rounded-lg hover:bg-[#6A42E4]"
                                  >
                                    <div className="flex items-center">
                                      <img src={cross} alt="cross" className="w-4 h-4 mr-2 rotate-45" />
                                      Add
                                    </div>
                                  </button>
                                ) : (
                                  <>
                                    <div
                                      className="flex items-center gap-2 w-fit"
                                      onMouseLeave={() => handleAdjustToSchemeQty(item.counter_product_lnk_id)}
                                    >
                                      <button
                                        onClick={() => handleDecrement(item?.counter_product_lnk_id)}
                                        className="px-3 py-1 rounded-lg border border-gray-300 bg-white hover:bg-gray-100"
                                      >
                                        -
                                      </button>
                                      <Input
                                        isError={
                                          cartDataObj?.[item?.counter_product_lnk_id]?.cartQty > item?.counter_product_slab?.[0]?.max_order_qty ||
                                          cartDataObj?.[item?.counter_product_lnk_id]?.cartQty < item?.counter_product_slab?.[0]?.min_order_qty
                                        }
                                        enableArrows={false}
                                        showIcon={false}
                                        type="number"
                                        onChange={(e) => handleManualQuantityChange(item, e.target.value)}
                                        value={cartDataObj?.[item?.counter_product_lnk_id]?.cartQty}
                                        className="py-1 w-16 text-center border rounded-lg border-gray-300"
                                      />
                                      <button
                                        onClick={() => handleIncrement(item?.counter_product_lnk_id)}
                                        className="px-3 py-1 rounded-lg border border-gray-300 bg-white hover:bg-gray-100"
                                      >
                                        +
                                      </button>
                                    </div>
                                    <p className="text-red-500 text-xs mt-1 ml-3">
                                      {cartDataObj?.[item?.counter_product_lnk_id]?.cartQty > item?.counter_product_slab?.[0]?.max_order_qty &&
                                        "Maximum order quantity is " + item?.counter_product_slab?.[0]?.max_order_qty}
                                      {cartDataObj?.[item?.counter_product_lnk_id]?.cartQty < item?.counter_product_slab?.[0]?.min_order_qty &&
                                        "Minimum order quantity is " + item?.counter_product_slab?.[0]?.min_order_qty}
                                    </p>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};

export default OrdersProductScreen;
