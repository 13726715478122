import { useSelector } from "react-redux";
import { Button } from "src/components";
import { RootState } from "src/context/store";

const UinDetails = ({ onConfirm, onBack, uinData }: { onBack: () => void; onConfirm: () => void; uinData: any }) => {
  const { selectedCounterData, addDivisionModalState } = useSelector((state: RootState) => state.counter);
  console.log("uinData", uinData, selectedCounterData, addDivisionModalState);

  const isDoctorLinked = ["DRDS", "DRAPH", "DRNT"].includes(selectedCounterData?.counter_location?.[0]?.drph_lnk_type?.dr_ph_lnk_code);

  const isAsscDetailsInFieldForceObj =
    addDivisionModalState?.data?.counter_field_force_id?.[0]?.assc_counterhdr_data &&
    Object.keys(addDivisionModalState?.data?.counter_field_force_id?.[0]?.assc_counterhdr_data)?.length > 0;

  const isAsscDetailsInAsscHdrObj =
    addDivisionModalState?.data?.assc_counterhdr_id && Object.keys(addDivisionModalState?.data?.assc_counterhdr_id)?.length > 0;

  const getCounterName = () => {
    if (isDoctorLinked) {
      return selectedCounterData?.counter_name ?? "-";
    }

    if (addDivisionModalState?.isEdit) {
      if (isAsscDetailsInAsscHdrObj) {
        return addDivisionModalState?.data?.assc_counterhdr_id?.counter_name ?? "-";
      }

      if (isAsscDetailsInFieldForceObj) {
        return addDivisionModalState?.data?.counter_field_force_id?.[0]?.assc_counterhdr_data?.counter_name ?? "-";
      }
    }

    if (!addDivisionModalState?.isEdit) {
      return addDivisionModalState?.data?.assc_counterhdr_id?.counter_name ?? "-";
    }

    return "-";
  };

  const getCityName = () => {
    if (isDoctorLinked) {
      return selectedCounterData?.counter_location?.[0]?.city_code?.city_name ?? "-";
    }

    if (addDivisionModalState?.isEdit) {
      if (isAsscDetailsInAsscHdrObj) {
        return addDivisionModalState?.data?.assc_counterhdr_id?.city_name ?? "-";
      }

      if (isAsscDetailsInFieldForceObj) {
        return addDivisionModalState?.data?.counter_field_force_id?.[0]?.assc_counterhdr_data?.counter_location?.[0]?.address ?? "-";
      }
    }

    if (!addDivisionModalState?.isEdit) {
      return addDivisionModalState?.data?.assc_counterhdr_id?.city_name ?? "-";
    }

    return "-";
  };

  const getStateName = () => {
    if (isDoctorLinked) {
      return selectedCounterData?.counter_location?.[0]?.city_code?.district_code?.state_code?.state_name ?? "-";
    }

    if (addDivisionModalState?.isEdit) {
      if (isAsscDetailsInAsscHdrObj) {
        return addDivisionModalState?.data?.assc_counterhdr_id?.state_name ?? "-";
      }

      if (isAsscDetailsInFieldForceObj) {
        return (
          addDivisionModalState?.data?.counter_field_force_id?.[0]?.assc_counterhdr_data?.counter_location?.[0]?.city_code?.district_code?.state_code
            ?.state_name ?? "-"
        );
      }
    }

    if (!addDivisionModalState?.isEdit) {
      return addDivisionModalState?.data?.assc_counterhdr_id?.state_name ?? "-";
    }

    return "-";
  };

  return (
    <section className="px-5 py-3">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-[#f8f8f8] text-[#49484C]">
            <th className="p-4" />
            <th className="p-4">As per Medvol</th>
            <th className="p-4">As per Company</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white text-[#49484C]">
            <td className="p-4 bg-[#f8f8f8]">Name</td>
            <td className="p-4 font-semibold">{getCounterName()}</td>
            <td className="p-4 font-semibold">{uinData?.CompanyUINDetails?.Counter_Name ?? "-"}</td>
          </tr>
          <tr className="bg-white text-[#49484C]">
            <td className="p-4 bg-[#f8f8f8]">City</td>
            <td className="p-4 font-semibold">{getCityName()}</td>
            <td className="p-4 font-semibold">{uinData?.CompanyUINDetails?.City ?? "-"}</td>
          </tr>
          <tr className="bg-white text-[#49484C]">
            <td className="p-4 bg-[#f8f8f8]">State</td>
            <td className="p-4 font-semibold">{getStateName()}</td>
            <td className="p-4 font-semibold">{uinData?.CompanyUINDetails?.State_Name ?? "-"}</td>
          </tr>
          <tr className="bg-white text-[#49484C]">
            <td className="p-4 bg-[#f8f8f8]">UIN Speciality</td>
            <td className="p-4 font-semibold">-</td>
            <td className="p-4 font-semibold">{uinData?.CompanyUINDetails?.UIN_Speciality}</td>
          </tr>
          <tr className="bg-white text-[#49484C]">
            <td className="p-4 bg-[#f8f8f8]">Division Speciality</td>
            <td className="p-4 font-semibold">-</td>
            <td className="p-4 font-semibold">{uinData?.CompanyUINDetails?.Division_Speciality}</td>
          </tr>
        </tbody>
      </table>
      <footer className="flex justify-end gap-3 items-center ">
        <Button onClick={onBack} className="border">
          Back
        </Button>
        <Button onClick={onConfirm} className="text-white bg-[#7F56D9]">
          Confirm
        </Button>
      </footer>
    </section>
  );
};

export default UinDetails;
