import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Button, Input } from "src/components";
import { invoiceActions } from "src/context/invoice-slice";
import { RootState } from "src/context/store";
import FilterAccordion from "src/features/fields/container/FilterAccordion";
import { DateRange, DayPicker } from "react-day-picker";
import { addDays } from "date-fns";

interface FilterItem {
  id?: string;
  name?: string;
  code?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // Add an index signature
}

interface InvoiceFiltersProps {
  onClose: () => void;
  companyDetails: FilterItem[];
  divisionDetails: FilterItem[];
  stockistDetails: FilterItem[];
  statusDetails: FilterItem[];
  assigneeDetails: FilterItem[];
  cityDetails: FilterItem[];
  districtDetails: FilterItem[];
  stateDetails: FilterItem[];
}

const Divider = () => <div className="w-full h-[0.0625rem] my-2 bg-[#0000001a]" />;

const InvoiceFiltersComponent = forwardRef<HTMLElement, InvoiceFiltersProps>(
  ({ onClose, companyDetails, divisionDetails, statusDetails, assigneeDetails, cityDetails, districtDetails, stateDetails }) => {
    const [selected, setSelected] = useState<DateRange | undefined>({
      from: new Date(),
      to: addDays(new Date(), 0),
    });
    const [fuidNo, setFuidNo] = useState<string>("");
    const dispatch = useDispatch();

    // if (selected?.from && selected?.to) {
    //   dispatch(invoiceActions.setInvoiceFilters({ key: "dateRange", value: [selected.from?.toISOString(), selected.to?.toISOString()] }));
    // }

    useEffect(() => {
      if (fuidNo) {
        dispatch(invoiceActions.setInvoiceFilters({ key: "fuidNo", value: [fuidNo] }));
      }
    }, [fuidNo]);

    const companyFilterHandler = (details: FilterItem[]) => {
      dispatch(invoiceActions.setInvoiceFilters({ key: "company", value: details }));
    };

    const divisionFilterHandler = (details: FilterItem[]) => {
      dispatch(invoiceActions.setInvoiceFilters({ key: "division", value: details }));
    };

    const stockistFilterHandler = (details: FilterItem[]) => {
      dispatch(invoiceActions.setInvoiceFilters({ key: "stockist", value: details }));
    };

    const statusFilterHandler = (details: FilterItem[]) => {
      dispatch(invoiceActions.setInvoiceFilters({ key: "status", value: details }));
    };

    const assigneeFilterHandler = (details: FilterItem[]) => {
      dispatch(invoiceActions.setInvoiceFilters({ key: "assignee", value: [details] }));
    };

    const cityFilterHandler = (details: FilterItem[]) => {
      dispatch(invoiceActions.setInvoiceFilters({ key: "city", value: details }));
    };

    const districtFilterHandler = (details: FilterItem[]) => {
      dispatch(invoiceActions.setInvoiceFilters({ key: "district", value: details }));
    };

    const stateFilterHandler = (details: FilterItem[]) => {
      dispatch(invoiceActions.setInvoiceFilters({ key: "state", value: details }));
    };

    const resetFilter = () => {
      dispatch(invoiceActions.setInvoiceFilters({ key: "company", value: [] }));
      dispatch(invoiceActions.setInvoiceFilters({ key: "division", value: [] }));
      dispatch(invoiceActions.setInvoiceFilters({ key: "stockist", value: [] }));
      dispatch(invoiceActions.setInvoiceFilters({ key: "status", value: [] }));
      dispatch(invoiceActions.setInvoiceFilters({ key: "assignee", value: [] }));
      dispatch(invoiceActions.setInvoiceFilters({ key: "city", value: [] }));
      dispatch(invoiceActions.setInvoiceFilters({ key: "district", value: [] }));
      dispatch(invoiceActions.setInvoiceFilters({ key: "state", value: [] }));
      dispatch(invoiceActions.setInvoiceFilters({ key: "fuidNo", value: [] }));
      dispatch(invoiceActions.setIsResetFilter(false));
    };

    const filters = useSelector((state: RootState) => state?.invoice?.InvoiceFilters);

    return (
      <aside className="bg-white border-l-[1px] min-w-[24rem] overflow-y-auto max-w-[25rem] border-l-[#00000014] fixed px-8 text-[#49484C] text-xl font-semibold py-5 right-0  h-screen">
        <div className="flex items-center justify-between gap-5 mt-3">
          <h3 className="whitespace-nowrap">Fetch filters</h3>
          <Button className="border py-1 bg-[#7F56D9] text-white" onClick={resetFilter}>
            Reset
          </Button>
          <Button className="border py-2 bg-[#7F56D9] text-white" onClick={onClose}>
            Close
          </Button>
        </div>

        {/* filters  */}
        <div className="flex flex-col ">
          <div className="mt-2">
            <h1 className="text-[.9rem] font-semibold">Assignee</h1>
            <Autocomplete
              placeholder="Search"
              suggestionList={assigneeDetails}
              displayKey="user_name"
              uniqueKey="invoice_assignment_id"
              onSelectionChange={assigneeFilterHandler}
              inputClassName="w-full"
              defaultValue={filters.assignee?.[0]?.user_name}
            />
          </div>
          <div className="mt-2 mb-2">
            <h1 className="text-[.9rem] font-semibold">Fuid no.</h1>
            <Input
              placeholder="Search"
              debounceTime={500}
              className="w-full"
              containerClasses="w-full py-1 mt-1"
              onChange={(e) => setFuidNo(e.target.value)}
              value={fuidNo}
            />
          </div>
          <Divider />
          <FilterAccordion
            uniqueKey="code"
            displayKey="shortname"
            onSelectingFilter={companyFilterHandler}
            title="Company Name"
            arrayToFilter={companyDetails}
            defaultValue={filters.company}
            isSingleSelect={true}
          />
          <FilterAccordion
            uniqueKey="city_code"
            displayKey="city_name"
            onSelectingFilter={cityFilterHandler}
            title="City"
            arrayToFilter={cityDetails}
            defaultValue={filters.city}
            isSingleSelect={true}
          />
          <Divider />
          <FilterAccordion
            uniqueKey=""
            displayKey=""
            onSelectingFilter={() => {}}
            title="Stockist Code (MV)"
            arrayToFilter={[]}
            defaultValue={[]}
            isSingleSelect={true}
          />
          <Divider />
          <FilterAccordion
            uniqueKey=""
            displayKey=""
            onSelectingFilter={() => {}}
            title="Stockist Code (Cmp)"
            arrayToFilter={[]}
            defaultValue={[]}
            isSingleSelect={true}
          />
          <Divider />
          <FilterAccordion
            uniqueKey="company_divisioncode"
            displayKey="division_name"
            onSelectingFilter={divisionFilterHandler}
            title="Division"
            arrayToFilter={divisionDetails}
            defaultValue={filters.division}
            isSingleSelect={true}
          />
          <Divider />
          <FilterAccordion
            uniqueKey=""
            displayKey=""
            onSelectingFilter={stockistFilterHandler}
            title="Counter Code"
            arrayToFilter={[]}
            defaultValue={[]}
            isSingleSelect={true}
          />
          <Divider />
          <FilterAccordion
            uniqueKey="fuid_status_id"
            displayKey="fuid_status_desc"
            title="Status"
            arrayToFilter={statusDetails}
            onSelectingFilter={statusFilterHandler}
            defaultValue={filters.status}
            isSingleSelect={true}
          />
          <Divider />
          <FilterAccordion
            uniqueKey=""
            displayKey=""
            title="Invoice no."
            arrayToFilter={[]}
            onSelectingFilter={() => {}}
            defaultValue={[]}
            isSingleSelect={true}
          />

          <>
            <Divider />
            <FilterAccordion
              displayKey=""
              title="Order no."
              arrayToFilter={[]}
              uniqueKey=""
              onSelectingFilter={() => {}}
              defaultValue={[]}
              isSingleSelect={true}
            />
          </>
          <>
            <Divider />
            <FilterAccordion
              displayKey="city_name"
              title="City"
              arrayToFilter={cityDetails}
              uniqueKey="city_code"
              onSelectingFilter={cityFilterHandler}
              defaultValue={filters.city}
              isSingleSelect={true}
            />
          </>
          <>
            <Divider />
            <FilterAccordion
              displayKey="district_name"
              title="District"
              arrayToFilter={districtDetails}
              uniqueKey="district_code"
              onSelectingFilter={districtFilterHandler}
              defaultValue={filters.district}
              isSingleSelect={true}
            />
          </>
          <>
            <Divider />
            <FilterAccordion
              displayKey="state_name"
              title="State"
              arrayToFilter={stateDetails}
              uniqueKey="state_code"
              onSelectingFilter={stateFilterHandler}
              defaultValue={filters.state}
              isSingleSelect={true}
            />
          </>
          <>
            <Divider />
            <FilterAccordion
              displayKey=""
              title="Date & Time"
              arrayToFilter={[]}
              uniqueKey=""
              onSelectingFilter={() => {}}
              defaultValue={[]}
              isSingleSelect={true}
            />
          </>
          <div className="my-4">
            <DayPicker
              classNames={{
                today: `border border-[#8051FF]`,
                selected: `bg-[#ded9ff] border-[#8051FF] font-semibold`, // Highlight the selected day
                root: `border border-[#D0D0D0] rounded-xl p-5 flex items-center justify-center text-[1rem]`, // Add a shadow to the root element
                day: `p-2 cursor-pointer`, // Base styles for each day
                chevron: `fill-[#7F56D9] hover:fill-[#8051FF] transition-colors`, // Change the color of the chevron
                outside: `text-gray-400`,
              }}
              mode="range"
              defaultMonth={selected?.from}
              selected={selected}
              onSelect={setSelected}
              numberOfMonths={1}
            />
          </div>
        </div>
      </aside>
    );
  }
);

export default InvoiceFiltersComponent;
