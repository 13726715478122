import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Spinner } from "src/components";
import { ordActions } from "src/context/order-slice";
import { RootState } from "src/context/store";
import { TimeLineChip } from "src/features/fields/components/TimeLineChip";
import { getOrderApprovalHierarchy } from "src/services/order-api";

const OrderInfoApprovalHierarchy = () => {
  const { approvalHierarchy } = useSelector((state: RootState) => state.ord);
  const { selectedCounterData, orderDetailsFiltered } = useSelector((state: RootState) => state.ord);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const order_id = orderDetailsFiltered?.[0]?.order_id_int;
  useEffect(() => {
    const fetchApprovalHierarchy = async () => {
      try {
        setIsLoading(true);
        const response = await getOrderApprovalHierarchy(order_id, selectedCounterData?.company_code);
        dispatch(ordActions.setApprovalHierarchy(response?.data?.data?.data));
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    dispatch(ordActions.setApprovalHierarchy([]));
    fetchApprovalHierarchy();
  }, [order_id]);

  return (
    <div className="w-full mt-6 mx-auto shadow-sm border border-[#D0D0D0] rounded-xl p-6 bg-white">
      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <Spinner />
        </div>
      ) : approvalHierarchy?.length && approvalHierarchy?.length > 0 ? (
        <div className="p-4 px-6 overflow-y-auto">
          {approvalHierarchy?.map((item: any) => {
            if (item?.operation_list?.length === 1 && item?.operation_list?.[0]?.toString()?.toLowerCase() === "viewer") return <></>;
            return (
              <Fragment key={item?.order_status_id}>
                <TimeLineChip
                  title={item?.ff_level}
                  name={item?.actor_name}
                  location={item?.actor_short_code}
                  dateTime={item?.modified_date}
                  status={item?.operation_status}
                  preq_status={item?.operation_status}
                />
              </Fragment>
            );
          })}
        </div>
      ) : (
        <h1 className="text-center text-gray-500 text-lg h-[50vh] flex items-center justify-center">No hierarchy found</h1>
      )}
    </div>
  );
};

export default OrderInfoApprovalHierarchy;
