import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment-timezone";
import * as yup from "yup";
import Badge from "../components/Badge";

const columnHelper = createColumnHelper();

export const getCraColumns = (onRedraftClick: (event: any, data: any) => void = () => {}, isLoadingImport: boolean = false) => {
  console.log("onRedraftClick", onRedraftClick);
  console.log("isLoadingImport", isLoadingImport);

  const columns = [
    columnHelper.accessor("counterRequestDetail.counter_request_code", {
      header: () => "Request ID",
      enableSorting: true,
    }),
    columnHelper.accessor("created_date", {
      header: () => "Request Date",
      enableSorting: true,
      cell: (info: any) => {
        return moment(info.getValue()).tz("Asia/Kolkata").format("DD MMM YYYY, HH:mm");
      },
    }),
    columnHelper.accessor("counterRequestDetail.company_division_code.division_name", {
      header: () => "Division",
      enableSorting: true,
    }),
    columnHelper.accessor("counterRequestDetail.drph_lnk_type.dr_ph_lnk_code", {
      header: () => "Counter Type",
      enableSorting: true,
    }),
    columnHelper.accessor("counterRequestDetail.counter_name", {
      header: () => "Doctor Name",
      enableSorting: true,
    }),
    columnHelper.accessor("counterRequestDetail.pharmacy_name", {
      header: () => "Pharmacy Name",
      enableSorting: true,
    }),
    columnHelper.accessor("counterRequestDetail.city_code.city_name", {
      header: () => "City",
      enableSorting: true,
      cell: (info: any) => {
        const { dr_ph_lnk_code } = info?.row?.original?.counterRequestDetail?.drph_lnk_type || {};

        const isPharmacy = ["INDPH-PH", "INDPH-HS"].includes(dr_ph_lnk_code);

        return isPharmacy ? info?.row?.original?.counterRequestDetail?.pharmacy_city_code?.city_name : info.getValue()?.toString()?.toUpperCase();
      },
    }),
    columnHelper.accessor("counterRequestDetail.city_code.district_code.state_code.state_name", {
      header: () => "State",
      enableSorting: true,
      cell: (info: any) => {
        const { dr_ph_lnk_code } = info?.row?.original?.counterRequestDetail?.drph_lnk_type || {};

        const isPharmacy = ["INDPH-PH", "INDPH-HS"].includes(dr_ph_lnk_code);

        return isPharmacy
          ? info?.row?.original?.counterRequestDetail?.pharmacy_city_code?.district_code?.state_code?.state_name
          : info.getValue()?.toString()?.toUpperCase();
      },
    }),
    columnHelper.accessor("counterRequestDetail.current_status", {
      header: () => "Status",
      enableSorting: true,
      cell: (info: any) => {
        const status = info.getValue();
        let valueToShow = "pending";
        let variant = "default";
        if (status?.toString()?.toLowerCase() === "pending") {
          variant = "warning";
          valueToShow = "Pending";
        } else if (status?.toString()?.toLowerCase() === "rejected") {
          variant = "error";
          valueToShow = "Rejected";
        } else if (status?.toString()?.toLowerCase() === "approval") {
          variant = "success";
          valueToShow = "Approved";
        } else {
          variant = "default";
          valueToShow = status.toString();
        }

        return (
          <Badge variant={variant} className="capitalize">
            {valueToShow}
          </Badge>
        );
      },
    }),
    columnHelper.accessor("counterRequestDetail.request_type", {
      header: () => "Request Type",
      enableSorting: true,
      cell: (info: any) => {
        const requestType = info.getValue();
        return requestType;
      },
    }),
  ];

  return columns;
};

export const getProductSchema = (isAllProducts: boolean) => {
  return yup.object().shape({
    // doctor_name: yup.string().required("Doctor Name is required"),
    company_divisioncode: yup.object().required("Division is required"),
    companyProduct: isAllProducts ? yup.object().notRequired().nullable() : yup.object().required("Item selection is required"),
    Period: yup.lazy((value) =>
      typeof value === "object" ? yup.object().required("Period type is required") : yup.string().required("Period type is required")
    ),
    PeriodLimit: yup.number().integer().required("Period limit is required"),
    Min_Order_Qty: yup.number().integer().required("Minimum Quantity is required").positive("Minimum Quantity must be greater than 0"),
    Max_Order_Qty: yup
      .number()
      .integer()
      .required("Maximum Quantity is required")
      .positive("Maximum Quantity must be greater than zero")
      .moreThan(yup.ref("Min_Order_Qty"), "Maximum Quantity must be greater than Minimum Quantity")
      .lessThan(yup.ref("PeriodLimit"), "Maximum Quantity must be less than Period Limit"),
    int_qty: isAllProducts
      ? yup.mixed().nullable().notRequired()
      : yup.number().notRequired().lessThan(yup.ref("Min_Order_Qty"), "Int Quantity must be less than Minimum Quantity"),
    reference_number: yup.string().required("Reference Number is required"),
    Priceldentifier_Id: yup.object().required("Discount selection is required"),
    DiscountOnPtr: yup
      .number()
      .nullable()
      .test("discount-on-ptr", "Discount on PTR is required", function (value) {
        const { Priceldentifier_Id } = this.parent;
        if (Priceldentifier_Id?.toString()?.toLowerCase() === "discount on ptr") {
          return value !== undefined && value !== null;
        }
        return true;
      }),
  });
};

export const addProductSchema = yup.object().shape({
  // doctor_name: yup.string().required("Doctor Name is required"),
  company_divisioncode: yup.object().required("Division is required"),
  companyProduct: yup.object().required("Item selection is required"),
  Period: yup.lazy((value) =>
    typeof value === "object" ? yup.object().required("Period type is required") : yup.string().required("Period type is required")
  ),
  PeriodLimit: yup.number().required("Period limit is required"),
  Min_Order_Qty: yup.number().required("Minimum Quantity is required").positive("Minimum Quantity must be greater than 0"),
  Max_Order_Qty: yup
    .number()
    .required("Maximum Quantity is required")
    .positive("Maximum Quantity must be greater than zero")
    .moreThan(yup.ref("Min_Order_Qty"), "Maximum Quantity must be greater than Minimum Quantity")
    .lessThan(yup.ref("PeriodLimit"), "Maximum Quantity must be less than Period Limit"),
  int_qty: yup.number().notRequired().lessThan(yup.ref("Min_Order_Qty"), "Int Quantity must be less than Minimum Quantity"),
  reference_number: yup.string().required("Reference Number is required"),
  Priceldentifier_Id: yup.object().required("Discount selection is required"),
  DiscountOnPtr: yup
    .number()
    .nullable()
    .test("discount-on-ptr", "Discount on PTR is required", function (value) {
      const { Priceldentifier_Id } = this.parent;
      if (Priceldentifier_Id?.toString()?.toLowerCase() === "discount on ptr") {
        return value !== undefined && value !== null;
      }
      return true;
    }),
});
