import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface OrdState {
  counterData: any | null | undefined | any[];
  productsData: any | null | undefined | any[];
  counterLocationData: any | null | undefined | any[];
  selectedLocation: any | null | undefined | any[];
  selectedCounter: any | null | undefined | any[];
  cartDataObj: any;
  orderPayload: any | null | undefined | any[];
  orderDetails: any;
  selectedCounterData: any | null | undefined | any[];
  approvalHierarchy: any[] | null | undefined | any[];
  orderDetailsFiltered: any | null | undefined | any[];
  pendingOrder: any[] | null | undefined | any[];
  OrderFilters: {
    division: any[] | undefined;
    company: any[] | undefined;
    status: any[] | undefined;
    stockist: any[] | undefined;
  };
  OrdersFiltersData: {
    division: any[] | undefined;
    company: any[] | undefined;
    status: any[] | undefined;
    stockist: any[] | undefined;
  };
  isResetFilter: boolean;
  eventStatus: {
    blocked: boolean;
    pending: boolean;
  };
}

const ordSlice = createSlice({
  name: "ord",
  initialState: {
    counterData: null,
    productsData: null,
    counterLocationData: null,
    selectedLocation: null,
    selectedCounter: null,
    cartData: null,
    orderPayload: null,
    cartDataObj: {},
    orderDetails: null,
    selectedCounterData: null,
    approvalHierarchy: null,
    orderDetailsFiltered: null,
    pendingOrder: null,
    OrderFilters: {
      division: [],
      company: [],
      stockist: [],
      status: [],
    },
    isResetFilter: false,
    OrdersFiltersData: {
      division: [],
      company: [],
      status: [],
      stockist: [],
    },
    eventStatus: {
      blocked: false,
      pending: false,
    },
  } as OrdState,
  reducers: {
    setCounterData(state, action: PayloadAction<any[] | null | undefined>): void {
      state.counterData = action.payload;
    },
    setSelectedCounter(state, action: PayloadAction<any[] | null | undefined>): void {
      state.selectedCounter = action.payload;
    },
    setCounterLocationData(state, action: PayloadAction<any[] | null | undefined>): void {
      state.counterLocationData = action.payload;
    },
    setSelectedLocation(state, action: PayloadAction<any[] | null | undefined>): void {
      state.selectedLocation = action.payload;
    },
    setProductsData(state, action: PayloadAction<any[] | null | undefined>): void {
      state.productsData = action.payload;
    },
    setCartDataObj(state, action: PayloadAction<any | null | undefined>): void {
      const { key, value } = action.payload;
      state.cartDataObj[key] = value;
    },
    setOrderPayload(state, action: PayloadAction<any[] | null | undefined>): void {
      state.orderPayload = action.payload;
    },
    removeCartDataObj(state, action: PayloadAction<any | null | undefined>): void {
      const { key } = action.payload;
      delete state.cartDataObj[key];
    },
    clearCartDataObj(state): void {
      state.cartDataObj = {};
    },
    setOrderDetails(state, action: PayloadAction<any | null | undefined>): void {
      state.orderDetails = action.payload;
    },
    setSelectedCounterData(state, action: PayloadAction<any | null | undefined>): void {
      state.selectedCounterData = action.payload;
    },
    setApprovalHierarchy(state, action: PayloadAction<any | null | undefined>): void {
      state.approvalHierarchy = action.payload;
    },
    setOrderDetailsFiltered(state, action: PayloadAction<any | null | undefined>): void {
      state.orderDetailsFiltered = action.payload;
    },
    setPendingOrder(state, action: PayloadAction<any | null | undefined>): void {
      state.pendingOrder = action.payload;
    },
    setOrderFilters(state, action: PayloadAction<{ key: keyof typeof state.OrderFilters; value: any[] | undefined }>) {
      const { key, value } = action.payload;
      state.OrderFilters[key] = value;
    },
    setOrdersFiltersData(state, action: PayloadAction<{ key: keyof typeof state.OrdersFiltersData; value: any[] | undefined }>) {
      const { key, value } = action.payload;
      state.OrdersFiltersData[key] = value;
    },
    setIsResetFilter(state, action: PayloadAction<boolean>) {
      state.isResetFilter = action.payload;
    },
    setEventPending(state, action: PayloadAction<boolean>) {
      state.eventStatus.pending = action.payload;
    },
    setEventBlocked(state, action: PayloadAction<boolean>) {
      state.eventStatus.blocked = action.payload;
    },
  },
});

export const ordActions = ordSlice.actions;
export { ordSlice };
