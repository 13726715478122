import { Button, Input, MultiAutocomplete, Spinner } from "src/components";
import { useEffect, useState } from "react";
import downArrow from "src/assets/down-arrow.svg";
import saveFile from "src/features/fields/assets/saveFile.svg";
import send from "src/features/fields/assets/send.svg";
import cross from "src/features/fields/assets/cross.svg";
import star from "src/features/fields/assets/star.svg";
import { editInvoiceProduct, getInvoiceDetails, getSimilarOrders, invoiceProductDelete } from "src/services/invoice-api";
import { invoiceActions } from "src/context/invoice-slice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InvoiceDetailCard from "./invoice-detail.component";
import { getInvoiceDetailsInfo } from "../invoice-master/invoice-helper";
import { RootState } from "src/context/store";
import { useSelector } from "react-redux";
import { useToast } from "src/hooks/useToast";
import PurpleLeftArrowIcon from "../../assets/purple-left-arrow.svg";
import InvoiceLayout from "../invoice-master/invoice-layout.component";
import DeleteIcon from "../../assets/delete.svg";
import { useEventStatus } from "src/hooks/useEventStatus";
import SuccessIcon from "src/features/fields/assets/successCircle.svg";

const DEFAULT_TABLE_DATA = [
  {
    type: "Quantity",
    invoiceData: "",
    orderedData: "",
    modifiedData: "",
  },
  {
    type: "Discount %",
    invoiceData: "",
    orderedData: "",
    modifiedData: "",
    discountValue: "",
  },
  {
    type: "Cash Discount",
    invoiceData: "",
    orderedData: "",
    modifiedData: "",
  },
  {
    type: "PTR",
    invoiceData: "",
    orderedData: "",
    modifiedData: "",
  },
  {
    type: "MRP",
    invoiceData: "",
    orderedData: "",
    modifiedData: "",
  },
  {
    type: "Price",
    invoiceData: "",
    orderedData: "",
    modifiedData: "",
  },
];

export const InvoiceApprovalScreen = () => {
  const { stockist_invoice_attachment_id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { invoiceApprovalData } = useSelector((state: RootState) => state.invoice);
  const [mappedData, setMappedData] = useState<any[]>([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState<any>(null);
  const [tableData, setTableData] = useState(DEFAULT_TABLE_DATA);
  const { toast } = useToast();
  const [productsData, setProductsData] = useState<any[]>(invoiceApprovalData?.invoice?.invoiceProducts);
  const [similarOrders, setSimilarOrders] = useState<any[]>([]);
  const [tcs, setTcs] = useState<number>(0);
  const [selectedOrders, setSelectedOrders] = useState<any[]>([]);
  const navigate = useNavigate();

  // const [cashDiscountAmount, setCashDiscountAmount] = useState(0);
  const { withEventCheck } = useEventStatus();

  const fetchInvoiceDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getInvoiceDetails(stockist_invoice_attachment_id as string, false);
      dispatch(invoiceActions.setInvoiceApprovalData(response?.data?.data));
      setProductsData(response?.data?.data?.invoice?.invoiceProducts);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateTotalCashDiscountPercentage = () => {
    const totalCashDiscount = calculateTotalCashDiscount();
    const totalSubtotal = calculateSubtotal();
    return (totalCashDiscount / totalSubtotal) * 100;
  };

  const calculateTotalCashDiscount = () => {
    let total = 0;
    mappedData?.forEach((item: any) => {
      const { gross_amt, discount_perc, cash_discount } = item;
      const afterItemDiscount = Number(gross_amt) - (Number(gross_amt) * Number(discount_perc)) / 100;
      const cashDiscountAmount = (afterItemDiscount * Number(cash_discount)) / 100;
      total += cashDiscountAmount;
    });
    return total;
  };

  // const subtotal = mappedData?.reduce((acc: number, item: any) => acc + Number(item?.quantity) * Number(item?.ptr), 0);
  const calculateSubtotal = () => {
    let total = 0;
    mappedData?.forEach((item: any) => {
      const { gross_amt, discount_perc } = item;
      const cashDiscountAmount = (gross_amt * Number(discount_perc)) / 100;
      total += gross_amt - cashDiscountAmount;
    });
    return total;
  };

  const calculateTotalGst = () => {
    let total = 0;
    mappedData?.forEach((item: any) => {
      const { gross_amt, p_gst_perc, discount_perc, cash_discount } = item;
      // Calculate GST on amount after discount
      let amountAfterItemDiscount = Number(gross_amt) - (Number(gross_amt) * Number(discount_perc)) / 100;
      let amountAfterCashDiscount = amountAfterItemDiscount - (amountAfterItemDiscount * Number(cash_discount)) / 100;

      const gstAmount = (amountAfterCashDiscount * Number(p_gst_perc)) / 100;
      total += gstAmount;
    });
    return total;
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const amountAfterCashDiscount = subtotal - calculateTotalCashDiscount();
    const totalGst = calculateTotalGst();
    const tcsCalculatedAmount = amountAfterCashDiscount + totalGst + ((amountAfterCashDiscount + totalGst) * tcs) / 100;
    // return amountAfterCashDiscount + totalGst;
    return tcsCalculatedAmount;
  };

  const handleViewDetails = async (item: any) => {
    setSelectedOrders([]);
    setMappedData((prev) => prev.filter((mappedItem) => mappedItem.item_code !== item.item_code));
    try {
      const response = await getSimilarOrders({
        item_code: item?.item_code,
        inv_mrp: item?.inv_mrp,
        counter_code: invoiceApprovalData?.counter_code,
        company_stockist_code: invoiceApprovalData?.company_stockist_code,
      });

      toast({
        title: "Data Fetched Successfully",
        description: "Data has been fetched successfully.",
      });

      setSimilarOrders(response?.data?.data);
      setSelectedItemDetails(item);
    } catch (error: any) {
      console.log(error);
      toast({
        title: "Error",
        description: "Error fetching data",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    if (!selectedItemDetails) return;
    const newTableData = [
      {
        type: "Quantity",
        invoiceData: selectedItemDetails?.quantity,
        orderedData: String(selectedOrders?.reduce((acc: number, curr: any) => acc + Number(curr?.quantity), 0)),
        modifiedData: selectedItemDetails?.quantity,
      },
      {
        type: "Discount %",
        invoiceData: String((selectedItemDetails?.discount_amt / selectedItemDetails?.gross_amt) * 100),
        orderedData: (() => {
          switch (selectedOrders?.[0]?.price_identifier) {
            case 1:
              return selectedOrders?.[0]?.discount_on_ptr;
            case 2:
              return "Scheme : " + selectedOrders?.[0]?.scheme_quantity + " + " + selectedOrders?.[0]?.scheme_free_quantity;
            case 3:
              return "Fixed Price : " + selectedOrders?.[0]?.fixed_price;
          }
        })(),

        discountValue:
          selectedItemDetails?.price_identifier === 1
            ? selectedItemDetails?.discount_on_ptr
            : selectedItemDetails?.price_identifier === 2
              ? selectedItemDetails?.scheme_quantity + "+" + selectedItemDetails?.scheme_free_quantity
              : selectedItemDetails?.fixed_price,

        modifiedData: String((selectedItemDetails?.discount_amt / selectedItemDetails?.gross_amt) * 100),
      },
      {
        type: "Cash Discount",
        invoiceData: selectedItemDetails?.p_cash_discount_perc || 0,
        orderedData: selectedOrders?.[0]?.cash_discount_perc || 0,
        modifiedData: selectedItemDetails?.p_cash_discount_perc || 0,
        // modifiedData: data?.item_discount,
      },
      {
        type: "PTR",
        invoiceData: selectedItemDetails?.ptr,
        orderedData: selectedOrders?.[0]?.ptr,
        modifiedData: selectedItemDetails?.ptr,
      },
      {
        type: "MRP",
        invoiceData: selectedItemDetails?.inv_mrp,
        orderedData: selectedOrders?.[0]?.mrp,
        modifiedData: selectedItemDetails?.inv_mrp,
      },
      {
        type: "Price",
        invoiceData: selectedItemDetails?.inv_rate,
        orderedData: selectedOrders?.[0]?.price,
        modifiedData: selectedItemDetails?.inv_rate,
      },
    ];
    setTableData(newTableData);
  }, [selectedItemDetails, selectedOrders]);

  const handleOrderChange = (value: any, type: string) => {
    const valueArray = Array.isArray(value) ? value : [value];

    switch (type) {
      case "add": {
        setSelectedOrders((prev: any[]) => {
          const newSelection = valueArray.filter((item: any) => !prev.some((prevItem: any) => prevItem.id === item.id));
          return [...prev, ...newSelection];
        });

        break;
      }

      case "remove": {
        setSelectedOrders((prev: any[]) => prev.filter((item) => !valueArray.some((removeItem: any) => removeItem.id === item.id)));

        break;
      }
    }
  };

  console.log("selectedOrders", selectedOrders);
  console.log("mappedData", mappedData);

  const handleModifyData = (index: number, value: string) => {
    const updatedTableData = [...tableData];
    if (tableData[index].type === "Quantity") {
      const orderedQuantity = Number(tableData[index].orderedData);
      const newQuantity = Number(value);

      if (newQuantity > orderedQuantity) {
        toast({
          description: `Modified quantity cannot be greater than remaining quantity ${orderedQuantity}`,
          variant: "destructive",
        });
        return;
      }

      // If quantity is negative or not a number, show error
      if (isNaN(newQuantity) || newQuantity < 0) {
        toast({
          title: "Invalid Quantity",
          description: "Please enter a valid positive number",
          variant: "destructive",
        });
        return;
      }
    }

    // if (tableData[index].type == "Cash Discount") {
    //   const orderedDiscount = Number(tableData[index].orderedData);
    //   const newDiscount = Number(value);

    //   if (newDiscount > orderedDiscount) {
    //     toast({
    //       description: `Modified discount cannot be greater than ordered discount ${orderedDiscount}`,
    //       variant: "destructive",
    //     });
    //     return;
    //   }
    // }

    if (tableData[index].type == "Discount %") {
      const orderedDiscountType = Number(tableData[index].discountValue);
      const newDiscountType = Number(value);
      if (newDiscountType > orderedDiscountType) {
        toast({
          description: `Modified discount type cannot be greater than ordered discount type ${orderedDiscountType}`,
          variant: "destructive",
        });
        return;
      }
    }

    updatedTableData[index].modifiedData = value;
    setTableData(updatedTableData);
  };

  const handleSaveModifiedData = () => {
    if (!selectedItemDetails) return;

    if (selectedOrders?.length == 0) {
      toast({ description: "Choose an order before submission", variant: "destructive" });
      return;
    }

    const orderCodes = selectedOrders?.map((item: any) => item?.id);

    const modifiedItem = {
      ...selectedItemDetails,
      quantity: Number(tableData.find((item) => item.type === "Quantity")?.modifiedData),
      discount_perc: tableData.find((item) => item.type === "Discount %")?.modifiedData,
      cash_discount: tableData.find((item) => item.type === "Cash Discount")?.modifiedData,
      ptr: tableData.find((item) => item.type === "PTR")?.modifiedData,
      inv_mrp: tableData.find((item) => item.type === "MRP")?.modifiedData,
      gross_amt:
        Number(tableData.find((item) => item.type === "PTR")?.modifiedData) *
        Number(tableData.find((item) => item.type === "Quantity")?.modifiedData),
      invoice_id: invoiceApprovalData?.invoice?.invoice_id,
      company_stockist_code: String(invoiceApprovalData?.company_stockist_code),
      counter_code: invoiceApprovalData?.counter_code,
      order_code: orderCodes,
      p_cash_discount_perc: Number(tableData.find((item) => item.type === "Cash Discount")?.modifiedData),
    };

    // Check if item with same item_code already exists
    const itemExists = mappedData.some((item) => item.item_code === modifiedItem.item_code);

    if (itemExists) {
      toast({
        title: "Duplicate Item",
        description: "This item has already been mapped.",
        variant: "destructive",
      });
      return;
    }

    setProductsData((prev) => prev.map((item) => (item.item_code === modifiedItem.item_code ? { ...item, mapped: true } : item)));
    setMappedData((prev) => [...prev, modifiedItem]);

    console.log({ mappedData });

    setSelectedItemDetails(null);
    setTableData(DEFAULT_TABLE_DATA);
    setSelectedOrders([]);

    toast({
      title: "Data Mapped Successfully",
      description: "Item has been added to mapped data.",
    });
  };

  const handleReset = () => {
    setSelectedOrders([]);
    setProductsData(invoiceApprovalData?.invoice?.invoiceProducts);
    setMappedData([]);
    setTableData(DEFAULT_TABLE_DATA);
    setSelectedItemDetails(null);
    toast({
      title: "Data Reset Successfully",
      description: "All data has been reset.",
    });
  };

  const handleApproveInvoice = async () => {
    if (mappedData.length == 0) {
      toast({ description: "Please add products", variant: "default" });
      return;
    }

    const payload = {
      data: mappedData,
      primary_key: invoiceApprovalData?.invoice?.invoice_id,
      event_type: "INVOICE_PRODUCT_EDIT",
    };

    try {
      const response = await editInvoiceProduct(payload);
      console.log(response);
      toast({ description: "Invoice approved successfully", variant: "default" });
      navigate("/transactions/invoice");
    } catch (error: any) {
      console.log(error);
      toast({ description: error?.response?.data?.message, variant: "destructive" });
    }
  };

  const handleProductDelete = async (product: any) => {
    const payload = {
      data: {
        invoice_product_id: product?.invoice_product_id,
        type: "Approval",
      },
      primary_key: invoiceApprovalData?.invoice?.invoice_id,
      event_type: "INVOICE_PRODUCT_DELETE",
    };
    try {
      const res = await invoiceProductDelete(payload);
      const response = await withEventCheck(
        () => {
          return getInvoiceDetails(stockist_invoice_attachment_id as string, false);
        },
        res?.data?.data?.[0]?.eventStreamCreated?.streamId
      );
      dispatch(invoiceActions.setInvoiceApprovalData(response?.data?.data));
      toast({ description: "Product deleted successfully", variant: "default" });
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchInvoiceDetails();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <InvoiceLayout>
      <div className="mx-auto mt-10">
        <div className="flex justify-between px-[3.75rem] mt-6  pb-12 shadow-md border-b-[1px] border-b-[#E9E9E9]">
          <div className="flex items-center flex-col">
            <Button className="flex items-center px-0 py-2 text-lg text-[#7F56D9]" onClick={() => navigate("/transactions/invoice")}>
              <img src={PurpleLeftArrowIcon} alt="back" className="w-3 h-3 mr-3" />
              Back to Invoices
            </Button>
            <h1 className="text-[#49484C] font-bold text-[2rem]">Invoices</h1>
          </div>

          <div className="flex items-center gap-2">
            <Input placeholder="Search" className="px-3 h-[32px] w-[180px] rounded-lg " showIcon={true} />
          </div>
        </div>
        <div className="p-5 mt-10 mx-[3.75rem] rounded-xl border border-[#d0d0d0] shadow-sm">
          <header className="w-full">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <img src={star} className="p-1 mr-2 w-8 h-8 rounded-lg border border-[#d0d0d0] shadow-sm" alt="star" />
                <h1 className={"font-semibold text-lg text-[1.5rem] mr-4"}>{invoiceApprovalData?.fuid_no}</h1>
                <h1 className={`font-semibold  flex items-center text-xs h-6 px-2 rounded-full ${"bg-[#FFF1E3] text-[#5E4200]"}`}>
                  {invoiceApprovalData?.fileStatusHdr?.fuid_status_desc}
                  <img className="ml-1 w-3 h-3 cursor-pointer" src={downArrow} alt="down-arrow" />
                </h1>
              </div>
              <div className="flex gap-x-1.5">
                <Button className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600">
                  <img src={saveFile} className="mr-1 w-4 h-4" alt="save-file" />
                  Save{" "}
                </Button>
                <Button className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600">
                  <img src={send} className="mr-1 w-4 h-4" alt="send" />
                  Send{" "}
                </Button>
                <Button className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600">
                  <img src={cross} className="mr-1 w-4 h-4 invert" alt="cross" />
                  Clear{" "}
                </Button>
              </div>
            </div>
          </header>

          <ul className="mt-5">
            <li className="my-5 mb-10 rounded-lg">
              <InvoiceDetailCard
                isExpandedInitial={true}
                isExpandable={false}
                status={invoiceApprovalData?.fileStatusHdr?.fuid_status_desc}
                checkDataType={false}
                data={getInvoiceDetailsInfo(invoiceApprovalData)}
                viewDetails={false}
                invoiceData={invoiceApprovalData}
                showHeader={false}
                fetchInvoiceDetails={fetchInvoiceDetails}
              />
            </li>
          </ul>

          <div className="mt-10 mb-10 rounded-lg px-4">
            <div className="flex justify-between items-center pb-5 border-b-2 border-gray-200">
              <p className="mb-2 text-lg font-semibold">Invoice Details</p>
            </div>

            <table className="mt-5 min-w-full border border-gray-200">
              <thead className="w-full bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Item code</th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Item description</th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. MRP</th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. PTR</th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Qty.</th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Gross Amt.</th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Scheme</th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Discount %</th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Discount Amount</th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">GST % </th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Batch</th>
                  <th className="px-4 py-2 w-36 text-sm text-left text-gray-600">Order Map</th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Action</th>
                  <th className="px-4 py-2 text-sm text-left text-gray-600">Mapped</th>
                </tr>
              </thead>
              <tbody>
                {productsData?.map((item: any, index: any) => (
                  <tr key={index} className={`${index % 2 === 0 ? "bg-gray-50 text-sm" : "text-sm"}`}>
                    <td className="px-4 py-2 border-t">{item?.item_code}</td>
                    <td className="px-4 py-2 border-t">{item?.item_description}</td>
                    <td className="px-4 py-2 border-t">{item?.inv_mrp}</td>
                    <td className="px-4 py-2 border-t">{item?.ptr}</td>
                    <td className="px-4 py-2 border-t">{item?.quantity}</td>
                    <td className="px-4 py-2 border-t">{item?.gross_amt}</td>
                    <td className="px-4 py-2 border-t">{item?.price_identifier}</td>
                    <td className="px-4 py-2 border-t">{((item?.discount_amt / item?.gross_amt) * 100).toFixed(2)}</td>
                    <td className="px-4 py-2 border-t">{item?.discount_amt}</td>
                    <td className="px-4 py-2 border-t">{item?.p_gst_perc}</td>
                    <td className="px-4 py-2 border-t">{item?.batch_no}</td>
                    <td className="px-4 py-2 border-t">
                      <Button onClick={() => handleViewDetails(item)} className="w-full h-8 font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0]">
                        View Details
                      </Button>
                    </td>
                    <td className="px-4 py-2 border-t">
                      <Button onClick={() => handleProductDelete(item)}>
                        <img src={DeleteIcon} alt="delete" className="w-4 h-4" />
                      </Button>
                    </td>
                    {
                      <td className="px-4 py-2 border-t">
                        {item?.mapped && (
                          <Button>
                            <img src={SuccessIcon} alt="counter_details_check" className="" />
                          </Button>
                        )}
                      </td>
                    }
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-10 border border-[#d0d0d0] p-5 rounded-xl mx-4">
            <div className="flex justify-between items-center">
              <p className="text-lg font-semibold">{selectedItemDetails?.item_description || "Select an Item"}</p>
              <MultiAutocomplete
                inputClassName="w-full"
                placeholder="Choose an order"
                suggestionList={similarOrders?.map((item: any) => ({
                  id: item?.order?.order_code,
                  ...item,
                }))}
                controlledValue={selectedOrders || []}
                uniqueKey="id"
                displayKey="id"
                onSelectionChange={handleOrderChange}
              />
            </div>

            <table className="mt-5 min-w-full border border-gray-200">
              <thead className="w-full bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-sm font-normal text-left text-gray-600"></th>
                  <th className="px-4 py-2 text-sm font-normal text-left text-gray-600">Invoice Data</th>
                  <th className="px-4 py-2 text-sm font-normal text-left text-gray-600">Ordered Data</th>
                  <th className="px-4 py-2 text-sm font-normal text-left text-gray-600">Modified Data</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => (
                  <tr key={index} className="h-12 text-sm">
                    <td className="px-4 py-2 text-gray-500 border-t">{item?.type}</td>
                    <td className="px-4 py-2 border-t">{Number(item?.invoiceData).toFixed(2)}</td>
                    <td className="px-4 py-2 border-t">{item?.orderedData}</td>
                    <td className="px-4 py-2 border-t">
                      <input
                        disabled={item.type === "MRP" || item.type === "Discount %" || item.type === "PTR"}
                        type="text"
                        value={item.modifiedData}
                        onChange={(e) => handleModifyData(index, e.target.value)}
                        className="px-2 py-1 w-full rounded-md border border-1-black"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-end">
              {selectedItemDetails && (
                <Button onClick={handleSaveModifiedData} className="mt-4 h-9 font-normal text-white bg-[#8051FF] shadow-sm">
                  Save Modified Data
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="mt-10 border mx-[3.75rem] border-[#d0d0d0] p-5 rounded-xl px-10">
          <div className="flex justify-between items-center pb-5">
            <p className="text-lg font-semibold">Final Mapped Data</p>
            {/* <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
            <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
          </Button> */}
            <Button
              className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>

          <table className="min-w-full border border-gray-200">
            <thead className="w-full bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Item code</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Item description</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. MRP</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. PTR</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Qty.</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Gross Amt.</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Scheme</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Discount %</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Cash Discount %</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">GST % </th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Batch</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Action</th>
              </tr>
            </thead>
            <tbody>
              {mappedData?.map((item: any, index: any) => (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-50 text-sm h-12" : "text-sm h-12"}>
                  <td className="px-4 py-2 border-t">{item?.item_code}</td>
                  <td className="px-4 py-2 border-t">{item?.item_description}</td>
                  <td className="px-4 py-2 border-t">{item?.inv_mrp}</td>
                  <td className="px-4 py-2 border-t">{item?.ptr}</td>
                  <td className="px-4 py-2 border-t">{item?.quantity}</td>
                  <td className="px-4 py-2 border-t">{Number(item?.ptr * item?.quantity).toFixed(2)}</td>
                  <td className="px-4 py-2 border-t">{item?.price_identifier}</td>
                  <td className="px-4 py-2 border-t">{Number(item?.discount_perc).toFixed(2)}</td>
                  <td className="px-4 py-2 border-t">{item?.cash_discount}</td>
                  <td className="px-4 py-2 border-t">{item?.p_gst_perc || 0}</td>
                  <td className="px-4 py-2 border-t">{item?.batch_no}</td>
                  <td className="px-4 py-2 border-t">
                    <Button
                      className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600"
                      onClick={() => handleViewDetails(item)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="p-6 mt-10 rounded-xl border border-[#d0d0d0] w-[360px] shadow-sm mx-[3.75rem]">
          <p className="mb-2 text-lg font-semibold">Calculations</p>
          <div className="flex flex-col gap-y-1 pb-10">
            <p className="flex justify-between py-2 text-sm">
              Subtotal <span className="">₹ {Number(calculateSubtotal().toFixed(2))}</span>
            </p>
            <p className="flex justify-between py-2 text-sm">
              <span className="mr-2">Cash Discount %</span>
              <div className="">
                <Input
                  type="number"
                  enableArrows={false}
                  showIcon={false}
                  disabled={true}
                  value={calculateTotalCashDiscountPercentage()?.toFixed(2)}
                  className="w-24 py-1 text-right border border-gray-300 rounded-lg"
                />
              </div>
            </p>
            <p className="flex justify-between py-2 text-sm">
              <span className="mr-2">Cash Discount Amount</span>
              <div className="">
                <Input
                  type="number"
                  enableArrows={false}
                  showIcon={false}
                  disabled={true}
                  value={calculateTotalCashDiscount()?.toFixed(2)}
                  className="w-24 py-1 text-right border border-gray-300 rounded-lg"
                />
              </div>
            </p>
            <p className="flex justify-between py-2 text-sm">
              GST Amount<span className="font-semibold">₹{calculateTotalGst()?.toFixed(2)}</span>
            </p>
            <p className="flex justify-between py-2 text-sm">
              <span className="mr-2">TCS %</span>
              <div className="">
                <Input
                  enableArrows={false}
                  showIcon={false}
                  type="number"
                  placeholder="TCS"
                  value={tcs}
                  onChange={(e: any) => setTcs(e?.target?.value)}
                  className="w-24 py-1 text-right border border-gray-300 rounded-lg"
                />{" "}
              </div>
            </p>
            <p className="flex justify-between py-2 text-sm">
              Total (as calculated) <span className="font-semibold">₹ {calculateTotal().toFixed(2)}</span>
            </p>
            {/* <p className="flex justify-between py-2 text-sm">
              Net Inv. Amt. <span className="">₹ {calculateSubtotal().toFixed(2)}</span>
            </p> */}
            <Button className="w-full h-9 mt-5 font-normal text-white bg-[#8051FF] shadow-sm" onClick={handleApproveInvoice}>
              Approve Invoice
            </Button>
          </div>
        </div>
      </div>
    </InvoiceLayout>
  );
};
