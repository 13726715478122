import { useEffect, useState } from "react";
import Button from "src/components/ui/button.component";
import downArrow from "src/assets/down-arrow.svg";
import cross from "src/features/fields/assets/cross.svg";
import receipt from "src/features/fields/assets/receipt.svg";

const invoiceData = {
  "Uploaded by": "Dr. Shreevansh Kumar",
  "Uploaded on": "21 June 2024, 11:30AM",
  "Invoice No.": "#123-456",
  "Invoice Date": "21 June 2024",
  Type: "Image / Screen",
  "Acknowledged on": "21 June 2024, 11:30AM",
  "Acknowledged by": "Arun Maitley",
  "Verified on": "21 June 2024, 11:30AM",
  "Verified by": "Medvol Team",
};

const InvoiceAcknowledgement = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true); // Start the animation
    } else {
      const timer = setTimeout(() => setIsAnimating(false), 300); // Wait for closing animation to finish
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <div
      className={`fixed z-10 top-0 right-0 h-screen w-[30%] bg-white shadow-sm border border-[#D0D0D0] transform transition-transform duration-500 ${
        isAnimating ? (isOpen ? "translate-x-0" : "translate-x-full") : "translate-x-full"
      }`}
    >
      <Button onClick={onClose} className="absolute top-2 right-2">
        <img src={cross} alt="cross" className="invert" />
      </Button>
      <div className="p-4">
        <h1 className="text-[1rem] font-semibold">Invoice Acknowledgement</h1>
        <div className="border-t mt-6 border-[#d0d0d0]"></div>
        <div className="flex items-center mt-6 text-sm">
          <img src={receipt} alt="receipt" className="inline w-5 h-5 transform scale-x-[-1] mr-1" />
          <h1 className="mr-4 text-[1rem] font-semibold">Invoice #123-456</h1>
          <h1 className="font-semibold  flex items-center text-xs h-6 px-2 rounded-full bg-[#cdfee1] text-[#0C5132]">
            Accepted
            <img className="ml-1 w-3 h-3" src={downArrow} alt="down-arrow" />
          </h1>
        </div>
      </div>
      <div className="flex gap-2 justify-end p-4">
        <Button className="font-normal bg-[#F1F1F1] h-8 border shadow-sm border-[#D0D0D0]">View</Button>
        <Button className="font-normal text-white bg-[#8051FF] h-8">Download</Button>
      </div>
      <div className="overflow-y-auto h-screen scrollbar-hide">
        <div className="p-4 mt-4 w-full bg-white rounded-lg shadow-sm">
          {Object.entries(invoiceData).map(([key, value]) => (
            <div key={key} className="flex mb-2 space-x-2 space-y-2 w-full">
              <span className="text-sm text-[#616161] w-1/3">{key} : </span>
              <span className="w-2/3 text-sm">{value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InvoiceAcknowledgement;
