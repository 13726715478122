import { Fragment, useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { Tab } from "@headlessui/react";
import { useSelector } from "react-redux";
import { RootState } from "src/context/store";
import { Button } from "src/components";
import PurpleLeftArrowIcon from "../../assets/purple-left-arrow.svg";
import OrderDetailsTab from "../OrderDetailsTab/OrderDetailsTab";
import { OrderInfoHomeScreen } from "./OrderInfoHomeScreen";
import { getCounterByEmployee, getCounterOrderDetailsById, getOrderIds } from "src/services/order-api";
import { ordActions } from "src/context/order-slice";
import { useEventStatus } from "src/hooks/useEventStatus";
import OrderInfoFuid from "./OrderInfoFuid";
import OrderInfoInvoices from "./OrderInfoInvoices";
import OrderInfoApprovalHierarchy from "./OrderInfoApprovalHierarchy";
import OrderStatusTooltip from "../OrderDetailsTab/OrderStatusTooltip";

const tabs = ["Order Info", "Order Details", "FUID", "Invoices", "Approval Hierarchy"];

const OrderInfoScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedCounterData, orderDetailsFiltered } = useSelector((state: RootState) => state.ord);
  const { tabIndex } = useParams();

  const { withEventCheck } = useEventStatus();

  const fetchDetails = useCallback(async () => {
    setIsLoading(true);
    const counterOrderId = window.sessionStorage.getItem("counterOrderId");

    if (counterOrderId) {
      let res = await getCounterOrderDetailsById(counterOrderId);
      if (res?.status !== 200) throw new Error();
      dispatch(ordActions.setCounterLocationData(res?.data));
    } else {
      navigate("/transactions/orders");
    }
    setIsLoading(false);
  }, []);

  const fetchOrderId = async (counter_company_location_id: number, company_code: string) => {
    const order_id_int = window.sessionStorage.getItem("order_id_int") ? Number(window.sessionStorage.getItem("order_id_int")) : null;
    const res = await getOrderIds(counter_company_location_id, company_code, 1, 10, order_id_int, Number(tabIndex) == 0 ? "true" : "");
    dispatch(ordActions.setOrderDetailsFiltered(res?.data?.data));
  };

  const fetchCounterData = async (counter_company_location_id: number) => {
    const query = `?counter_company_location_id=${counter_company_location_id}&stockist=true`;

    try {
      const order_id_int = window.sessionStorage.getItem("order_id_int") ? Number(window.sessionStorage.getItem("order_id_int")) : null;
      const response = await withEventCheck(async () => await getCounterByEmployee(query), `order_${order_id_int}`);
      dispatch(ordActions.setSelectedCounterData(response?.data?.data?.[0]));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(ordActions.setProductsData([]));
    dispatch(ordActions.clearCartDataObj());

    fetchDetails();
    if (!selectedCounterData || Object.keys(selectedCounterData)?.length === 0) {
      fetchCounterData(Number(window.sessionStorage.getItem("counterCompanyLocationId")));
    }
  }, []);

  useEffect(() => {
    if (selectedCounterData && Object.keys(selectedCounterData)?.length > 0) {
      fetchOrderId(selectedCounterData?.counter_company_location_id, selectedCounterData?.company_code);
    }
  }, [selectedCounterData?.company_code]);

  return (
    <div>
      <div className="h-screen min-h-screen">
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <Loader />
          </div>
        ) : (
          <div className="h-full bg-white">
            {/* Header Section */}
            {/* <div className="p-6 border-b border-purple-200 bg-gradient-to-r from-purple-50 to-purple-100"> */}
            <div className="mx-16 mt-6">
              <div className="container">
                <Button className="flex items-center px-0 py-2 text-lg text-[#7F56D9]" onClick={() => navigate("/transactions/orders")}>
                  <img src={PurpleLeftArrowIcon} alt="back" className="w-3 h-3 mr-3" />
                  Back to Counters
                </Button>

                <div className="flex items-center justify-between">
                  <div>
                    <h1 className="text-[2rem] font-bold text-gray-800">{orderDetailsFiltered?.[0]?.order_code}</h1>
                    <OrderStatusTooltip
                      orderStatusCode={orderDetailsFiltered?.[0]?.orderStatusHdr?.order_status_id}
                      orderStatusText={orderDetailsFiltered?.[0]?.orderStatusHdr?.order_status_desc}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Tab.Group defaultIndex={Number(tabIndex) ?? 0}>
              <Tab.List className="border-b-[1px] px-16 shadow-md border-b-[rgba(0, 0, 0, 0.10)] mb-[1.16rem] mt-4">
                {tabs.map((item: string) => (
                  <Tab key={item} as={Fragment}>
                    {({ selected }: { selected: boolean }) => (
                      <div
                        className={`relative inline-block cursor-pointer -bottom-[1px] px-4 py-3 font-semibold text-sm  border-b-2  not-italic  hover:border-b-[#586AF5] hover:text-[#586af5] focus:outline-none ${
                          selected ? "text-[#586af5] border-b-[#586AF5]" : "text-[rgba(0,0,0,0.4)] border-b-transparent"
                        }`}
                      >
                        {item}
                      </div>
                    )}
                  </Tab>
                ))}
              </Tab.List>

              <Tab.Panels className="mx-16 mt-8">
                <Tab.Panel>
                  <OrderInfoHomeScreen />
                </Tab.Panel>
                <Tab.Panel>
                  <OrderDetailsTab />
                </Tab.Panel>
                <Tab.Panel>
                  <OrderInfoFuid />
                </Tab.Panel>
                <Tab.Panel>
                  <OrderInfoInvoices />
                </Tab.Panel>
                <Tab.Panel>
                  <OrderInfoApprovalHierarchy />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderInfoScreen;
