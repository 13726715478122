import InvoiceHomeDetailCard from "./InvoiceHomeDetailCard";
import { getInvoiceDetailsCardInfo } from "../invoice-master/invoice-helper";
import { RootState } from "src/context/store";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "src/components";
import { invoiceActions } from "src/context/invoice-slice";
import { fetchEscalatedInvoicesData } from "src/services/invoice-api";

const InvoiceEscalatedTab = ({
  isExpandable,
  isExpandedInitial,
  page,
}: {
  isExpandable?: boolean;
  isExpandedInitial?: boolean;
  fetchTabData: (tabIndex: number, page: number) => Promise<void>;
  page: number;
  setPage: (page: number) => void;
}) => {
  const { invoiceEscalatedData } = useSelector((state: RootState) => state.invoice);
  const [hasMore, setHasMore] = useState(true);
  const { InvoiceFilters } = useSelector((state: RootState) => state.invoice);

  const [pageNumber, setPageNumber] = useState(page + 1);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchMoreData = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const searchParams = {
        company_code: InvoiceFilters?.company?.[0]?.code,
        division_code: InvoiceFilters?.division?.[0]?.company_divisioncode,
        startDate: InvoiceFilters?.dateRange?.[0],
        endDate: InvoiceFilters?.dateRange?.[1],
        assignee_id: InvoiceFilters?.assignee?.[0]?.invoice_assignment_id,
        city: InvoiceFilters?.city?.[0]?.city_name?.split("|")?.[0]?.trim(),
        district: InvoiceFilters?.district?.[0]?.district_name,
        state: InvoiceFilters?.state?.[0]?.state_name,
        fuid_status_id: InvoiceFilters?.status?.[0]?.fuid_status_id || 1,
        page_size: 10,
        page_number: pageNumber,
      };

      const response = await fetchEscalatedInvoicesData(searchParams);

      if (!response?.data?.data || response?.data?.data?.invoiceData === 0) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }

      const uniqueNewData = response?.data?.data?.invoiceData;

      if (uniqueNewData.length === 0) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }

      dispatch(invoiceActions.setInvoiceEscalatedData(invoiceEscalatedData ? [...invoiceEscalatedData, ...uniqueNewData] : uniqueNewData));

      setPageNumber(pageNumber + 1);

      if (!invoiceEscalatedData || invoiceEscalatedData.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching more invoices:", error);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-10 w-full">
      {invoiceEscalatedData?.length === 0 && (
        <div className="flex justify-center items-center h-[20vh]">
          <p className="text-lg text-gray-500">No invoices found</p>
        </div>
      )}

      <div id="scrollableDiv" className="h-screen overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        <InfiniteScroll
          dataLength={invoiceEscalatedData?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div className="flex justify-center py-4">
              <Spinner />
            </div>
          }
          scrollableTarget="scrollableDiv"
          endMessage={<div className="py-4 text-center text-gray-500">No more invoices to load</div>}
        >
          <ul>
            {invoiceEscalatedData?.map((data: any, index: number) => (
              <li className="my-5 rounded-xl border shadow-sm border-gray-200" key={index}>
                <InvoiceHomeDetailCard
                  status={data?.fileStatusHdr?.fuid_status_desc}
                  isExpandable={isExpandable}
                  isExpandedInitial={isExpandedInitial}
                  data={getInvoiceDetailsCardInfo(data)}
                  checkDataType={false}
                  invoiceData={data}
                  viewDetails={true}
                  index={index}
                />
              </li>
            ))}
          </ul>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default InvoiceEscalatedTab;
