import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/context/store";
import OrderedProduct from "./OrderedProduct";
import { useState } from "react";
import { useToast } from "src/hooks/useToast";
import { getOrderIds } from "src/services/order-api";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "src/components";
import { ordActions } from "src/context/order-slice";
import { useParams } from "react-router-dom";

const OrderDetailsTab = () => {
  const { orderDetailsFiltered, selectedCounterData } = useSelector((state: RootState) => state.ord);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { toast } = useToast();
  const itemsPerPage = 10;
  const { tabIndex } = useParams();

  // const removeDuplicates = (existingData: any[], newData: any[]) => {
  //   const seen = new Set(existingData.map((item) => item.order_id_int));
  //   return newData.filter((item) => !seen.has(item.order_id_int));
  // };

  const fetchMoreData = async () => {
    if (isLoading) return;
    const order_id_int = window.sessionStorage.getItem("order_id_int") ? Number(window.sessionStorage.getItem("order_id_int")) : null;

    try {
      setPage((prevPage) => prevPage + 1);

      setIsLoading(true);
      const res = await getOrderIds(
        Number(window.sessionStorage.getItem("counterCompanyLocationId")),
        selectedCounterData?.company_code,
        page,
        itemsPerPage,
        order_id_int,
        Number(tabIndex) === 0 ? "true" : ""
      );

      if (!res?.data?.data || res?.data?.data?.length === 0) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }

      // Remove duplicates before appending new data
      // const uniqueNewData = removeDuplicates(orderDetailsFiltered || [], res.data.data);
      const uniqueNewData = res?.data?.data;

      // If no new unique data is found, stop loading more
      if (uniqueNewData.length === 0) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }

      dispatch(ordActions.setOrderDetailsFiltered(orderDetailsFiltered ? [...orderDetailsFiltered, ...uniqueNewData] : res?.data?.data));
    } catch (error) {
      console.error("Error fetching more data:", error);
      toast({
        variant: "destructive",
        description: "Failed to load more orders",
      });
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full pb-20 mt-10">
      {orderDetailsFiltered?.length > 0 && (
        <>
          <p className="text-xl font-semibold">Order Draft</p>
          <p className="text-lg text-[#b5b5b5]">Review and manage authorized orders</p>
        </>
      )}
      {orderDetailsFiltered?.length === 0 && (
        <div className="flex justify-center items-center h-[20vh]">
          <p className="text-lg text-[#b5b5b5]">No order details found</p>
        </div>
      )}
      {orderDetailsFiltered?.length > 0 && (
        <div
          id="scrollableDiv"
          className="h-[calc(100vh-250px)] overflow-auto overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
        >
          <InfiniteScroll
            dataLength={orderDetailsFiltered?.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div className="flex justify-center py-4">
                <Spinner />
              </div>
            }
            scrollableTarget="scrollableDiv"
            endMessage={<div className="py-4 text-center text-gray-500">No more orders to load</div>}
          >
            {orderDetailsFiltered.map((products: any, index: any) => (
              <OrderedProduct key={products?.order_id_int} products={products} index={index} />
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default OrderDetailsTab;
